import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProjectsProvider } from './Context/ProjectsContext';
import { ConnectorContextProvider } from './Context/ConnectorContext';
import { WorkflowContextProvider } from './Context/WorkflowContext';
import { ResponseToContextProvider } from './Context/ResponseToClientContext';
import { AuthContextProvider } from './Context/AuthContext';
import { CopyProvider } from './Context/GetValueFromForResponseTab';
import { CopyEventProvider } from './Context/CopyPasteEvent';
import { CopyPasteConditionInsideGetDataFromProvider } from './Context/CopyPasteGetDataFrom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ResponseToContextProvider>
      <ProjectsProvider>
        <WorkflowContextProvider>
          <ConnectorContextProvider>
            <CopyProvider>
              <CopyEventProvider>
                <CopyPasteConditionInsideGetDataFromProvider>
                  <AuthContextProvider>
                    <App />
                  </AuthContextProvider>
                </CopyPasteConditionInsideGetDataFromProvider>
              </CopyEventProvider>
            </CopyProvider>
          </ConnectorContextProvider>
        </WorkflowContextProvider>
      </ProjectsProvider>
    </ResponseToContextProvider>
  </React.StrictMode>
);


