import React, { useState, useEffect } from 'react';
import '../../../Styles/Plugins/ApiConnector/AddAnotherCall/AddAnotherCallGET.css';
import trash_black from '../../../../Assets/Images/trash_black.png';

function AddAnotherCallGET({ onAnotherCallMethodObjectChange, anotherCallMethodObject}) {


  // const [addAnotherCallHeaders, setAddAnotherCallHeaders] = useState([]);
  // const [addAnotherCallParameters, setAddAnotherCallParameters] = useState([]);
  console.log("anotherCallMethodObject", anotherCallMethodObject);
  const [addAnotherCallHeaders, setAddAnotherCallHeaders] = useState(anotherCallMethodObject?.addAnotherCallHeaders || []);
  const [addAnotherCallParameters, setAddAnotherCallParameters] = useState(anotherCallMethodObject?.addAnotherCallParameters || []);
  const [initialized, setInitialized] = useState(false);

  // useEffect(() => {
  //   if(anotherCallMethodObject) {
  //     setAddAnotherCallHeaders(anotherCallMethodObject?.addAnotherCallHeaders || [])
  //     setAddAnotherCallParameters(anotherCallMethodObject?.addAnotherCallParameters || [])
  //   }
  // },[anotherCallMethodObject])

    const addAnotherCallHeader = () => {
      setAddAnotherCallHeaders((prevHeaders) => [
          ...prevHeaders,
          { key: '', value: '', private: true, optional: false, dynamic: false },
        ]);
      };
    
      // const updateAnotherCallHeader = (headerIndex, field, newValue) => {
      //   setAddAnotherCallHeaders((prevHeaders) => {
      //     const updatedHeaders = [...prevHeaders];
      //     updatedHeaders[headerIndex][field] = newValue;
      //     return updatedHeaders;
      //   });
      // };  
    
      const removeAnotherCallHeader = (headerIndex) => {
        setAddAnotherCallHeaders((prevHeaders) => {
          const updatedHeaders = [...prevHeaders];
          updatedHeaders.splice(headerIndex, 1);
          return updatedHeaders;
        });
      };
    
      const addAnotherCallParameter = () => {
        setAddAnotherCallParameters((prevParameters) => [
          ...prevParameters,
          { key: '', value: '', private: true, allowblank: false, optional: false, dynamic:false },
        ]);
      };
    
      // const updateAnotherCallParameter = (parameterIndex, field, newValue) => {
      //   setAddAnotherCallParameters((prevParameters) => {
      //     const updatedParameters = [...prevParameters];
      //     updatedParameters[parameterIndex][field] = newValue;
      //     return updatedParameters;
      //   });
      // };
    
      const removeAnotherCallParameter = (parameterIndex) => {
        setAddAnotherCallParameters((prevParameters) => {
          const updatedParameters = [...prevParameters];
          updatedParameters.splice(parameterIndex, 1);
          return updatedParameters;
        });
      };

      const updateAnotherCallHeader = (headerIndex, field, newValue) => {
        setAddAnotherCallHeaders((prevHeaders) => {
          if (prevHeaders[headerIndex][field] !== newValue) {
            return prevHeaders.map((header, index) =>
              index === headerIndex ? { ...header, [field]: newValue } : header
            );
          }
          return prevHeaders;
        });
      };

      const updateAnotherCallParameter = (parameterIndex, field, newValue) => {
        setAddAnotherCallParameters((prevParameters) => {
          if (prevParameters[parameterIndex][field] !== newValue) {
            return prevParameters.map((parameter, index) =>
              index === parameterIndex ? { ...parameter, [field]: newValue } : parameter
            );
          }
          return prevParameters;
        });
      };
    
      // useEffect(() => {
      //   if (responseData) {
      //     setAddAnotherCallHeaders((prevHeaders) => responseData.addAnotherCallHeaders || prevHeaders);
      //     setAddAnotherCallParameters((prevParameters) => responseData.addAnotherCallParameters || prevParameters);
      //   }
      // }, [responseData]);
    

      // useEffect(() => {
      //   const generateDataObject = () => {
      //     const dataObject = {
      //       addAnotherCallHeaders,
      //       addAnotherCallParameters,
      //     };
      //     onAnotherCallMethodObjectChange(dataObject);
      //   };
      //   generateDataObject();
      // }, [addAnotherCallHeaders, addAnotherCallParameters, onAnotherCallMethodObjectChange]);
         
      // useEffect(() => {
      //   if (responseData) {
      //     setAddAnotherCallHeaders(responseData.addAnotherCallHeaders || []);
      //     setAddAnotherCallParameters(responseData.addAnotherCallParameters || []);
      //     // setInitialized(true);
      //   }
      // }, [responseData]);
    
      useEffect(() => {
        // if (initialized) {
          const generateDataObject = () => {
            const dataObject = {
              addAnotherCallHeaders,
              addAnotherCallParameters,
            };
            onAnotherCallMethodObjectChange(dataObject);
          };
          generateDataObject();
        // }
      }, 
      [
        addAnotherCallHeaders, 
        addAnotherCallParameters, 
        onAnotherCallMethodObjectChange, 
        // initialized
      ]);
    
  return (
    <>
    <div className="addanothercall-acc-card-input-container3">
            <label htmlFor={''}>Headers</label>
            {addAnotherCallHeaders.map((header, headerIndex) => (
              <div key={headerIndex} className="addanothercall-header-row">
                <div className="addanothercall-header-field">
                  <label htmlFor={`key-${headerIndex}`}>Key</label>
                  <input
                    id={`key-${headerIndex}`}
                    value={header.key}
                    type="text"
                    onChange={(e) => updateAnotherCallHeader(headerIndex, 'key', e.target.value)}
                  />
                </div>
                <div className="addanothercall-header-field">
                  <label htmlFor={`value-${headerIndex}`}>Value</label>
                  <input
                    id={`value-${headerIndex}`}
                    value={header.value}
                    type="text"
                    onChange={(e) => updateAnotherCallHeader(headerIndex, 'value', e.target.value)}
                  />
                </div>
                <div className="addanothercall-header-field">
                  <label htmlFor={`private-${headerIndex}`}>Private</label>
                  <input
                    id={`private-${headerIndex}`}
                    checked={header.private}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallHeader(headerIndex, 'private', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-header-field">
                  <label htmlFor={`optional-${headerIndex}`}>Optional</label>
                  <input
                    id={`optional-${headerIndex}`}
                    checked={header.optional}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallHeader(headerIndex, 'optional', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-header-field">
                  <label htmlFor={`dynamic-${headerIndex}`}>Dynamic</label>
                  <input
                    id={`dynamic-${headerIndex}`}
                    checked={header.dynamic}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallHeader(headerIndex, 'dynamic', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-header-field" onClick={() => removeAnotherCallHeader(headerIndex)}>
                  <img src={trash_black} alt="trash_black" className="addanothercall-img-hover" />
                </div>
              </div>
            ))}
            <button id={`addHeader-${addAnotherCallHeaders.length}`} onClick={addAnotherCallHeader}>
              Add header
            </button>
    </div>
    <div className="addanothercall-acc-card-input-container4">
      <label htmlFor={''}>Parameters</label>
      {addAnotherCallParameters.map((parameter, parameterIndex) => (
        <div key={parameterIndex} className="addanothercall-parameter-row">
          <div className="addanothercall-parameter-field">
            <label htmlFor={`key-${parameterIndex}`}>Key</label>
            <input
              id={`key-${parameterIndex}`}
              value={parameter.key}
              type="text"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'key', e.target.value)}
            />
          </div>
          <div className="addanothercall-parameter-field">
            <label htmlFor={`value-${parameterIndex}`}>Value</label>
            <input
              id={`value-${parameterIndex}`}
              value={parameter.value}
              type="text"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'value', e.target.value)}
            />
          </div>
          <div className="addanothercall-parameter-field">
            <label htmlFor={`private-${parameterIndex}`}>Private</label>
            <input
              id={`private-${parameterIndex}`}
              checked={parameter.private}
              type="checkbox"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'private', e.target.checked)}
            />
          </div>
          <div className="addanothercall-parameter-field">
            <label htmlFor={`allowblank-${parameterIndex}`}>Allow Blank</label>
            <input
              id={`allowblank-${parameterIndex}`}
              checked={parameter.allowblank}
              type="checkbox"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'allowblank', e.target.checked)}
            />
          </div>
          <div className="addanothercall-parameter-field">
            <label htmlFor={`optional-${parameterIndex}`}>Optional</label>
            <input
              id={`optional-${parameterIndex}`}
              checked={parameter.optional}
              type="checkbox"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'optional', e.target.checked)}
            />
          </div>
          <div className="addanothercall-parameter-field">
            <label htmlFor={`dynamic-${parameterIndex}`}>Dynamic</label>
            <input
              id={`dynamic-${parameterIndex}`}
              checked={parameter.dynamic}
              type="checkbox"
              onChange={(e) => updateAnotherCallParameter(parameterIndex, 'dynamic', e.target.checked)}
            />
          </div>
          <div className="addanothercall-parameter-field" onClick={() => removeAnotherCallParameter(parameterIndex)}>
            <img src={trash_black} alt="trash_black" className="addanothercall-img-hover" />
          </div>
        </div>
      ))}
      <button id={`addparameter-${addAnotherCallParameters.length}`} onClick={addAnotherCallParameter}>
        Add parameter
      </button>
    </div>
    </>
  )
}

export default AddAnotherCallGET