import React, { useState, useEffect } from 'react'
import '../../../Styles/Plugins/ApiConnector/Authentication/JSONWebToken.css'

function JSONWebToken({onAuthObjectChange, authenticationData}) {

  const [jsonWebTokenScope, setJsonWebTokenScope] = useState('')
  const [jsonWebTokenIss, setJsonWebTokenIss] = useState('')
  const [jsonWebTokenAccessTokenEndpoint, setJsonWebTokenAccessTokenEndpoint] = useState('')
  const [jsonWebTokenPrivateKey, setJsonWebTokenPrivateKey] = useState('')

  useEffect(() => {
    if (authenticationData) {
      setJsonWebTokenScope(authenticationData.jsonWebTokenScope || '');
      setJsonWebTokenIss(authenticationData.jsonWebTokenIss || '');
      setJsonWebTokenAccessTokenEndpoint(authenticationData.jsonWebTokenAccessTokenEndpoint || '');
      setJsonWebTokenPrivateKey(authenticationData.jsonWebTokenPrivateKey || '');
    }
  }, [authenticationData]);

  const handleJsonwebTokenScope = (e) => {
    setJsonWebTokenScope(e.target.value)
  }

  const handleJsonWebTokenIss = (e) => {
    setJsonWebTokenIss(e.target.value)
  }

  const handleJsonWebTokenAccessTokenEndpoint = (e) => {
    setJsonWebTokenAccessTokenEndpoint(e.target.value)
  }

  const handleJsonWebTokenPrivateKey = (e) => {
    setJsonWebTokenPrivateKey(e.target.value)
  }

  useEffect(() => {
    const generateDataObject = () => {
      const dataObject = {
        jsonWebTokenScope,
        jsonWebTokenIss,
        jsonWebTokenAccessTokenEndpoint,
        jsonWebTokenPrivateKey,
      };
      onAuthObjectChange(dataObject);
    };
    generateDataObject();
  }, [jsonWebTokenScope, jsonWebTokenIss, jsonWebTokenAccessTokenEndpoint, jsonWebTokenPrivateKey, onAuthObjectChange]);
  
  return (
    <div className="jsonwebtoken-container">
      <div className="jsonwebtoken-row">
        <label htmlFor={'jsonWebTokenScope'}>Scope</label>
        <input id={'jsonWebTokenScope'} value={jsonWebTokenScope} type="textarea" onChange={handleJsonwebTokenScope} />
      </div>
      <div className="jsonwebtoken-row1">
        <label htmlFor={'jsonWebTokenIss'}>Iss (account email)</label>
        <input id={'jsonWebTokenIss'} value={jsonWebTokenIss} type="textarea" onChange={handleJsonWebTokenIss} />
      </div>
      <div className="jsonwebtoken-row2">
        <label htmlFor={'jsonWebTokenAccessTokenEndpoint'}>Access token endpoint</label>
        <input id={'jsonWebTokenAccessTokenEndpoint'} value={jsonWebTokenAccessTokenEndpoint} type="textarea" onChange={handleJsonWebTokenAccessTokenEndpoint} />
      </div>
      <div className="jsonwebtoken-row3">
        <label htmlFor={'jsonWebTokenPrivateKey'}>Private key (between -----BEGIN RSA PRIVATE KEY----- and -----END RSA PRIVATE KEY-----)</label>
        <input id={'jsonWebTokenPrivateKey'} value={jsonWebTokenPrivateKey} type="textarea" onChange={handleJsonWebTokenPrivateKey} />
      </div>
    </div>
  )
}

export default JSONWebToken