import React,{ useState, useEffect } from 'react'
import '../../../Styles/Plugins/ApiConnector/Authentication/OAuth2PasswordFlow.css'

function OAuth2PasswordFlow({onAuthObjectChange, authenticationData}) {

    const [userNameOAuth2PasswordFlow, setUserNameOAuth2PasswordFlow] = useState('');
    const [passwordOAuth2PasswordFlow, setPasswordOAuth2PasswordFlow] = useState('');
    const [tokenEndpointOAuth2PasswordFlow, setTokenEndpointOAuth2PasswordFlow] = useState('');

    useEffect(() => {
      if (authenticationData) {
        setUserNameOAuth2PasswordFlow(authenticationData.userNameOAuth2PasswordFlow || '');
        setPasswordOAuth2PasswordFlow(authenticationData.passwordOAuth2PasswordFlow || '');
        setTokenEndpointOAuth2PasswordFlow(authenticationData.tokenEndpointOAuth2PasswordFlow || '');
      }
    }, [authenticationData]);

    const handleUserNameOAuth2PasswordFlow = (e) => {
        setUserNameOAuth2PasswordFlow(e.target.value);
      };
    
      const handlePasswordOAuth2PasswordFlow = (e) => {
        setPasswordOAuth2PasswordFlow(e.target.value);
      };

      const handleTokenEndpointOAuth2PasswordFlow = (e) => {
        setTokenEndpointOAuth2PasswordFlow(e.target.value);
      };

      useEffect(() => {
        const generateDataObject = () => {
          const dataObject = {
            userNameOAuth2PasswordFlow,
            passwordOAuth2PasswordFlow,
            tokenEndpointOAuth2PasswordFlow
          };
          onAuthObjectChange(dataObject);
        };
        generateDataObject();
      }, [userNameOAuth2PasswordFlow, passwordOAuth2PasswordFlow, tokenEndpointOAuth2PasswordFlow, onAuthObjectChange]);
      
      
  return (
    <div className="authpasswordflow-container">
      <div className="authpasswordflow-row">
        <label htmlFor={'userNameOAuth2PasswordFlow'}>Username</label>
        <input id={'userNameOAuth2PasswordFlow'} value={userNameOAuth2PasswordFlow} type="text" onChange={handleUserNameOAuth2PasswordFlow} />
      </div>
      <div className="authpasswordflow-row">
        <label htmlFor={'passwordOAuth2PasswordFlow'}>Password</label>
        <input id={'passwordOAuth2PasswordFlow'} value={passwordOAuth2PasswordFlow} type="text" onChange={handlePasswordOAuth2PasswordFlow} />
      </div>
      <div className="authpasswordflow-row1">
        <label htmlFor={'tokenEndpointOAuth2PasswordFlow'}>Token Endpoint (POST)</label>
        <input
          id={'tokenEndpointOAuth2PasswordFlow'}
          value={tokenEndpointOAuth2PasswordFlow}
          type="text"
          onChange={handleTokenEndpointOAuth2PasswordFlow}
        />
      </div>
    </div>
  )
}

export default OAuth2PasswordFlow