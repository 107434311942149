import { createContext, useEffect, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
// import { toast } from "react-toastify";
import { useProjectsContext } from "./ProjectsContext";


const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(`Failed to access localStorage for key "${key}":`, error);
    return null;
  }
};

const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Failed to set localStorage for key "${key}":`, error);
  }
};

const initialState = {
  admin: getLocalStorageItem("admin") != undefined
    ? JSON.parse(getLocalStorageItem("admin"))
    : null,
  access: getLocalStorageItem("access") || null,
  token: getLocalStorageItem("token") || null,
};

export const authContext = createContext(initialState);

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        admin: null,
        access: null,
        token: null,
      };

    case "LOGIN_SUCCESS":
      return {
        admin: action.payload.admin,
        access: action.payload.access,
        token: action.payload.token,
      };

    case "LOGOUT":
      return {
        admin: null,
        access: null,
        token: null,
      };

    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const {setDatabase} = useProjectsContext();
  useEffect(() => {
    setLocalStorageItem("admin", JSON.stringify(state.admin));
    setLocalStorageItem("token", state.token);
    setLocalStorageItem("access", state.access);
  }, [state.admin, state.token, state.access]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (state.token !== null) {
        try {
          const decodedToken = jwtDecode(state.token);
          const currentTime = Date.now() / 1000; // Convert to seconds

          if (decodedToken.exp < currentTime) {
            // Token is expired, perform logout
            dispatch({ type: "LOGOUT" });
            setDatabase("")
            console.log("Session expired. Please log in again.");
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          // Handle the error, e.g., invalid token format
        }
      }
    };

    // Check token expiration on component mount
    checkTokenExpiration();

    // Set up an interval to check token expiration periodically
    const intervalId = setInterval(checkTokenExpiration, 60000); // Check every minute

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [state.token]);

  return (
    <authContext.Provider
      value={{
        admin: state.admin,
        token: state.token,
        access: state.access,
        dispatch,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
