import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the close icon
import '../Styles/ApiReceiver/TokenGenerationForm.css';

const TokenGenerationForm = ({ onClose, onTokenChange, initialApiTokenLabel, initialJwtToken }) => {

    const [apiTokenLabel, setApiTokenLabel ] = useState('');
    const [jwtToken, setJwtToken] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
      // Set initial values for API token label and JWT token based on props
      if (initialApiTokenLabel && initialJwtToken) {
        setApiTokenLabel(initialApiTokenLabel || '');
        setJwtToken(initialJwtToken || '');   
        onTokenChange(initialApiTokenLabel, initialJwtToken); // Notify parent with initial values
      } else {
        setApiTokenLabel('');
        const initialToken = getNewToken();
        setJwtToken(initialToken);
        if (apiTokenLabel.trim() !== '') {
          onTokenChange(apiTokenLabel, initialToken);
        }
      }
    }, [initialApiTokenLabel, initialJwtToken]);

    
    // Listen for changes in apiTokenLabel and call onTokenChange accordingly
    useEffect(() => {
        if (apiTokenLabel.trim() !== '') {
        onTokenChange(apiTokenLabel, jwtToken);
        }
    }, [apiTokenLabel, jwtToken, onTokenChange]);

    function handleApiTokenLabelChange(e) {
        setApiTokenLabel(e.target.value);
    }

    // Function to generate a new JWT token
    const regenerateToken = () => {
        const newToken = getNewToken();
        setJwtToken(newToken);

        if (apiTokenLabel.trim() !== '') {
        onTokenChange(apiTokenLabel, newToken);
        }
    };

    const getNewToken = () => {
        return Array.from({ length: 32 }, () => Math.floor(Math.random() * 256).toString(16)).join('');
    };

    const copyToClipboard = (e) => {
      e.preventDefault(); // Prevent the default behavior (form submission, link navigation, etc.)
    
      if (inputRef.current) {
          inputRef.current.select();
  
          try {
              navigator.clipboard.writeText(inputRef.current.value);
              alert('Token copied to clipboard!');
          } catch (err) {
              console.error('Unable to copy to clipboard', err);
          }
      }
    };  

  return (
    <div className="token-generation-form">
      <div className="form-header">
        <h2>API Tokens</h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <form>
        <div className="form-group">
          <label htmlFor="apiTokenLabel" className="label-inline">
            API Token Label
          </label>
          <input type="text" className="form-control-input1" id="apiTokenLabel" value={apiTokenLabel} onChange={handleApiTokenLabelChange}/>
        </div>
        <div className="form-group">
          <label htmlFor="privateKey" className="label-inline">
            Private Key
          </label>
          <input
            type="text"
            className="form-control-input2"
            id="privateKey"
            readOnly
            value={jwtToken}
            ref={inputRef}
          />
          <button className='copy-btn' onClick={copyToClipboard}>Copy</button>
        </div>
        <button type="button" className="btn btn-outline-secondary" onClick={regenerateToken}>
          Regenerate Private Key
        </button>
      </form>
    </div>
  );
};

export default TokenGenerationForm;
