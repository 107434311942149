import React, { createContext, useContext, useState, useEffect } from "react";
// import { BASE_URL } from "../../config";

const ProjectsContext = createContext();

export const useProjectsContext = () => {
  return useContext(ProjectsContext);
};

export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [currentDatabase, setCurrentDatabase] = useState(() => {
    const storedDatabase = localStorage.getItem("currentDatabase");
    return storedDatabase || "";
  });
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  const setDatabase = (databaseName) => {
    setCurrentDatabase(databaseName);
  };

  const fetchProjects = async () => {
    try {
      setIsLoadingProjects(true);

      const response = await fetch(
        'https://aim-core.insurancepolicy4us.com/get-databases'
      );

      if (response.ok) {
        const data = await response.json();

        setProjects(data);
      } else {
        console.error(`Failed to fetch`);
      }
    } catch (error) {
      console.error(`Error fetching :`, error);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("currentDatabase", currentDatabase);
  }, [currentDatabase]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const updateProject = async () => {
    await fetchProjects();
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        setDatabase,
        updateProject,
        isLoadingProjects,
        currentDatabase,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
