import React, { useState, useEffect } from 'react'
import trash_black from '../../../../Assets/Images/trash_black.png';
import '../../../Styles/Plugins/ApiConnector/AddAnotherCall/AddAnotherCallPOST.css'
// import AceEditor from 'react-ace';
// import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/theme-github';
// import 'ace-builds/src-noconflict/worker-json';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-xml'; // Import XML mode as well
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/worker-json';


function AddAnotherCallPOST({onAnotherCallMethodObjectChange, anotherCallMethodObject}) {
    const [addAnotherCallPOSTHeaders, setAddAnotherCallPOSTHeaders] = useState(anotherCallMethodObject?.addAnotherCallPOSTHeaders || []);
    const [addAnotherCallPOSTParameters, setAddAnotherCallPOSTParameters] = useState(anotherCallMethodObject?.addAnotherCallPOSTParameters || []);
    // const [selectedBodyType, setSelectedBodyType] = useState(anotherCallMethodObject?.selectedBodyType || 'JSON')
    const [selectedBodyType, setSelectedBodyType] = useState({
      selectedBodyType: anotherCallMethodObject?.selectedBodyType?.selectedBodyType || 'JSON',
      dynamic: anotherCallMethodObject?.selectedBodyType?.dynamic || false,
    });
    const [jsonBody, setJsonBody] = useState(
      anotherCallMethodObject?.jsonBody || JSON.stringify({}, null, 2) // Default to an empty JSON object
    );
    const [xmlBody, setXmlBody] = useState(
      anotherCallMethodObject?.xmlBody || '' // Default to an empty string
    );  
    
    const addAnotherCallPOSTHeader = () => {
        setAddAnotherCallPOSTHeaders((prevHeaders) => [
          ...prevHeaders,
          { key: '', value: '', private: true, optional: false, dynamic:false },
        ]);
      };
    
      // const updateAnotherCallPOSTHeader = (headerIndex, field, newValue) => {
      //   setAddAnotherCallPOSTHeaders((prevHeaders) => {
      //     const updatedHeaders = [...prevHeaders];
      //     updatedHeaders[headerIndex][field] = newValue;
      //     return updatedHeaders;
      //   });
      // };
      const updateAnotherCallPOSTHeader = (headerIndex, field, newValue) => {
        setAddAnotherCallPOSTHeaders((prevHeaders) => {
          if (prevHeaders[headerIndex][field] !== newValue) {
            return prevHeaders.map((header, index) =>
              index === headerIndex ? { ...header, [field]: newValue } : header
            );
          }
          return prevHeaders;
        });
      };
    
      const removeAnotherCallPOSTHeader = (headerIndex) => {
        setAddAnotherCallPOSTHeaders((prevHeaders) => {
          const updatedHeaders = [...prevHeaders];
          updatedHeaders.splice(headerIndex, 1);
          return updatedHeaders;
        });
      };
    
      const addAnotherCallPOSTParameter = () => {
        let newParameter;
        if (selectedBodyType === 'JSON') {
          newParameter = { key: '', value: '', private: true, allowblank: false, optional: false, queryst: false, long: false, dynamic:false };
        } else if (selectedBodyType === 'Form-data') {
          newParameter = { key: '', value: '', private: true, allowblank: false, optional: false, sendfile: false, long: false, dynamic:false };
        } else {
          // If selectedBodyType is 'Raw', don't include value in the parameter
          newParameter = { key: '', private: true, allowblank: false, optional: false, sendfile: false, long: false, dynamic:false };
        }
    
        setAddAnotherCallPOSTParameters((prevParameters) => [...prevParameters, newParameter]);
      };
    
      // const updateAnotherCallPOSTParameter = (parameterIndex, field, newValue) => {
      //   setAddAnotherCallPOSTParameters((prevParameters) => {
      //     const updatedParameters = [...prevParameters];
      //     updatedParameters[parameterIndex][field] = newValue;
      //     return updatedParameters;
      //   });
      // };

      const updateAnotherCallPOSTParameter = (parameterIndex, field, newValue) => {
        setAddAnotherCallPOSTParameters((prevParameters) => {
          if (prevParameters[parameterIndex][field] !== newValue) {
            return prevParameters.map((parameter, index) =>
              index === parameterIndex ? { ...parameter, [field]: newValue } : parameter
            );
          }
          return prevParameters;
        });
      };
    
      const removeAnotherCallPOSTParameter = (parameterIndex) => {
        setAddAnotherCallPOSTParameters((prevParameters) => {
          const updatedParameters = [...prevParameters];
          updatedParameters.splice(parameterIndex, 1);
          return updatedParameters;
        });
      };

      // useEffect(() => {
      //   if (responseData) {
      //     setAddAnotherCallPOSTHeaders(responseData.addAnotherCallPOSTHeaders || []);
      //     setAddAnotherCallPOSTParameters(responseData.addAnotherCallPOSTParameters || []);
      //     setSelectedBodyType(responseData.selectedBodyType || 'JSON')
      //     setJsonBody(responseData.jsonBody || '')
      //     // setInitialized(true);
      //   }
      // }, [responseData]);

      const handleBodyTypeCheckbocChange = () => {
        setSelectedBodyType((prevSelectedBodyType) => ({
          ...prevSelectedBodyType,
          dynamic: !prevSelectedBodyType.dynamic,
        }));
      }; 

      // const handleJsonEditorChange = (newValue) => {
      //   try {
      //     const parsedJson = JSON.parse(newValue);
      //     setJsonBody(parsedJson);
      //   } catch (error) {
      //     // Handle parsing error, e.g., show an error message or log the error.
      //     console.error("Invalid JSON format:", error.message);
      //   }
      // };
      useEffect(() => {
        console.log('Initial jsonBody:', jsonBody);
      }, []);

      const handleJsonEditorChange = (newValue) => {
        try {
          const parsedJson = JSON.parse(newValue);
          setJsonBody(parsedJson);
        } catch (error) {
          console.error("Invalid JSON format:", error.message);
        }
      };     
      

      useEffect(() => {
        // if (initialized) {
          const generateDataObject = () => {
            let dataObject;
        
            if (selectedBodyType === 'Raw') {
              // If selectedBodyType is 'Raw', exclude addAnotherCallPOSTParameters
              dataObject = {
                addAnotherCallPOSTHeaders,
                selectedBodyType :{
                  selectedBodyType: selectedBodyType.selectedBodyType,
                  dynamic: selectedBodyType.dynamic
                },
                jsonBody,
                xmlBody,
              };
            } else {

              // console.log("jsonBody",jsonBody);
              // console.log("addAnotherCallPOSTHeaders",addAnotherCallPOSTHeaders);
              // console.log("addAnotherCallPOSTParameters",addAnotherCallPOSTParameters);
              // console.log("selectedBodyType",selectedBodyType)
              // For other cases, include addAnotherCallPOSTParameters
              dataObject = {
                addAnotherCallPOSTHeaders,
                addAnotherCallPOSTParameters,
                selectedBodyType :{
                  selectedBodyType: selectedBodyType.selectedBodyType,
                  dynamic: selectedBodyType.dynamic
                },
                jsonBody,
                xmlBody,
              };
            }
        
            onAnotherCallMethodObjectChange(dataObject);
          };
        
          generateDataObject();
        // }
      }, [addAnotherCallPOSTHeaders, addAnotherCallPOSTParameters, selectedBodyType, jsonBody, xmlBody, onAnotherCallMethodObjectChange]);
   
      
  return (
    <>
    <div className="addanothercall-acc-card-post-input-container3">
            <label htmlFor={''}>Headers</label>
            {addAnotherCallPOSTHeaders.map((header, headerIndex) => (
              <div key={headerIndex} className="addanothercall-post-header-row">
                <div className="addanothercall-post-header-field">
                  <label htmlFor={`key-${headerIndex}`}>Key</label>
                  <input
                    id={`key-${headerIndex}`}
                    value={header.key}
                    type="text"
                    onChange={(e) => updateAnotherCallPOSTHeader(headerIndex, 'key', e.target.value)}
                  />
                </div>
                <div className="addanothercall-post-header-field">
                  <label htmlFor={`value-${headerIndex}`}>Value</label>
                  <input
                    id={`value-${headerIndex}`}
                    value={header.value}
                    type="text"
                    onChange={(e) => updateAnotherCallPOSTHeader(headerIndex, 'value', e.target.value)}
                  />
                </div>
                <div className="addanothercall-post-header-field">
                  <label htmlFor={`private-${headerIndex}`}>Private</label>
                  <input
                    id={`private-${headerIndex}`}
                    checked={header.private}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTHeader(headerIndex, 'private', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-header-field">
                  <label htmlFor={`optional-${headerIndex}`}>Optional</label>
                  <input
                    id={`optional-${headerIndex}`}
                    checked={header.optional}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTHeader(headerIndex, 'optional', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-header-field">
                  <label htmlFor={`dynamic-${headerIndex}`}>Dynamic</label>
                  <input
                    id={`dynamic-${headerIndex}`}
                    checked={header.dynamic}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTHeader(headerIndex, 'dynamic', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-header-field" onClick={() => removeAnotherCallPOSTHeader(headerIndex)}>
                  <img src={trash_black} alt="trash_black" className="addanothercall-post-img-hover" />
                </div>
              </div>
            ))}
            <button id={`addHeader-${addAnotherCallPOSTHeaders.length}`} onClick={addAnotherCallPOSTHeader}>
              Add header
            </button>
          </div>
          <div className="addanothercall-acc-card-post-input-container5">
            <label htmlFor={''}>Body type</label>
            <select 
              className="addanothercall-post-select"
              onChange={(e) =>
                setSelectedBodyType({
                  selectedBodyType: e.target.value,
                  dynamic: selectedBodyType.dynamic,
                })
              }
              value={selectedBodyType.selectedBodyType}
            >
              <option value="JSON">JSON</option>
              <option value="Form-data">Form-data</option>
              <option value="Raw">Raw</option>
            </select>
            <input
              id={''}
              type="checkbox"
              className="small-checkbox"
              onChange={handleBodyTypeCheckbocChange}
              checked={selectedBodyType.dynamic}
            />
          </div>
          <div className="addanothercall-acc-card-post-input-container4">
          {selectedBodyType !== 'Raw' && (
            <>
            <label htmlFor={''}>Parameters</label>
            {addAnotherCallPOSTParameters.map((parameter, parameterIndex) => (
              <div key={parameterIndex} className="addanothercall-post-parameter-row">
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`key-${parameterIndex}`}>Key</label>
                  <input
                    id={`key-${parameterIndex}`}
                    value={parameter.key}
                    type="text"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'key', e.target.value)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`value-${parameterIndex}`}>Value</label>
                  <input
                    id={`value-${parameterIndex}`}
                    value={parameter.value}
                    type="text"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'value', e.target.value)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`private-${parameterIndex}`}>Private</label>
                  <input
                    id={`private-${parameterIndex}`}
                    checked={parameter.private}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'private', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`allowblank-${parameterIndex}`}>Allow Blank</label>
                  <input
                    id={`allowblank-${parameterIndex}`}
                    checked={parameter.allowblank}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'allowblank', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`optional-${parameterIndex}`}>Optional</label>
                  <input
                    id={`optional-${parameterIndex}`}
                    checked={parameter.optional}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'optional', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  {selectedBodyType === 'JSON' && (
                    <>
                    <label htmlFor={`queryst-${parameterIndex}`}>Queryst.</label>
                    <input
                      id={`queryst-${parameterIndex}`}
                      checked={parameter.queryst}
                      type="checkbox"
                      onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'queryst', e.target.checked)}
                    />
                    </>
                  )}
                </div>
                <div className="addanothercall-post-parameter-field">
                  {selectedBodyType === 'Form-data' && (
                    <>
                    <label htmlFor={`sendfile-${parameterIndex}`}>Send File</label>
                    <input
                      id={`sendfile-${parameterIndex}`}
                      checked={parameter.sendfile}
                      type="checkbox"
                      onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'sendfile', e.target.checked)}
                    />
                    </>
                  )}
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`long-${parameterIndex}`}>Long</label>
                  <input
                    id={`long-${parameterIndex}`}
                    checked={parameter.long}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'long', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field">
                  <label htmlFor={`dynamic-${parameterIndex}`}>Dynamic</label>
                  <input
                    id={`dynamic-${parameterIndex}`}
                    checked={parameter.dynamic}
                    type="checkbox"
                    onChange={(e) => updateAnotherCallPOSTParameter(parameterIndex, 'dynamic', e.target.checked)}
                  />
                </div>
                <div className="addanothercall-post-parameter-field" onClick={() => removeAnotherCallPOSTParameter(parameterIndex)}>
                  <img src={trash_black} alt="trash_black" className="addanothercall-post-img-hover" />
                </div>
              </div>
            ))}
            <button id={`addparameter-${addAnotherCallPOSTParameters.length}`} onClick={addAnotherCallPOSTParameter}>
              Add parameter
            </button>
            </>
            )}
          </div>
          <div className="addanothercall-acc-card-post-input-container6">
          {selectedBodyType.selectedBodyType === 'JSON' && (
                    <>
                        <label htmlFor={''}>Body (JSON object, use {'<>'} for dynamic values)</label>
                        <AceEditor
                            mode="json"
                            theme="github"
                            value={jsonBody ? JSON.stringify(jsonBody, null, 2) : ''} // Ensure value is not null
                            onChange={handleJsonEditorChange}
                            name="json-editor"
                            editorProps={{ $blockScrolling: true }}
                            className="ace-post-editor"
                        />
                    </>
                )}
                {selectedBodyType.selectedBodyType === 'Raw' && (
                    <>
                        <label htmlFor={''}>Body (Raw - use _*_ for dynamic values and specify Content-Type in headers)</label>
                        <AceEditor
                            mode="xml"
                            theme="github"
                            value={xmlBody || ''} // Ensure value is not null
                            onChange={(newValue) => setXmlBody(newValue)}
                            name="xml-editor"
                            editorProps={{ $blockScrolling: true }}
                            className="ace-post-editor"
                        />
                    </>
                )}
          </div>
    </>
  )
}

export default AddAnotherCallPOST