import React, { useState, useContext, useEffect } from 'react';
import '../../../Styles/Workflow/DynamicAction/ResponseTab/ResponseTab.css';
import { ResponseToClientContext } from '../../../../Context/ResponseToClientContext';
import GetFieldValuePopup from './GetFieldValuePopup';
import xmlParser from 'xml-js'; // Import xml-js library 
import AddNestedField from './AddNestedField';

function ResponseTab({ apiCallName, onGetDataFromResponse, Response, actionStep, customEventText, actionLabel }) {
    const { apiResponse } = useContext(ResponseToClientContext);
    const [fieldState, setFieldState] = useState({});
    const [addNewFields, setAddNewFields] = useState([]);
    const [showPopup, setShowPopup] = useState(false); // State to manage visibility of the popup
    const [clickedFieldIndex, setClickedFieldIndex] = useState(null);
    const [getDataFromFieldValues, setGetDataFromFieldValues] = useState({});

    const [fieldStatePaths, setFieldStatePaths] = useState([])
    

    // console.log("Response apiCallName:", apiCallName);
    // console.log("apiResponse:", apiResponse);
    // console.log("Response customEventText:", customEventText);

    // console.log("fieldState:", fieldState);
    // console.log("fieldState[apiCallName]) from DB", Object.keys(Response.getDataFromResponse.fieldState[apiCallName]));
    // Object.keys(fieldState).forEach(apiCallName => {
    //   if (fieldState[apiCallName]) {
    //     console.log("fieldState[apiCallName]", Object.keys(fieldState[apiCallName]));
    //     setFieldStatePaths(Object.keys(fieldState[apiCallName]) || [])
    //   } else {
    //     console.log(`fieldState[${apiCallName}] is undefined or null`);
    //   }
    // });

    useEffect(() => {
      if (apiResponse && fieldState[apiCallName]) {
        setFieldStatePaths(Object.keys(fieldState[apiCallName]) || []);
      }
    }, [apiResponse, fieldState, apiCallName]);  
      

    useEffect(() => {
      if (Response && Response.getDataFromResponse && Response.getDataFromResponse.addNewFields) {
        setAddNewFields(Response.getDataFromResponse.addNewFields);
      } 
      if (Response && Response.getDataFromResponse && Response.getDataFromResponse.fieldState) {
        setFieldStatePaths(Object.keys(Response.getDataFromResponse.fieldState[apiCallName]) || [])
      }
    }, [Response]);    

    function removeNamespace(xmlString) {
      // Regular expression to remove xmlns:typ from tags
      return xmlString.replace(/xmlns:typ="[^"]*"/g, '');
    }

      // Modify the removeTextProp function to return the modified object
      function removeTextProp(obj) {
        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            obj[key] = removeTextProp(obj[key]);
          } else if (key === '_text') {
            obj = obj[key];
          }
        }
        return obj;
      }

  useEffect(() => {
    const responseObject = apiResponse.find(obj => obj.hasOwnProperty(apiCallName));
    // console.log("responseObject:", responseObject);

    if (responseObject) {
        const innerObject = responseObject[apiCallName];
        // console.log("innerObject..:", innerObject);
        const newState = {};

        // Function to recursively iterate through nested objects and build fieldState with paths
        const extractKeys = (object, state, currentPath) => {
            Object.keys(object).forEach(key => {
                const value = object[key];
                const newPath = currentPath ? `${currentPath}.${key}` : key;

                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    extractKeys(value, state, newPath);
                } else if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            extractKeys(item, state, `${newPath}[${index}]`);
                        } else {
                            if (item !== '') {
                                state[`${newPath}[${index}]`] = {
                                    label: `${newPath}[${index}]`,
                                    resValue: item !== null ? item.toString() : '',
                                    value: '',
                                    checkbox: false
                                };
                            }
                        }
                    });
                } else {
                    if (value !== '') {
                        state[newPath] = {
                            label: newPath,
                            resValue: value !== null ? value.toString() : '',
                            value: '',
                            checkbox: false
                        };
                    }
                }
            });
        };

        if (typeof innerObject !== 'object') {
            const updatedResponse = removeNamespace(innerObject);
            // console.log("updatedResponse:", updatedResponse);
            // Convert XML to JSON
            const jsonFromXml = xmlParser.xml2json(updatedResponse, { compact: true, spaces: 4 });
            const parsedJson = JSON.parse(jsonFromXml);
            // console.log("convertedJson:", parsedJson);

            const newjson = removeTextProp(parsedJson);
            // console.log("convertednewJson:", newjson);
            // Pass extractedValues into inside of extractKeys
            extractKeys(newjson, newState, '');
        } else {
            // Pass innerObject inside to extractKeys
            extractKeys(innerObject, newState, '');
        }

        setFieldState(prevState => ({
            ...prevState,
            [apiCallName]: newState
        }));
    }

  }, [apiCallName, apiResponse, setFieldState]);


  // console.log("newFieldState:", newFieldState)
  // console.log("FieldState:", fieldState)
  
    const handleCheckboxChange = (fieldName) => {
        setFieldState(prevState => ({
            ...prevState,
            [apiCallName]: {
                ...prevState[apiCallName],
                [fieldName]: {
                    ...prevState[apiCallName][fieldName],
                    checkbox: !prevState[apiCallName][fieldName].checkbox
                }
            }
        }));
    };

    const handleInputChange = (fieldName, inputValue) => {
      setFieldState(prevState => ({
          ...prevState,
          [apiCallName]: {
              ...prevState[apiCallName],
              [fieldName]: {
                  ...prevState[apiCallName][fieldName],
                  value: inputValue
              }
          }
      }));
    }; 
    
    useEffect(() => {
      const generateDataObject = async () => {
        const dataObject = {
          fieldState:fieldState,
          addNewFields:addNewFields,
          getDataFromFieldValues:getDataFromFieldValues
        }

        onGetDataFromResponse(dataObject)
      }
      generateDataObject()
    },[
      fieldState,
      addNewFields,
      getDataFromFieldValues,
      onGetDataFromResponse
    ])

    const handleAddNewFieldButtonClick = () => {
      // const newLabel = `Step 1: Key ${addNewFields.length + 1}`;
      let newLabel;
      if (actionStep !== null && actionStep !== undefined) {
        newLabel = `${actionStep}: Key ${addNewFields.length + 1}`;
      } else if (customEventText !== null && customEventText !== undefined) {
        newLabel = `${customEventText}: Key ${addNewFields.length + 1}`;
      } 
        const newField = {
          newFieldlabel: newLabel,
          newFieldInput: '',
          newFieldCheckbox: '',
        };
    
        setAddNewFields(prevFields => [...prevFields, newField]);
    };

    // console.log("addNewFields:", addNewFields);

    const handleInputFieldClick = (index) => {
      setShowPopup(true); // Open the popup
      setClickedFieldIndex(index); // Store the index of the clicked input field
    };

    // const onGetFieldValue = (value) => {
    //   console.log("Value from GetFieldValuePopup", value);
    //   const newFields = [...addNewFields];
    //   newFields[clickedFieldIndex].newFieldInput = value;
    //   setAddNewFields(newFields);
      
    // }

    const onGetFieldValue = (value) => {
      console.log("Value from GetFieldValuePopup", value);
      if (!isNaN(clickedFieldIndex)) {
          const newFields = [...addNewFields];
          newFields[clickedFieldIndex].newFieldInput = value;
          setAddNewFields(newFields);
      } else {
          // Update the corresponding field in fieldState
          setFieldState(prevState => {
              const updatedApiCallState = { ...prevState[apiCallName] };
              updatedApiCallState[clickedFieldIndex].value = value;
              
              return {
                  ...prevState,
                  [apiCallName]: updatedApiCallState
              };
          });
      }
    };
  

    const onGetValueFrom = (index, dataObject) => {
      setGetDataFromFieldValues((prevDataObject) => {
        const updatedDataObject = { ...prevDataObject };
        updatedDataObject[index] = dataObject;
        return updatedDataObject;
      })
    }

    const getValueFromDB = Response && Response.getDataFromResponse && Response.getDataFromResponse.getDataFromFieldValues ? Response.getDataFromResponse.getDataFromFieldValues : {};


    return (
        <div>
          <div className='rt-outerline'>
            {addNewFields.map((addNewField, index) => (
              <>
                  <div className="row" key={index}>
                        <div className="col-md-12 accls1">
                            {/* <label className="col-md-5 rt-label" >
                            </label> */}
                            <input
                                className="col-md-5 rt-input1"
                                type='text'
                                value={addNewField.newFieldlabel}
                                onChange={(e) => {
                                  const newFields = [...addNewFields];
                                  newFields[index].newFieldlabel = e.target.value;
                                  setAddNewFields(newFields);
                                }} 
                                placeholder='Enter key here'
                                // disabled={fieldState[apiCallName][fieldName].checkbox}
                            />
                            <input
                                className="rt-input2"
                                type='text'
                                value={addNewField.newFieldInput}
                                onClick={() => handleInputFieldClick(index)} // Open popup on click
                                disabled={addNewField.newFieldCheckbox}
                            />
                            <input
                                className="rt-checkbox"
                                type='checkbox'
                                checked={addNewField.newFieldCheckbox}
                                onChange={(e) => {
                                  const newFields = [...addNewFields];
                                  newFields[index].newFieldCheckbox = e.target.checked;
                                  setAddNewFields(newFields);
                                }} 
                                // checked={fieldState[apiCallName][fieldName].checkbox}
                            />
                        </div>
                  </div>
              </>
            ))}
            <div className="row">
            <div className="col-md-12 rtcls1">
              <button className='col-md-12 rt-format-btn' onClick={handleAddNewFieldButtonClick}>
                <span className="rt-text">Add New Field</span>
              </button>
            </div>
          </div>
          </div>
          <AddNestedField
            actionStep = {actionStep}
            customEventText = {customEventText}
          />
          {showPopup && (
            <GetFieldValuePopup
              onClose={() => setShowPopup(false)} // Close the popup
              index={clickedFieldIndex} // Pass the index of the clicked input field to the popup
              onSave={onGetFieldValue}
              onGetValueFrom={onGetValueFrom}
              getValueFromDB={getValueFromDB}
              fieldStatePaths = {fieldStatePaths || []}
              actionLabel = {actionLabel}
            />
          )}
            <div>
              {Response && Response.getDataFromResponse && Response.getDataFromResponse.fieldState && Object.keys(Response.getDataFromResponse.fieldState).map((apiCallName, index) => (
                  Object.keys(Response.getDataFromResponse.fieldState[apiCallName]).map((fieldName, index) => {
                    const parts = fieldName.split(/\.|\[|\]/).filter(Boolean);
                    // console.log("parts inside return:", parts);
                    const lastPart = parts[parts.length - 1];
                    // console.log("lastPart inside return:", lastPart);
                    let label;
            
                    // Check if the key is inside a nested object or array
                    if (parts.length > 1) {
                        const secondLastPart = parts[parts.length - 2];
                        if (fieldName.includes('[')) {
                            // If the fieldName includes '[', it indicates an array
                            label = `${secondLastPart}[${parts[parts.length - 3]}].${lastPart}`;
                        } else {
                            // Otherwise, it's part of a nested object
                            label = `${secondLastPart}.${lastPart}`;
                        }
                    } else {
                        // If not nested, use the key itself
                        label = lastPart;
                    }
                    return(
                      <div className="row" key={index}>
                          <div className="col-md-12 accls1">
                              <label className="col-md-5 rt-label" htmlFor={label}>
                                {label}
                              </label>
                              <input
                                className="rt-input_1"
                                type='text'
                                value={Response.getDataFromResponse.fieldState[apiCallName][fieldName]?.resValue || ''}
                                readOnly // Make the input read-only to display the value
                              />
                              <input
                                  className="rt-input"
                                  type='text'
                                  value={Response.getDataFromResponse.fieldState[apiCallName][fieldName]?.value || ''}
                                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                  disabled={fieldState[apiCallName]?.[fieldName]?.checkbox || false}
                                  onClick={() => handleInputFieldClick(fieldName)} 
                              />
                              <input
                                  className="rt-checkbox"
                                  type='checkbox'
                                  onChange={() => handleCheckboxChange(fieldName)}
                                  checked={Response.getDataFromResponse.fieldState[apiCallName][fieldName]?.checkbox || false}
                              />
                          </div>
                      </div>
                    )
                  })
              ))}
              {Object.keys(fieldState[apiCallName] || {}).map((fieldName, index) => {
                const parts = fieldName.split('.');
                const lastPart = parts[parts.length - 1];
                let label;
            
                // Check if the key is inside a nested object or array
                if (parts.length > 1) {
                    const secondLastPart = parts[parts.length - 2];
                    if (secondLastPart.includes('[')) {
                        // If the second-to-last part includes '[', it indicates an array
                        label = `${secondLastPart}.${lastPart}`;
                    } else if (!isNaN(parts[parts.length - 2])){
                        label = `${secondLastPart}.${lastPart}`;
                    } else {
                        // Otherwise, it's part of a nested object
                        label = lastPart;
                    }
                } else {
                    // If not nested, use the key itself
                    label = lastPart;
                }
                  return(
                    <div className="row" key={index}>
                        <div className="col-md-12 accls1">
                            <label className="col-md-5 rt-label" htmlFor={label}>
                            {label}
                            </label>
                            <input
                                className="rt-input_1"
                                type='text'
                                value={fieldState[apiCallName][fieldName].resValue}
                                readOnly // Make the input read-only to display the value
                            />
                            <input
                                className="rt-input"
                                type='text'
                                value={fieldState[apiCallName][fieldName].value}
                                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                disabled={fieldState[apiCallName][fieldName].checkbox}
                                onClick={() => handleInputFieldClick(fieldName)} 
                            />
                            <input
                                className="rt-checkbox"
                                type='checkbox'
                                onChange={() => handleCheckboxChange(fieldName)}
                                checked={fieldState[apiCallName][fieldName].checkbox}
                            />
                        </div>
                    </div>
                  )
              })}
            </div>
        </div>
    );
}

export default ResponseTab;

