import React, { useState, useEffect } from 'react';
import '../../../Styles/Plugins/ApiConnector/Authentication/PrivateKeyinURL.css';

function PrivateKeyinURL({onAuthObjectChange, authenticationData}) {
  const [keyNameURL, setKeyNameURL] = useState('private_key');
  const [keyValueURL, setKeyValueURL] = useState('');
  const [developmentKeyValueURL, setDevelopmentKeyValueURL] = useState('');
  
  useEffect(() => {
    if (authenticationData) {
      setKeyNameURL(authenticationData.keyNameURL || 'private_key');
      setKeyValueURL(authenticationData.keyValueURL || '');
      setDevelopmentKeyValueURL(authenticationData.developmentKeyValueURL || '');
    }
  }, [authenticationData]);

  const handleKeyNameURL = (e) => {
    setKeyNameURL(e.target.value);
  };

  const handleKeyValueURL = (e) => {
    setKeyValueURL(e.target.value);
  };

  const handleDevelopmentKeyValueURL = (e) => {
    setDevelopmentKeyValueURL(e.target.value);
  };

  useEffect(() => {
    const generateDataObject = () => {
      const dataObject = {
        keyNameURL,
        keyValueURL,
        developmentKeyValueURL
      };
      onAuthObjectChange(dataObject);
    };
    generateDataObject();
  }, [keyNameURL, keyValueURL, developmentKeyValueURL, onAuthObjectChange]);
  
  return (
    <div className="privatekeyinurl-container">
      <div className="privatekeyinurl-row">
        <label htmlFor={'keyNameURL'}>Key Name</label>
        <input id={'keyNameURL'} value={keyNameURL} type="text" onChange={handleKeyNameURL} />
      </div>
      <div className="privatekeyinurl-row">
        <label htmlFor={'keyValueURL'}>Key Value</label>
        <input id={'keyValueURL'} value={keyValueURL} type="text" onChange={handleKeyValueURL} />
      </div>

      <div className="privatekeyinurl-row1">
        <label htmlFor={'developmentKeyValueURL'}>Development Key Value</label>
        <input
          id={'developmentKeyValueURL'}
          value={developmentKeyValueURL}
          type="text"
          placeholder="(Optional)"
          onChange={handleDevelopmentKeyValueURL}
        />
      </div>
    </div>
  );
}

export default PrivateKeyinURL;
