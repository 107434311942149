import React, { useState, useEffect } from 'react'
import '../../../Styles/Plugins/ApiConnector/Authentication/ClientSideSSLCertificate.css'

function ClientSideSSLCertificate({onAuthObjectChange, authenticationData}) {
  const [certificateFileContent, setCertificateFileContent] = useState('');
  const [keyFileContent, setKeyFileContent] = useState('');

  useEffect(() => {
    if (authenticationData) {
      setCertificateFileContent(authenticationData.certificateFileContent || '');
      setKeyFileContent(authenticationData.keyFileContent || '');
    }
  }, [authenticationData]);

  const handleCertificateFileContent = (e) => {
    setCertificateFileContent(e.target.value);
  };

  const handleKeyFileContent = (e) => {
    setKeyFileContent(e.target.value);
  };

  useEffect(() => {
    const generateDataObject = () => {
      const dataObject = {
        certificateFileContent,
        keyFileContent,
      };
      onAuthObjectChange(dataObject);
    };
    generateDataObject();
  }, [certificateFileContent, keyFileContent, onAuthObjectChange]);
  

  return (
    <div className="clientsidesslcertificate-container">
      <div className="clientsidesslcertificate-row">
        <label htmlFor={'certificateFileContent'}>Certificate file content</label>
        <input id={'certificateFileContent'} value={certificateFileContent} type="textarea" onChange={handleCertificateFileContent} />
      </div>
      <div className="clientsidesslcertificate-row">
        <label htmlFor={'keyFileContent'}>Key file content</label>
        <input id={'keyFileContent'} value={keyFileContent} type="textarea" onChange={handleKeyFileContent} />
      </div>
    </div>
  )
}

export default ClientSideSSLCertificate