import React, { createContext, useState } from 'react';

export const CopyContext = createContext();

export const CopyProvider = ({ children }) => {
    const [copiedData, setCopiedData] = useState(null);

    return (
        <CopyContext.Provider value={{ copiedData, setCopiedData }}>
            {children}
        </CopyContext.Provider>
    );
};
