import React, { useState, useEffect } from 'react';
import '../../Styles/Plugins/ApiConnector/ImportFromcURL.css';

function ImportFromcURL({onClose, onImportFromcURLObjectChange, importFromcURLData}) {
  
  const [importFromcURL, setImportFromcURL] = useState('')

  useEffect(() => {
    if (importFromcURLData && importFromcURLData.importFromcURL) {
      setImportFromcURL(importFromcURLData.importFromcURL);
    }
  }, [importFromcURLData]);
  

  const handleImportFromcURL = (e) => {
    const inputValue = e.target.value;
    setImportFromcURL(inputValue);
  };

  const handleImportButtonClick = () => {
    
    const importFromObject = {
      importFromcURL: importFromcURL,
    };

    // Invoke the callback with the object
    onImportFromcURLObjectChange(importFromObject);

    onClose();
  };


    return (
        <div className="importfromcurl-modal">
          <div className="importfromcurl-modal-header">Import From cURL</div>
          <div className="importfromcurl-modal-body">
            <h4>Supported import fields are:</h4>
            <ul>
              <li>url</li>
              <li>method</li>
              <li>headers</li>
              <li>data</li>
            </ul>
            <textarea
                className="importfromcurl-textarea"
                placeholder="Enter cURL command here..."
                value={importFromcURL}
                onChange={handleImportFromcURL}
            ></textarea>
          </div>
          <div className="importfromcurl-modal-footer">
            <button className="importfromcurl-import-button" onClick={handleImportButtonClick}>Import</button>
            <button className="importfromcurl-cancel-button" onClick={onClose}>Cancel</button>
          </div>
        </div>
    );
}

export default ImportFromcURL