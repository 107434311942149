import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../Styles/Plugins/ApiConnector/ApiConnector.css';
import { Card } from 'react-bootstrap';
import AuthComponents from './Authentication/AuthComponentPath';
import ImportFromcURL from './ImportFromcURL';
import AddAnotherCall from './AddAnotherCall/AddAnotherCall';
import trash_black from '../../../Assets/Images/trash_black.png';


function ApiConnector({onDataReady, dataFromContext, documentId}) {
    
  const apiConnectorObject = dataFromContext.find(item => item.Connector_Name === 'API Connector');

  // If the object is found, extract Connector_Values
  let installedConnectorDataDB = null;
  if (apiConnectorObject) {
      installedConnectorDataDB = apiConnectorObject.Connector_Values;
  }
  
  console.log("installedConnectorDataDB:", installedConnectorDataDB);
  
  
  const [accordionStates, setAccordionStates] = useState([]);
    // const [apiNames, setApiNames] = useState(Array.from({ length: accordionStates.length }, () => ''));
    const [apiNames, setApiNames] = useState(Array.from({ length: accordionStates.length }, () => ({ apiName: '', dynamic: false })));
    const [authenticationTypes, setAuthenticationTypes] = useState(Array.from({ length: accordionStates.length }, () => 'None or self-handled'));
    const [showImportFromCurlStates, setShowImportFromCurlStates] = useState([]);

    const [addAnotherCallAccordionStates, setaddAnotherCallAccordionStates] = useState([]);
    const [addAnotherCallApiNames, setaddAnotherCallApiNames] = useState(Array.from({ length: addAnotherCallAccordionStates.length }, () => ''));

    const [apiData, setApiData] = useState([]);
    
    const [addAnotherCallResponsesArray, setAddAnotherCallResponsesArray] = useState([]);
    const [authenticationData, setAuthenticationData] = useState(null);
    const [importFromcURLData, setImportFromcURLData] = useState(null);

    const [apiRemarks, setApiRemarks] = useState(Array.from({ length: accordionStates.length }, () => ({ apiRemark: '', dynamic: false })));

    useEffect(() => {
      const apiConnectorObject = dataFromContext.find(item => item.Connector_Name === 'API Connector');
      if (apiConnectorObject) {
        const installedConnectorDataDB = apiConnectorObject.Connector_Values;
        setAccordionStates(Array.from({ length: installedConnectorDataDB.length}, () => false));
      }
    }, [dataFromContext])

  const toggleAccordion = (index) => {
    const updatedAccordionStates = [...accordionStates];
    updatedAccordionStates[index] = !updatedAccordionStates[index];
    setAccordionStates(updatedAccordionStates);
  };

  const addHeader = (apiIndex) => {
    const updatedApiData = [...apiData];
    
    // Ensure the API index exists in the array
    if (apiIndex >= 0 && apiIndex < updatedApiData.length) {
      updatedApiData[apiIndex].sharedHeaders = [...updatedApiData[apiIndex].sharedHeaders, { key: '', value: '', dynamic:false }];
      setApiData(updatedApiData);
    }
  };

  const addParameter = (apiIndex) => {
    const updatedApiData = [...apiData];
  
    // Ensure the API index exists in the array
    if (apiIndex >= 0 && apiIndex < updatedApiData.length) {
      updatedApiData[apiIndex].sharedParameters = [...updatedApiData[apiIndex].sharedParameters, { key: '', value: '', dynamic:false }];
      setApiData(updatedApiData);
    }
  };

  const updateSharedHeader = (apiIndex, headerIndex, field, newValue) => {
    const updatedApiData = [...apiData];
    updatedApiData[apiIndex].sharedHeaders[headerIndex][field] = newValue;
    setApiData(updatedApiData);
  };

  const updateSharedParameter = (apiIndex, parameterIndex, field, newValue) => {
    const updatedApiData = [...apiData];
    updatedApiData[apiIndex].sharedParameters[parameterIndex][field] = newValue;
    setApiData(updatedApiData);
  };

  const updateApiName = (index, newValue, isDynamic) => {
    const updatedApiData = [...apiData];
    updatedApiData[index].apiName = { apiName: newValue, dynamic: isDynamic };
  
    setApiData(updatedApiData);
  
    // Set the entered value in apiNames state
    const updatedApiNames = [...apiNames];
    updatedApiNames[index] = { apiName: newValue, dynamic: isDynamic };
    setApiNames(updatedApiNames);
  };
   

  const updateApiRemark = (index, newValue, isDynamic) => {
    const updatedApiData = [...apiData];
    updatedApiData[index].apiRemark = { apiRemark: newValue, dynamic: isDynamic };
  
    setApiData(updatedApiData);
    // Set the entered value in apiRemarks state
    const updatedApiRemarks = [...apiRemarks];
    updatedApiRemarks[index] = { apiRemark: newValue, dynamic: isDynamic };
    setApiRemarks(updatedApiRemarks);
  };
  
  const updateAuthenticationType = (index, newValue) => {
    // console.log(`Updating authentication type for API at index ${index} to ${newValue}`);
    const updatedApiData = [...apiData];
    updatedApiData[index].authenticationType = newValue;
    // console.log("updatedApiData",updatedApiData);
    setApiData(updatedApiData);
  };
  
  const authenticationComponents = {
    'None or self-handled': null,
    ...Object.keys(AuthComponents).reduce((acc, key) => {
      acc[key] = AuthComponents[key];
      return acc;
    }, {}),
  };


    const openImportFromCurl = (index) => {
      const updatedShowImportFromCurlStates = [...showImportFromCurlStates];
      updatedShowImportFromCurlStates[index] = true;
      setShowImportFromCurlStates(updatedShowImportFromCurlStates);
    };

    const closeImportFromCurl = (index) => {
      const updatedShowImportFromCurlStates = [...showImportFromCurlStates];
      updatedShowImportFromCurlStates[index] = false;
      setShowImportFromCurlStates(updatedShowImportFromCurlStates);
    };
    

    const addAnotherCall = (apiIndex) => {
      const updatedApiData = [...apiData];
    
      if (apiIndex >= 0 && apiIndex < updatedApiData.length) {
        const newCall = { addAnotherCallApiName: '' };
        updatedApiData[apiIndex].addAnotherCalls = [...updatedApiData[apiIndex].addAnotherCalls, newCall];
        
        // Add an empty array for addAnotherCallResponsesArray for the new API entry
        setAddAnotherCallResponsesArray((prevResponses) => [...prevResponses, []]);
    
        setApiData(updatedApiData);
      }
    };

    
    const toggleAddAnotherCallAccordion = (index) => {
      const updatedAddAnotherCallAccordionStates = [...addAnotherCallAccordionStates];
      updatedAddAnotherCallAccordionStates[index] = !updatedAddAnotherCallAccordionStates[index];
      setaddAnotherCallAccordionStates(updatedAddAnotherCallAccordionStates);
    };
    

    const updateAddAnotherCallApiNames = (apiIndex, addAnotherCallIndex, newValue) => {
      setApiData((prevApiData) => {
        const updatedApiData = [...prevApiData];
        updatedApiData[apiIndex].addAnotherCalls[addAnotherCallIndex].addAnotherCallApiName = newValue;
        return updatedApiData;
      });
    };

    const addApi = () => {
      setAccordionStates([...accordionStates, false]);
    
      // Create a new API entry with initial data
      const newApiEntry = {
        apiIndex: apiData.length, // Assign a unique API index
        apiName: '',
        apiRemark: '',
        authenticationType: 'None or self-handled',
        sharedHeaders: [],
        sharedParameters: [],
        addAnotherCalls: [],
        authenticationData: null,
        importFromcURLData: null,
        // addAnotherCallResponsesArray: [], 
      };
    
      // Update the API data with the new entry
      setApiData([...apiData, newApiEntry]);
    };    

    const handleAuthObjectChange = (dataObject) => {
      setAuthenticationData(dataObject);
    
      // Update the last added API entry with authenticationData
      setApiData((prevApiData) => {
        const updatedApiData = [...prevApiData];
        updatedApiData[prevApiData.length - 1].authenticationData = dataObject;
        return updatedApiData;
      });
    };
    
    const handleImportFromcURLObjectChange = (importFromObject) => {
      setImportFromcURLData(importFromObject);
    
      // Update the last added API entry with importFromcURLData
      setApiData((prevApiData) => {
        const updatedApiData = [...prevApiData];
        updatedApiData[prevApiData.length - 1].importFromcURLData = importFromObject;
        return updatedApiData;
      });
    };
    

    const handleAddAnotherCallObjectChange = (anotherCallDataObject, addAnotherCallIndex, apiIndex) => {
      if (anotherCallDataObject.addAnotherCallApiName.trim() !== '') {
        setApiData((prevApiData) => {
          const updatedApiData = [...prevApiData];
          const addAnotherCalls = updatedApiData[apiIndex].addAnotherCalls;
    
          // Find the corresponding addAnotherCall object in the array
          const matchingAddAnotherCall = addAnotherCalls.find(
            (call) => call.addAnotherCallApiName === anotherCallDataObject.addAnotherCallApiName
          );
    
          if (matchingAddAnotherCall) {
            // If a match is found, update the addAnotherCallResponsesArray
            matchingAddAnotherCall.addAnotherCallResponsesArray = { ...anotherCallDataObject, apiIndex };
          }
    
          return updatedApiData;
        });
      }
    };
    
    
    
    // useEffect(() => {
    //   const nonEmptyApiData = apiData.filter(api => api.apiName.trim() !== '');
    
    //   onDataReady(nonEmptyApiData);
    // }, [apiData, onDataReady]);
    
    useEffect(() => {
      const nonEmptyApiData = apiData.filter(api => api.apiName && api.apiName.apiName && typeof api.apiName.apiName.trim === 'function' && api.apiName.apiName.trim() !== '');
    
      onDataReady(nonEmptyApiData);
    }, [apiData, onDataReady]);
    
    useEffect(() => {
      // Ensure that installedConnectorDataDB is not empty
      if (installedConnectorDataDB?.length > 0) {
        // Initialize apiData state with data from installedConnectorDataDB
        setApiData(installedConnectorDataDB?.map((api, index) => ({
          // apiIndexDB: index,
          // apiName: api.apiName,
          apiName: { apiName: api.apiName?.apiName, dynamic: api.apiName?.dynamic }, 
          apiRemark: { apiRemark: api.apiRemark?.apiRemark, dynamic: api.apiRemark?.dynamic }, 
          authenticationType: api.authenticationType,
          sharedHeaders: [...api.sharedHeaders],
          sharedParameters: [...api.sharedParameters],
          addAnotherCalls: [...api.addAnotherCalls],
          authenticationData: { ...api.authenticationData },
          importFromcURLData: api.importFromcURLData ? { ...api.importFromcURLData } : null,
          // addAnotherCallResponsesArray: [...api.addAnotherCallResponsesArray],
        })));
    
        // Initialize other state variables accordingly (e.g., apiNames, authenticationTypes, etc.)
        // setApiNames(installedConnectorDataDB?.map(api => api.apiName));
        setApiNames(installedConnectorDataDB?.map(api => ({ apiName: api.apiName.apiName, dynamic: api.apiName.dynamic })));
        setApiRemarks(installedConnectorDataDB?.map(api => ({ apiRemark: api.apiRemark.apiRemark, dynamic: api.apiRemark.dynamic })));
        setAuthenticationTypes(installedConnectorDataDB?.map(api => api.authenticationType));
        // Initialize other state variables as needed

        // console.log('Authentication Data:', installedConnectorDataDB.map(api => api.authenticationData));
        // console.log('importFromcURL Data:', installedConnectorDataDB.map(api => api.importFromcURLData));
        // console.log('Add ANother Call Data:', installedConnectorDataDB.map(api => api.addAnotherCalls))
        // console.log('addAnotherCallResponsesArray:', installedConnectorDataDB.map(api => api.addAnotherCallResponsesArray));      
      }
      // else {
      //   // Handle the case when there are no documents in the database
      //   setApiData([]);
      // }
    }, [installedConnectorDataDB]);

    const handleApiDeleteClickFun = async (apiIndex) => {
      // console.log("helloooooooooooooooooooo");
      // console.log("documentId",documentId);
      // console.log("apiIndex", apiIndex);
      try {
        // Make a POST request to your backend API endpoint
        const response = await axios.post('https://aim-core.insurancepolicy4us.com/delete-api-object', {
          documentId,
          apiIndex
        });
  
        // Check if the object was deleted successfully
        if (response.status === 200) {
          console.log('Object deleted successfully');
          // Optionally, you can update your component state or perform any other actions.
        } else {
          console.error('Error deleting object:', response.data.error);
          // Handle the error, e.g., show an error message to the user.
        }
      } catch (error) {
        console.error('Error deleting object:', error.message);
        // Handle network errors or other exceptions.
      }
    };
     
    const deleteApiCall = (apiIndex, addAnotherCallIndex) => {
      setApiData((prevApiData) => {
        const updatedApiData = [...prevApiData];
    
        if (apiIndex >= 0 && apiIndex < updatedApiData.length) {
          const addAnotherCalls = updatedApiData[apiIndex].addAnotherCalls;
    
          if (addAnotherCallIndex >= 0 && addAnotherCallIndex < addAnotherCalls.length) {
            // Remove the addAnotherCall object at the specified index
            addAnotherCalls.splice(addAnotherCallIndex, 1);
    
            // Update the state with the modified addAnotherCalls array
            return updatedApiData;
          }
        }
    
        // If the provided indices are out of bounds, return the unchanged state
        return prevApiData;
      });
    };    

    const handleApiDeleteClick = (apiIndex) => {
      // console.log("apiIndex",apiIndex);
      setApiData((prevApiData) => {
        const updatedApiData = [...prevApiData];
        updatedApiData.splice(apiIndex, 1);
        return updatedApiData;
      });
    
    };
    

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="apiconnector-container border">
            <h6>API Connector</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu odio nec turpis
              hendrerit ultrices vel id quam.
            </p>
            <div className="button-container">
              {accordionStates.map((isAccordionOpen, index) => (
                <Card
                  key={index}
                  className={`accordion-card ${isAccordionOpen ? 'accordion-open' : ''}`}
                >
                  <Card.Body className="collapse-btn">
                    <span style={{ display: isAccordionOpen ? 'block' : 'none' }} className='accordian-span'>
                    <div
                      className="acc-card-input-container"
                    >
                      <label className="col-md-3" htmlFor={`apiName-${index}`}>API Name</label>
                      <input
                        id={`apiName-${index}`}
                        value={apiNames[index]?.apiName}
                        type="text"
                        onChange={(e) => updateApiName(index, e.target.value, apiNames[index]?.dynamic)}
                      />
                      {/* <input
                        id={`apiName-${index}`}
                        type="checkbox"
                        className="small-checkbox"
                      />  */}
                      <input
                        id={`apiName-${index}`}
                        type="checkbox"
                        className="small-checkbox"
                        checked={apiNames[index]?.dynamic}
                        onChange={(e) => updateApiName(index, apiNames[index]?.apiName, e.target.checked)}
                      />
                      <div className="acc-card-input-container-del-img" onClick={() => handleApiDeleteClick(index)}>
                          <img src={trash_black} alt="trash_black" className="acc-card-input-container-del-img-hover" />
                      </div>
                  </div>
                  <div
                      className="acc-card-input-container-auth"
                    >       
                       <label htmlFor={`apiRemark-${index}`}>Remarks</label>
                       <input 
                        id={`apiRemark-${index}`}
                        type="textarea"
                        value={apiRemarks[index]?.apiRemark}
                        onChange={(e) => updateApiRemark(index, e.target.value, apiRemarks[index]?.dynamic)}
                       />
                        <div className="acc-card-input-container-img" >
                          <input
                            id={`remarks-${index}`}
                            type="checkbox"
                            className='small-checkbox1'
                            checked={apiRemarks[index]?.dynamic}
                            onChange={(e) => updateApiRemark(index, apiRemarks[index]?.apiRemark, e.target.checked)}
                          /> 
                        </div>
                  </div>
                  <div
                      className="acc-card-input-container-auth"
                    >       
                       <label htmlFor={`authentication-${index}`}>Authentication</label>
                       <select
                            id={`authentication-${index}`}
                            className="acc-select"
                            value={authenticationTypes[index]}
                            onChange={(e) => updateAuthenticationType(index, e.target.value)}
                            >
                            {Object.keys(authenticationComponents).map((optionValue) => (
                                <option key={optionValue} value={optionValue}>
                                {optionValue}
                                </option>
                            ))}
                        </select>
                        <div className="acc-card-input-container-img" >

                        <input
                        id={`authentication-${index}`}
                        type="checkbox"
                        className='small-checkbox1'
                      /> 
                        </div>
                  </div>
                      <div>
                      {apiData[index].authenticationType !== 'None or self-handled' && (
                        React.createElement(authenticationComponents[apiData[index].authenticationType], {
                          onAuthObjectChange: handleAuthObjectChange,
                          authenticationData: installedConnectorDataDB ? installedConnectorDataDB[index]?.authenticationData:undefined
                        })
                      )}
                      </div>
                    <div
                      className="acc-card-input-container1"
                    >
                      <label htmlFor={`apiName-${index}`}>Shared headers for all calls</label>
                      {apiData[index].sharedHeaders.map((header, headerIndex) => (
                        <div key={headerIndex} className="shared-header-row">
                          <div className="shared-header-field">
                            <label htmlFor={`key-${index}-${headerIndex}`}>Key</label>
                            <input
                              id={`key-${index}-${headerIndex}`}
                              value={header.key}
                              type="text"
                              onChange={(e) => updateSharedHeader(index, headerIndex, 'key', e.target.value)}
                            />
                          </div>
                          <div className="shared-header-field">
                            <label htmlFor={`value-${index}-${headerIndex}`}>Value</label>
                            <input
                              id={`value-${index}-${headerIndex}`}
                              value={header.value}
                              type="text"
                              onChange={(e) => updateSharedHeader(index, headerIndex, 'value', e.target.value)}
                            />
                          </div>
                          <div className="shared-header-field">
                            <input
                              id={`dynamic-${index}-${headerIndex}`}
                              type="checkbox"
                              checked={header.dynamic}
                              onChange={(e) => updateSharedHeader(index, headerIndex, 'dynamic', e.target.checked)}
                            />
                          </div>
                        </div>
                      ))}
                      <button id={`addHeader-${index}`} className='acc-card-input-container1-btn' onClick={() => addHeader(index)}>
                        Add a shared header
                      </button>
                    </div>
                    <div
                      className="acc-card-input-container2"
                    >
                      <label htmlFor={`apiName-${index}`}>Shared parameters for all calls</label>
                      {apiData[index].sharedParameters.map((parameter, parameterIndex) => (
                        <div key={parameterIndex} className="shared-parameter-row">
                          <div className="shared-parameter-field">
                            <label htmlFor={`key-${index}-${parameterIndex}`}>Key</label>
                            <input
                              id={`key-${index}-${parameterIndex}`}
                              value={parameter.key}
                              type="text"
                              onChange={(e) => updateSharedParameter(index, parameterIndex, 'key', e.target.value)}
                            />
                          </div>
                          <div className="shared-parameter-field">
                            <label htmlFor={`value-${index}-${parameterIndex}`}>Value</label>
                            <input
                              id={`value-${index}-${parameterIndex}`}
                              value={parameter.value}
                              type="text"
                              onChange={(e) => updateSharedParameter(index, parameterIndex, 'value', e.target.value)}
                            />
                          </div>
                          <div className="shared-parameter-field">
                            <input
                              id={`dynamic-${index}-${parameterIndex}`}
                              type="checkbox"
                              checked={parameter.dynamic}
                              onChange={(e) => updateSharedParameter(index, parameterIndex, 'dynamic', e.target.checked)}
                            />
                          </div>
                        </div>
                      ))}
                      <button id={`addParameter-${index}`} className='acc-card-input-container2-btn' onClick={() => addParameter(index)}>
                        Add a shared parameter
                      </button>
                    </div>
                    <div
                      className="acc-card-input-container3"
                    >
                    {apiData?.length > 0 && apiData[index]?.addAnotherCalls?.map((addAnotherCallData, addAnotherCallIndex) => (                      
                    <AddAnotherCall
                        // apiIndexDB={apiData[index]?.apiIndexDB ?? undefined}
                        key={addAnotherCallIndex}
                        index={addAnotherCallIndex}
                        apiIndex={index} // Pass the API index to the AddAnotherCall component
                        addAnotherCallIndex={addAnotherCallIndex} // Pass the Add Another Call index
                        updateAddAnotherCallApiNames={updateAddAnotherCallApiNames}
                        isAddAnotherCallAccordionOpen={addAnotherCallAccordionStates[addAnotherCallIndex]}
                        toggleAddAnotherCallAccordion={() => toggleAddAnotherCallAccordion(addAnotherCallIndex)}
                        onAnotherCallObjectChange={(dataObject) => handleAddAnotherCallObjectChange(dataObject, addAnotherCallIndex, index)} 
                        addAnotherCallData={addAnotherCallData}   
                        deleteApiCall={deleteApiCall}             
                      />
                    ))}
                        <h6>
                          <a href="#" onClick={() => openImportFromCurl(index)}>
                            Import another call from cURL
                          </a>
                        </h6>
                        {showImportFromCurlStates[index] && (
                          <div className="import-from-curl-popup">
                            <ImportFromcURL 
                              onClose={() => closeImportFromCurl(index)} 
                              onImportFromcURLObjectChange={handleImportFromcURLObjectChange}
                              importFromcURLData={installedConnectorDataDB ? installedConnectorDataDB[index]?.importFromcURLData : undefined}
                            />
                          </div>
                        )}
                      <button id={`addAnotherCall-${index}`} className='acc-card-input-container3-btn' onClick={() => addAnotherCall(index)}>
                        Add another call
                      </button>
                    </div>
                    </span>
                    <div
                      className="acc-card-input-container_"
                      style={{ display: isAccordionOpen ? 'none' : 'flex' }}
                    >
                      <label htmlFor={`apiName-${index}`}>API Name</label>
                      <input
                        id={`apiName-${index}`}
                        // value={apiNames[index]}
                        value={apiNames[index]?.apiName}
                        type="text"
                        readOnly
                      />
                    </div>
                    <button
                      className="collapse-button"
                      onClick={() => toggleAccordion(index)}
                    >
                      {isAccordionOpen ? 'Collapse' : 'Expand'}
                    </button>
                  </Card.Body>
                </Card>
              ))}
              <button className="api-button" onClick={addApi}>
                Add Another API
              </button>
              <button className="uninstall-button">Uninstall this Plugin</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiConnector;