import React, { createContext, useState, useContext, useEffect } from 'react';
import { useProjectsContext } from './ProjectsContext'; 
import { debounce } from 'lodash';

const WorkflowContext = createContext();

export const useWorkflowContext = () => {
    return useContext(WorkflowContext);
};

export const WorkflowContextProvider = ({ children }) => {
  const { currentDatabase } = useProjectsContext();  
  const [dynamicDbDataContext,setDynamicDbDataContext] = useState([])

  console.log("currentDatabase1:", currentDatabase);
  // Cache variable
  let cache = {};

useEffect(() => {
  const fetchData = async () => {
    if (cache[currentDatabase]) {
      // Use cached data
      setDynamicDbDataContext(cache[currentDatabase]);
      return;
    }
    try {
      console.log("currentDatabase...:", currentDatabase);

      const [response] = await Promise.all([
        fetch(`https://aim-core.insurancepolicy4us.com/getdynamicevent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ databaseName: currentDatabase })
        }),
      ]);

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const result = await response.json();
      console.log("result",result);
      
      setDynamicDbDataContext(result.data);

      // Cache the result
      cache[currentDatabase] = result.data;
      console.log("...cache...", cache);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // fetchData();
  const debounceFetch = debounce(fetchData, 500); // Adjust delay as necessary
  debounceFetch();

  return () => {
    debounceFetch.cancel(); // Cleanup debounce on unmount
  };
}, [currentDatabase]);

return (
    <WorkflowContext.Provider value={{ dynamicDbDataContext }}>
      {children}
    </WorkflowContext.Provider>
);

}
