import React, { useState, useEffect } from 'react'
import AuthComponents from '../Authentication/AuthComponentPath';

function AddAnotherCallAuthentication({ onAnotherCallAuthentication, anotherCallAuthetication }) {

    const [authenticationType, setAuthenticationType] = useState(anotherCallAuthetication?.authenticationType || '');
    const [authenticationData, setAuthenticationData] = useState(anotherCallAuthetication?.authenticationData || null);

    const authenticationComponents = {
        'None or self-handled': null,
        ...AuthComponents
      };

      const updateAuthenticationType = (newValue) => {        
        setAuthenticationType(newValue);
      };

      const handleAuthObjectChange = (dataObject) =>{
        console.log("hooo", dataObject);
        setAuthenticationData(dataObject);
      }


      // Get the selected authentication component
    const SelectedAuthComponent = authenticationComponents[authenticationType];

    useEffect(() => {
      // if (initialized) {
        const generateDataObject = () => {
          const dataObject = {
            authenticationType,
            authenticationData
          };
          onAnotherCallAuthentication(dataObject);
        };
        generateDataObject();
      // }
    }, 
    [
      authenticationType,
      onAnotherCallAuthentication, 
    ]);

  return (
    <div
        className="acc-card-input-container-auth"
    >       
        <label htmlFor={`authentication`}>Authentication</label>
        <select
            id={`authentication`}
            className="acc-select"
            value={authenticationType}
            onChange={(e) => updateAuthenticationType(e.target.value)}
            >
            {Object.keys(authenticationComponents).map((optionValue) => (
                <option key={optionValue} value={optionValue}>
                {optionValue} 
                </option>
            ))}
        </select>
        <div className="acc-card-input-container-img" >

        <input
            id={`authentication`}
            type="checkbox"
            // className='small-checkbox1'
        /> 
        </div>
        <div>
            {authenticationType !== 'None or self-handled' && SelectedAuthComponent && (
                <SelectedAuthComponent 
                    onAuthObjectChange={handleAuthObjectChange}
                    authenticationData={authenticationData}
                />
            )}
        </div>
    </div>
  )
}

export default AddAnotherCallAuthentication