import React from 'react';

function FormatOptions({ selectedConvertIdentifier }) {
  
  console.log("selectedConvertIdentifier", selectedConvertIdentifier);
  
  if (selectedConvertIdentifier === 'Date') {
    return (
      <>
        <option value="" disabled>Select a format</option>
        <option value="YYYY/MM/DD">YYYY/MM/DD</option> 
        <option value="DD/MM/YYYY">DD/MM/YYYY</option> 
        <option value="DD-MM-YYYY">DD-MM-YYYY</option> 
        <option value="YYYY-MM-DD">YYYY-MM-DD</option> 
        <option value="MM/DD/YYYY">MM/DD/YYYY</option> 
        <option value="MM/DD/YYYY120000">MM/DD/YYYY 12:00:00</option> 
        <option value="MM/DD/YYYY235959">MM/DD/YYYY 23:59:59</option> 
        <option value="MM/DD/YYYY000000">MM/DD/YYYY 00:00:00</option> 
        <option value="DD-MMM-YYYY">DD-MMM-YYYY</option> 
        <option value="Timestamp">Timestamp</option> 
        <option value="TimestampString">String Representation of Timestamps</option> 
      </>
    );
  } else   if (selectedConvertIdentifier === 'VehicleNumber') {
    return (
      <>
        <option value="" disabled>Select a format</option>
        <option value="WITH HYPHEN">With Hyphen</option> 
        <option value="WITH SPACE">With Space</option> 
        <option value="WITHOUT SPACE">Without Space</option> 
        <option value="SEGMENTS">Segments( / )</option> 
        <option value="EXTRACT_FIRST_SECTION">Extract First Section</option> 
        <option value="EXTRACT_SECOND_SECTION">Extract Second Section</option> 
        <option value="EXTRACT_THIRD_SECTION">Extract Third Section</option> 
        <option value="EXTRACT_FOURTH_SECTION">Extract Fourth Section</option> 
        <option value="EXTRACT_FIRST_TWO_SECTION_WITHOUT_SPACE">Extract First Two Section Without Space</option> 
        <option value="EXTRACT_FIRST_TWO_SECTION_WITH_HYPHEN">Extract First Two Section With Hyphen</option> 
      </>
    )
  } else   if (selectedConvertIdentifier === 'Currency') {
    return (
      <>
        <option value="" disabled>Select a format</option>
        <option value="RS. ">RS. </option> 
        <option value="$">$</option> 
      </>
    )
  } 

  return null;
}

export default FormatOptions;

