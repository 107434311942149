// import React, { useState } from 'react'
// import '../../../Styles/Workflow/DynamicAction/ResponseTab/ResponseTab.css';

// function AddNestedField(actionStep, customEventText) {
//     // console.log("########actionStep#########", actionStep);
//     // console.log("########customEventText#########", customEventText);
    
//     const [addNestedFields, setAddNestedFields] = useState([]);
//     const [showPopup, setShowPopup] = useState(false); // State to manage visibility of the popup
//     const [clickedFieldIndex, setClickedFieldIndex] = useState(null);

//     const handleAddNestedFieldButtonClick = () => {
//         // const newLabel = `Step 1: Key ${addNestedFields.length + 1}`;
//         let newLabel;
//         if (actionStep.actionStep !== null && actionStep.actionStep !== undefined) {
//           newLabel = `${actionStep.actionStep}: Key ${addNestedFields.length + 1}`;
//         } 
//         else if (actionStep.customEventText !== null && actionStep.customEventText !== undefined) {
//           newLabel = `${actionStep.customEventText}: Key ${addNestedFields.length + 1}`;
//         } 
//         else if (customEventText.customEventText !== null && customEventText.customEventText !== undefined) {
//             newLabel = `${customEventText.customEventText}: Key ${addNestedFields.length + 1}`;
//         } 
//           const newField = {
//             newFieldlabel: newLabel,
//             newFieldInput: '',
//             newFieldCheckbox: '',
//           };
      
//           setAddNestedFields(prevFields => [...prevFields, newField]);
//       };

//       const handleInputFieldClick = (index) => {
//         setShowPopup(true); // Open the popup
//         setClickedFieldIndex(index); // Store the index of the clicked input field
//       };

//   return (
//     <div className='rt-outerline'>
//     {addNestedFields.map((addNestedFields, index) => (
//               <>
//                   <div className="row" key={index}>
//                         <div className="col-md-12 accls1">
//                             {/* <label className="col-md-5 rt-label" >
//                             </label> */}
//                             <input
//                                 className="col-md-5 rt-input1"
//                                 type='text'
//                                 value={addNestedFields.newFieldlabel}
//                                 onChange={(e) => {
//                                   const newFields = [...addNestedFields];
//                                   newFields[index].newFieldlabel = e.target.value;
//                                   setAddNestedFields(newFields);
//                                 }} 
//                                 placeholder='Enter key here'
//                             />
//                             <input
//                                 className="rt-input2"
//                                 type='text'
//                                 value={addNestedFields.newFieldInput}
//                                 onClick={() => handleInputFieldClick(index)} // Open popup on click
//                                 disabled={addNestedFields.newFieldCheckbox}
//                             />
//                             <input
//                                 className="rt-checkbox"
//                                 type='checkbox'
//                                 checked={addNestedFields.newFieldCheckbox}
//                                 onChange={(e) => {
//                                   const newFields = [...addNestedFields];
//                                   newFields[index].newFieldCheckbox = e.target.checked;
//                                   setAddNestedFields(newFields);
//                                 }} 
//                             />
//                         </div>
//                   </div>
//               </>
//             ))}
//         <div className="row">
//             <div className="col-md-12 rtcls1">
//                 <button className='col-md-12 rt-format-btn' onClick={handleAddNestedFieldButtonClick}>
//                     <span className="rt-text">Add Nested Field</span>
//                 </button>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default AddNestedField

import React, { useState } from 'react';
import '../../../Styles/Workflow/DynamicAction/ResponseTab/ResponseTab.css';

function AddNestedField(actionStep, customEventText) {
    const [addNestedFields, setAddNestedFields] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [clickedFieldIndex, setClickedFieldIndex] = useState(null);
    const [clickedSubFieldIndex, setClickedSubFieldIndex] = useState(null);

    const handleAddNestedFieldButtonClick = () => {
        let newLabel;
        if (actionStep.actionStep !== null && actionStep.actionStep !== undefined) {
          newLabel = `${actionStep.actionStep}: Key ${addNestedFields.length + 1}`;
        } 
        else if (actionStep.customEventText !== null && actionStep.customEventText !== undefined) {
          newLabel = `${actionStep.customEventText}: Key ${addNestedFields.length + 1}`;
        } 
        else if (customEventText.customEventText !== null && customEventText.customEventText !== undefined) {
            newLabel = `${customEventText.customEventText}: Key ${addNestedFields.length + 1}`;
        } 

        const newField = {
            mainField: {
                label: newLabel,
                input: '',
                type: 'Text',  // default type
                checkbox: false,
            },
            subFields: []  // Holds sub-fields
        };

        setAddNestedFields(prevFields => [...prevFields, newField]);
    };

    const handleAddSubField = (index) => {
        const newSubField = {
            label: '',
            input: '',
            type: 'Dropdown',  // default type for sub-fields
            options: []
        };

        setAddNestedFields(prevFields => {
            const newFields = [...prevFields];
            newFields[index].subFields.push(newSubField);
            return newFields;
        });
    };

    const handleInputFieldClick = (index, subIndex = null) => {
        setShowPopup(true);
        setClickedFieldIndex(index);
        setClickedSubFieldIndex(subIndex);  // track if a sub-field was clicked
    };

    return (
        <div className='rt-outerline'>
            {addNestedFields.map((field, index) => (
                <div className="rt-set" key={index}>
                    <div className="row">
                        <div className="col-md-12 accls1">
                            <input
                                className="col-md-5 rt-input1"
                                type='text'
                                value={field.mainField.label}
                                onChange={(e) => {
                                  const newFields = [...addNestedFields];
                                  newFields[index].mainField.label = e.target.value;
                                  setAddNestedFields(newFields);
                                }} 
                                placeholder='Enter key here'
                            />
                            <input
                                className="rt-input2"
                                type='text'
                                value={field.mainField.input}
                                onClick={() => handleInputFieldClick(index)} // Main field popup
                                disabled={field.mainField.checkbox}
                            />
                            <select
                                className="rt-select"
                                value={field.mainField.type}
                                onChange={(e) => {
                                    const newFields = [...addNestedFields];
                                    newFields[index].mainField.type = e.target.value;
                                    setAddNestedFields(newFields);
                                }}
                            >
                                <option value="Text">Text</option>
                                <option value="Dropdown">Dropdown</option>
                            </select>
                            <input
                                className="rt-checkbox"
                                type='checkbox'
                                checked={field.mainField.checkbox}
                                onChange={(e) => {
                                  const newFields = [...addNestedFields];
                                  newFields[index].mainField.checkbox = e.target.checked;
                                  setAddNestedFields(newFields);
                                }} 
                            />
                        </div>
                    </div>
                      {field.subFields.map((subField, subIndex) => (
                        <div className="row sub-field-row" key={subIndex}>
                            <div className="col-md-12 accls1">
                                <input
                                    className="col-md-5 rt-input1"
                                    type='text'
                                    value={subField.label}
                                    onChange={(e) => {
                                      const newFields = [...addNestedFields];
                                      newFields[index].subFields[subIndex].label = e.target.value;
                                      setAddNestedFields(newFields);
                                    }} 
                                    placeholder='Enter sub key here'
                                />
                                <input
                                    className="rt-input2"
                                    type='text'
                                    value={subField.input}
                                    onClick={() => handleInputFieldClick(index, subIndex)} // Sub-field popup
                                />
                                <select
                                    className="rt-select"
                                    value={subField.type}
                                    onChange={(e) => {
                                        const newFields = [...addNestedFields];
                                        newFields[index].subFields[subIndex].type = e.target.value;
                                        setAddNestedFields(newFields);
                                    }}
                                >
                                    <option value="Text">Text</option>
                                    <option value="Dropdown">Dropdown</option>
                                </select>
                                <input
                                  className="rt-checkbox"
                                  type='checkbox'
                                  checked={field.mainField.checkbox}
                                  onChange={(e) => {
                                    const newFields = [...addNestedFields];
                                    newFields[index].mainField.checkbox = e.target.checked;
                                    setAddNestedFields(newFields);
                                  }} 
                                />
                            </div>
                        </div>  
                    ))}
                    <button className='col-md-6 rt-format-btn1' onClick={() => handleAddSubField(index)}>
                      <span className="rt-text">+ Add Sub Field</span>
                    </button>
                </div>
            ))}
            <div className="row">
                <div className="col-md-12 rtcls1">
                    <button className='col-md-12 rt-format-btn' onClick={handleAddNestedFieldButtonClick}>
                        <span className="rt-text">Add Nested Field</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddNestedField;
