import React, { useState, useEffect } from 'react';
import '../../../Styles/Workflow/DynamicAction/ApiConnector.css';
import { useConnectorContext } from '../../../../Context/ConnectorContext';
import { updateStateValues } from  './ApiConnectorUtils/ApiUtils';
import { v4 as uuidv4 } from 'uuid';
import trash_black from '../../../../Assets/Images/trash_black.png';

function APIConnector(props) {
  const { connectorValues } = useConnectorContext();
  const [selectedApi, setSelectedApi] = useState(props.Input?.inputConnectorData?.selectedApi || '');
  const [apiData, setApiData] = useState({});
  const [formValues, setFormValues] = useState(props.Input?.inputConnectorData?.formValues || {});
  const [apiDataState, setApiDataState] = useState({});
  const [displayValue, setDisplayValue] = useState('');
  const [thirdLabel, setThirdLabel] = useState('')

  console.log("props.clickedInputField",props.clickedInputField);
  console.log("props.manualInputValue",props.manualInputValue);

  const [addNewFieldsInput, setAddNewFieldsInput] = useState(props.Input?.inputConnectorData?.addNewFieldsInput || []);
  const handleAddNewFieldButtonClick = () => {
    // const newLabel = `Step 1: Key ${addNewFieldsInput.length + 1}`;
    let newLabel;
    if (props.actionStep !== null && props.actionStep !== undefined) {
      newLabel = `${props.actionStep}: Key ${addNewFieldsInput.length + 1}`;
    } else if (props.customEventText !== null && props.customEventText !== undefined) {
      newLabel = `${props.customEventText}: Key ${addNewFieldsInput.length + 1}`;
    } 
      const newField = {
        newFieldlabel: newLabel,
        newFieldInput: '',
        newFieldCheckbox: '',
      };
  
      setAddNewFieldsInput(prevFields => [...prevFields, newField]);
  };

  // console.log("props.Input inside API connector:", props.Input);

  // console.log("apiData:", apiData);

  
  useEffect(() => {
    const generateDataObject = async () => {
      const dataObject = {
        selectedApi:selectedApi,
        formValues,
        addNewFieldsInput:addNewFieldsInput,
      };
      props.onInputConnectorData(dataObject);
    }
      generateDataObject();
  }, [selectedApi, formValues, addNewFieldsInput, props.onInputConnectorData]);
    
  
  const handleContainerClick = (e) => {
    // Get the currently focused element
    const focusedElement = document.activeElement;
  
    // Check if the focused element is an input field and not a checkbox
    if (
      focusedElement.tagName.toLowerCase() === 'input' &&
      focusedElement.type.toLowerCase() !== 'checkbox'
    ) {
      // Get the input field value
      const inputFieldValue = focusedElement.id;
      // console.log("inputFieldValue",inputFieldValue);
      props.setManualInputValue('');
      setDisplayValue('')
      // Call the common function to handle input click and pass the input field value
      props.openGetDataPopup(inputFieldValue);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedApi(event.target.value);
  };

  useEffect(() => {
    props.setSelectedName(selectedApi)

  }, [selectedApi])
  
  

  useEffect(() => {
    const selectedApiData = connectorValues.find((connector) => connector.apiName.apiName === selectedApi) || {};

    console.log("selectedApiData",selectedApiData);
    // Update the state
    setApiDataState(selectedApiData);
  
    if (Object.keys(selectedApiData).length > 0) {
      const apiDataHierarchy = [];
      const fieldLabelsForObject = [];
      const addAnotherCallsDetails = [];
      for (const key in selectedApiData) {
        if (Array.isArray(selectedApiData[key])) {
          // Handle arrays inside connectorValues array's objects
          const arrayLabel = key;
          // console.log("arrayLabel",arrayLabel);
          selectedApiData[key].forEach((arrayItem, arrayIndex) => {
            if (arrayItem.dynamic === true) {
              const dynamicIndexLabel = `Index ${arrayIndex}`;
              const fieldLabels = [];
              for (const nestedKey in arrayItem) {
                if (nestedKey !== 'dynamic') {
                  const fieldLabel = `${nestedKey}`;
                  fieldLabels.push(fieldLabel);
                }
              }

              apiDataHierarchy.push({
                arrayLabel,
                dynamicIndexLabel,
                fieldLabels,
              });
            }
          })

          if (arrayLabel === 'addAnotherCalls') {
            selectedApiData[key].forEach((addAnotherCall, addAnotherCallIndex) => {
                // console.log("addAnotherCallApiName:", addAnotherCall.addAnotherCallApiName);
                props.setApiCallName(addAnotherCall.addAnotherCallApiName)
        
                const addAnotherCallResponsesArray = addAnotherCall?.addAnotherCallResponsesArray;
                // console.log("addAnotherCallResponsesArray:", addAnotherCallResponsesArray);
        
                if (addAnotherCallResponsesArray && typeof addAnotherCallResponsesArray === 'object') {
                  // Check if addAnotherCallResponsesArray is an object
              
                  // Extract objects where dynamic is true
                  const dynamicResponseObjects = Object.values(addAnotherCallResponsesArray).filter(responseItem => 
                      responseItem && responseItem.dynamic === true
                  );
              
                  // Extract fieldLabels from dynamicResponseObjects
                  const fieldLabels = dynamicResponseObjects.map(responseItem => {
                      const fields = Object.keys(responseItem).filter(nestedKey =>
                          nestedKey !== 'dynamic'
                      );
                      return fields;
                  }).flat();

                  let headersArray = [];

                  // const addAnotherCallPOSTHeaders = addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallPOSTHeaders;
                  const addAnotherCallPOSTHeaders =
                  addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallHeaders ||
                  addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallPOSTHeaders;
                  console.log("addAnotherCallPOSTHeaders",addAnotherCallPOSTHeaders);
                  if (Array.isArray(addAnotherCallPOSTHeaders)) {
                    addAnotherCallPOSTHeaders.forEach((header, index) => {
                      if (header.dynamic) {
                        console.log(`addAnotherCallPOSTHeaders[${index}].key: ${header.key}`);
                        console.log(`addAnotherCallPOSTHeaders[${index}].value: ${header.value}`);

                        headersArray.push(`Headers[${index}].key`);
                        headersArray.push(`Headers[${index}].value`);
                      }
                    });
                  } else {
                    console.log("addAnotherCallPOSTHeaders is not an array or is undefined");
                  }

                  console.log("headersArray", headersArray);
                  

                  let parametersArray = [];

                  // const addAnotherCallPOSTParameters = addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallPOSTParameters;
                  const addAnotherCallPOSTParameters =
                  addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallParameters ||  
                  addAnotherCallResponsesArray?.anotherCallMethodObject?.addAnotherCallPOSTParameters;
                  console.log("addAnotherCallPOSTParameters",addAnotherCallPOSTParameters);
                  if (Array.isArray(addAnotherCallPOSTParameters)) {
                    addAnotherCallPOSTParameters.forEach((parameter, index) => {
                      if (parameter.dynamic) {
                        console.log(`addAnotherCallPOSTParameters[${index}].key: ${parameter.key}`);
                        console.log(`addAnotherCallPOSTParameters[${index}].value: ${parameter.value}`);

                        parametersArray.push(`Parameters[${index}].key`);
                        parametersArray.push(`Parameters[${index}].value`);
                      }
                    });
                  } else {
                    console.log("addAnotherCallPOSTParameters is not an array or is undefined");
                  }
            
                  const selectedBodyType = addAnotherCallResponsesArray?.anotherCallMethodObject?.selectedBodyType;

                  if (selectedBodyType && selectedBodyType.dynamic === true) {
                    const fieldsInSelectedBodyType = Object.keys(selectedBodyType).filter(key =>
                      key !== 'dynamic'
                    );
                  
                    let bodyFieldLabel; // Declare it here
                  
                    fieldsInSelectedBodyType.forEach(field => {
                      bodyFieldLabel = field; // Assign value here
                      // console.log("bodyFieldLabel", field);
                    });

                    const jsonBody = addAnotherCallResponsesArray?.anotherCallMethodObject?.jsonBody;

                    console.log("jsonBody from plugin:", jsonBody);
                    const nestedFieldLabelsArray = [];

                    // Check if jsonBody is not null or undefined before traversing
                    if (jsonBody && jsonBody !== null && typeof jsonBody === 'object') {
                      const traverseJson = (obj, path = '') => {
                        // Iterate over each key in the object
                        Object.keys(obj).forEach(key => {
                          // Construct the current path
                          const currentPath = path ? `${path}.${key}` : key;

                          // Push the path into the array if it's not a numeric key
                          if (!/^\d+$/.test(key)) {
                            nestedFieldLabelsArray.push(currentPath);
                          }

                          // Check if the value associated with the key is an object
                          if (typeof obj[key] === 'object' && obj[key] !== null) {
                            // Recursively traverse the nested object
                            traverseJson(obj[key], currentPath);
                          } else if (Array.isArray(obj[key])) {
                            // If the value is an array, iterate over each element in the array
                            obj[key].forEach((element, index) => {
                              // Construct the current path with array index
                              const arrayPath = `${currentPath}[${index}]`;
                              
                              // If the element is an object, recursively traverse it
                              if (typeof element === 'object' && element !== null) {
                                traverseJson(element, arrayPath);
                              } else {
                                // Push the path of the array element if it's not an object
                                nestedFieldLabelsArray.push(arrayPath);
                              }
                            });
                          }
                        });
                      };

                      // Start traversing the JSON object
                      traverseJson(jsonBody);

                      console.log('Paths in JSON:', nestedFieldLabelsArray);
                    } else {
                      console.log('jsonBody is null or undefined');
                    }

                    const xmlBody = addAnotherCallResponsesArray?.anotherCallMethodObject?.xmlBody;

                    const elementsWithValues = [];

                    if (xmlBody && xmlBody !== '') {
                      const parser = new DOMParser();
                      const xmlDoc = parser.parseFromString(xmlBody, 'application/xml'); 

                      // const traverseAndCollectTagNames = (element, path = '') => {
                      //   const nodes = element.children; // Get all child elements
                      //   for (let i = 0; i < nodes.length; i++) {
                      //     const node = nodes[i];
                      //     if (node.tagName) {
                      //       const currentPath = path ? `${path}.${node.tagName}` : node.tagName;
                      //       elementsWithValues.push(currentPath);
                      //       // Recursively traverse the child elements
                      //       traverseAndCollectTagNames(node, currentPath);
                      //     }
                      //   }
                      // };

                      const traverseAndCollectTagNames = (element, path = '', tagCount = {}) => {
                        const nodes = element.children; // Get all child elements
                    
                        for (let i = 0; i < nodes.length; i++) {
                          const node = nodes[i];
                          if (node.tagName) {
                            // Track occurrence count of each tag name in the current scope
                            const tagName = node.tagName;
                            tagCount[tagName] = (tagCount[tagName] || 0) + 1;
                    
                            // Append index if it's not the first occurrence of the tag
                            const currentPath = path 
                              ? `${path}.${tagName}${tagCount[tagName] > 1 ? `[${tagCount[tagName]}]` : ''}`
                              : `${tagName}${tagCount[tagName] > 1 ? `[${tagCount[tagName]}]` : ''}`;
                    
                            elementsWithValues.push(currentPath);
                    
                            // Recursively traverse child elements with a new tag count for each scope
                            traverseAndCollectTagNames(node, currentPath, {});
                          }
                        }
                      };
                      
                      traverseAndCollectTagNames(xmlDoc.documentElement);

                      console.log('Paths of tags in XML:', elementsWithValues);
                    }
                  
                    addAnotherCallsDetails.push({
                      addAnotherCallApiName: addAnotherCall.addAnotherCallApiName,
                      fieldLabels,
                      bodyFieldLabel,
                      parametersArrayFieldLabels:parametersArray,
                      headersArrayFieldLabels:headersArray,
                      nestedFieldLabels: nestedFieldLabelsArray,
                      xmltaglabels:elementsWithValues,
                    });

                  } else {
                    addAnotherCallsDetails.push({
                      addAnotherCallApiName: addAnotherCall.addAnotherCallApiName,
                      fieldLabels,
                      parametersArrayFieldLabels:parametersArray,
                      headersArrayFieldLabels:headersArray
                    });
                  }              
              }              
            });
        }
        
        } else if (typeof selectedApiData[key] === 'object' && selectedApiData[key]?.dynamic === true) {
          console.log("hello");
          // Handle objects inside connectorValues array's objects (similar to existing code)
          for (const nestedKey in selectedApiData[key]) {
            if (nestedKey !== 'dynamic') {
              const fieldLabelForObject = nestedKey; // No need to include arrayLabel for objects
              fieldLabelsForObject.push(fieldLabelForObject);
            }
          }
        }
       
      } 
  
      setApiData({
        apiDataHierarchy,
        fieldLabelsForObject,
        addAnotherCallsDetails
      });
    }
  }, [selectedApi, connectorValues]);

  const updateApiConnectorValues = async (formValues, selectedApi) => {
    try {
      console.log("formValues",formValues);
      console.log("selectedApi",selectedApi);
      
      const dataToUpdate = {
        formValues,
        selectedApi,
      };
      
      console.log("dataToUpdate",dataToUpdate);
      const response = await fetch('https://aim-core.insurancepolicy4us.com/updateapiconnectorvalues', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToUpdate),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        console.log('Document updated successfully:', data.message);
      } else {
        console.error('Failed to update document:', data.message);
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };


  const handleInputChange = (parentLabel, currentLabel, value) => {
    const updatedFormValues = {
      ...formValues,
      [parentLabel]: {
        ...formValues[parentLabel],
        [currentLabel]: value,
      },
    };
    setFormValues(updatedFormValues);
    console.log("updatedFormValues",updatedFormValues);
    console.log("formValues",formValues);

    // Call the update function when input changes

    // updateStateValues(updatedFormValues, apiDataState);
  };

  useEffect(() => {
    // Check if props.clickedInputField and props.manualInputValue are truthy
    if (props.clickedInputField && props.manualInputValue) {

      const inputFieldParts = props.clickedInputField.split(',');
      console.log("inputFieldParts:", inputFieldParts);
      const parentLabel = inputFieldParts[0];
      const currentLabel = inputFieldParts[1];
      console.log("currentLabel", currentLabel)
      console.log("manualInputValue", props.manualInputValue)      
     
        const updatedFormValues = {
            ...formValues,
            [parentLabel]: {
              ...formValues[parentLabel],
              [currentLabel]:props.manualInputValue
            },
        }
      // setFormValues(updatedFormValues);
      // console.log("displayValue:", displayValue);
      // console.log("formValues:", updatedFormValues)
      // Check if the field belongs to addNewFieldsInput
      const addNewFieldIndex = addNewFieldsInput.findIndex(
        (field) => field.newFieldlabel === currentLabel
      );

      if (addNewFieldIndex !== -1) {
        const newFields = [...addNewFieldsInput];
        newFields[addNewFieldIndex].newFieldInput = props.manualInputValue;
        setAddNewFieldsInput(newFields);
      } else {
        setFormValues(updatedFormValues);
      }

    }
  }, [props.clickedInputField, props.manualInputValue, displayValue, formValues, addNewFieldsInput]);

  
  return (
    <div onClick={handleContainerClick}>
      <div className='ccrt-outerline'>
        {addNewFieldsInput.map((addNewField, index) => (
          <>
              <div className="row" key={index}>
                    <div className="col-md-12 accls1">
                        {/* <label className="col-md-5 rt-label" >
                        </label> */}
                        <input
                            className="col-md-5 ccrt-input1"
                            type='text'
                            value={addNewField.newFieldlabel}
                            onChange={(e) => {
                              const newFields = [...addNewFieldsInput];
                              newFields[index].newFieldlabel = e.target.value;
                              setAddNewFieldsInput(newFields);
                            }} 
                            placeholder='Enter key here'
                            // disabled={fieldState[apiCallName][fieldName].checkbox}
                        />
                        <input
                            className="ccrt-input2"
                            type='text'
                            value={addNewField.newFieldInput}
                            onChange={(e) => handleInputChange(selectedApi, addNewField.newFieldlabel, e.target.value)}
                            disabled={addNewField.newFieldCheckbox}
                            id={`${selectedApi},${addNewField.newFieldlabel}`} 
                        />
                        <input
                            className="ccrt-checkbox"
                            type='checkbox'
                            checked={addNewField.newFieldCheckbox}
                            onChange={(e) => {
                              const newFields = [...addNewFieldsInput];
                              newFields[index].newFieldCheckbox = e.target.checked;
                              setAddNewFieldsInput(newFields);
                            }} 
                            // checked={fieldState[apiCallName][fieldName].checkbox}
                        />
                    </div>
              </div>
          </>
        ))}
        <div className="row">
        <div className="col-md-12 rtcls1">
          <button className='col-md-12 rt-format-btn' onClick={handleAddNewFieldButtonClick}>
            <span className="rt-text">Add New Field</span>
          </button>
        </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 accls1">
          <label className="col-md-5 ac-label1" htmlFor="to" >
            API Name:
          </label>
          <select
            className="ac-select1"
            value={selectedApi}
            onChange={handleSelectChange}
            disabled={!props.actionLabel}
          >
            <option value="" disabled>Select a API Name</option>
            <option value="Dummy API">Dummy API</option>
            {connectorValues.map((connector) => (
              <option key={connector.id} value={connector.apiName.apiName}>
                {connector.apiName.apiName}
              </option>
            ))}                
          </select>      
        </div>
      </div>
    {apiData.fieldLabelsForObject && apiData.fieldLabelsForObject.map((fieldLabelForObject, fieldIndex) => (
      <div className="row" key={`fieldLabelForObject-${fieldIndex}`}>
        <div className="col-md-12 accls2">
          <label className="col-md-5 ac-label2" htmlFor={`fieldLabelForObject-${fieldIndex}`}>
            {fieldLabelForObject}
          </label>
          <input 
            type="text" 
            id={`${selectedApi},${fieldLabelForObject}`} 
            className="form-control" 
            value={(formValues[selectedApi]?.[fieldLabelForObject]) || ''}
            onChange={(e) => handleInputChange(selectedApi, fieldLabelForObject, e.target.value)}
          />
        </div>
      </div>
    ))}
    {apiData.apiDataHierarchy && apiData.apiDataHierarchy.map((item, index) => (
      <>
        <div className="row">
          <div key={index} className="col-md-12 accls2">
            <label className="col-md-8 ac-label_2">{item.arrayLabel} [{item.dynamicIndexLabel}]</label>
          </div>
        </div>
        
        {item.fieldLabels && item.fieldLabels.map((field, fieldIndex) => (
          <div className="row">
            <div key={index} className="col-md-12 accls2">
              <label className="col-md-5 ac-label2">{field}</label>
              <input 
                type="text" 
                className="form-control" 
                id={`${item.arrayLabel} [${item.dynamicIndexLabel}],${field}`}
                value={(formValues[`${item.arrayLabel} [${item.dynamicIndexLabel}]`]?.[field]) || ''}
                onChange={(e) => {
                  console.log("labellll")
                  handleInputChange(`${item.arrayLabel} [${item.dynamicIndexLabel}]`, field, e.target.value);
                }}   
              />
            </div>
          </div>
        ))}
      </>
    ))}
    {apiData.addAnotherCallsDetails && apiData.addAnotherCallsDetails.map((item, index) => (
      <>
        <div className="row">
          <div key={index} className="col-md-12 accls2">
            <label className="col-md-8 ac-label_2">{item.addAnotherCallApiName}</label>
          </div>
        </div>
        {item.fieldLabels && item.fieldLabels.map((field, fieldIndex) => (
          <div className="row">
            <div key={index} className="col-md-12 accls2">
              <label className="col-md-5 ac-label2">{field}</label>
              <input 
                type="text" 
                className="form-control" 
                id={`${item.addAnotherCallApiName},${field}`} 
                value={(formValues[item.addAnotherCallApiName]?.[field]) || ''}
                onChange={(e) => handleInputChange(item.addAnotherCallApiName, field, e.target.value)}
              />
            </div>
          </div>
        ))}
        { item.bodyFieldLabel && (
          <div className="row">
            <div className="col-md-12 accls2">
              <label className="col-md-5 ac-label2">{item.bodyFieldLabel}</label>
              <input 
                type="text" 
                className="form-control"
                id={`${item.addAnotherCallApiName},${item.bodyFieldLabel}`}
                value={(formValues[item.addAnotherCallApiName]?.[item.bodyFieldLabel]) || ''}
                onChange={(e) => handleInputChange(item.addAnotherCallApiName, item.bodyFieldLabel, e.target.value)}
              />
            </div>
          </div>
        )}
        {item.headersArrayFieldLabels && item.headersArrayFieldLabels.map((headersArrayFieldLabel, fieldIndex) => {
  
          return (
            <div className="row" key={fieldIndex}>
              <div className="col-md-12 accls2">
                <label className="col-md-5 ac-label2">{headersArrayFieldLabel}</label>
                <input 
                  type="text" 
                  className="form-control"
                  id={`${item.addAnotherCallApiName},${headersArrayFieldLabel}`}   
                  value={(formValues[item.addAnotherCallApiName]?.[headersArrayFieldLabel]) || ''}
                  onChange={(e) => handleInputChange(item.addAnotherCallApiName, headersArrayFieldLabel, e.target.value)}
                />
              </div>
            </div>
          );
        })}

        {item.parametersArrayFieldLabels && item.parametersArrayFieldLabels.map((parametersArrayFieldLabel, fieldIndex) => {
          
          return (
            <div className="row" key={fieldIndex}>
              <div className="col-md-12 accls2">
                <label className="col-md-5 ac-label2">{parametersArrayFieldLabel}</label>
                <input 
                  type="text" 
                  className="form-control"
                  id={`${item.addAnotherCallApiName},${parametersArrayFieldLabel}`}   
                  value={(formValues[item.addAnotherCallApiName]?.[parametersArrayFieldLabel]) || ''}
                  onChange={(e) => handleInputChange(item.addAnotherCallApiName, parametersArrayFieldLabel, e.target.value)}
                />
              </div>
            </div>
          );
        })}

        {item.nestedFieldLabels && item.nestedFieldLabels.map((nestedFieldLabel, fieldIndex) => {
          // Split the nestedFieldLabel by dots to get individual parts
          const parts = nestedFieldLabel.split('.');
          // Determine the label based on the number of parts and if the second-to-last part is numeric
          const label = parts.length > 1 && !isNaN(parts[parts.length - 2])
          ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}`
          : parts[parts.length - 1];

          return (
            <div className="row" key={fieldIndex}>
              <div className="col-md-12 accls2">
                <label className="col-md-5 ac-label2">{label}</label>
                <input 
                  type="text" 
                  className="form-control"
                  id={`${item.addAnotherCallApiName},${nestedFieldLabel}`}   
                  value={(formValues[item.addAnotherCallApiName]?.[nestedFieldLabel]) || ''}
                  onChange={(e) => handleInputChange(item.addAnotherCallApiName, nestedFieldLabel, e.target.value)}
                />
              </div>
            </div>
          );
        })}


        {item.xmltaglabels && item.xmltaglabels.map((xmltaglabel, fieldIndex) => {
          // Split the xmltaglabel by dots to get individual parts
          const parts = xmltaglabel.split('.');
          // Determine the label based on the number of parts and if the second-to-last part is numeric
          const label = parts.length > 1 && !isNaN(parts[parts.length - 2])
            ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}`
            : parts[parts.length - 1];

          return (
            <div className="row" key={fieldIndex}>
              <div className="col-md-12 accls2">
                <label className="col-md-5 ac-label2">{label}</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id={`${item.addAnotherCallApiName},${xmltaglabel}`}   
                  value={(formValues[item.addAnotherCallApiName]?.[xmltaglabel]) || ''}
                  onChange={(e) => handleInputChange(item.addAnotherCallApiName, xmltaglabel, e.target.value)}
                />
              </div>
            </div>
          );
        })}

      </>
    ))}
  </div>

  )
}

export default APIConnector