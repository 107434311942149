import React from 'react';
import '../../../../Styles/Plugins/ApiConnector/AddAnotherCall/DynamicParams/DynamicParams.css';

function DynamicParams({ parameter, updateDynamicParameter }) {
  // console.log("parameter",parameter);
  const { index, key, value, private: isPrivate, allowblank } = parameter;

  return (
    <div className="dynamicparams-acc-card-input-container">
      <div className="dynamicparams-parameter-row">
        <div className="dynamicparams-parameter-field">
          <label htmlFor={`key-${index}`}>Key</label>
          <input
            id={`key-${index}`}
            value={key}
            type="text"
            onChange={(e) => updateDynamicParameter('key', e.target.value)}
          />
        </div>
        <div className="dynamicparams-parameter-field">
          <label htmlFor={`value-${index}`}>Value</label>
          <input
            id={`value-${index}`}
            value={value}  // Make sure value is assigned correctly
            type="text"
            onChange={(e) => updateDynamicParameter('value', e.target.value)}
          />
        </div>
        <div className="dynamicparams-parameter-field">
          <label htmlFor={`private-${index}`}>Private</label>
          <input
            id={`private-${index}`}
            checked={isPrivate}
            type="checkbox"
            onChange={() => updateDynamicParameter('private', !isPrivate)}
          />
        </div>
        <div className="dynamicparams-parameter-field">
          <label htmlFor={`allowblank-${index}`}>Allow Blank</label>
          <input
            id={`allowblank-${index}`}
            checked={allowblank}
            type="checkbox"
            onChange={() => updateDynamicParameter('allowblank', !allowblank)}
          />
        </div>
      </div>
    </div>
  );
}

export default DynamicParams;
