import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useProjectsContext } from "../Context/ProjectsContext";
import { authContext } from "../Context/AuthContext";

export const ProtectedProjectRoute = ({ children, allowedRoles }) => {
  const { currentDatabase } = useProjectsContext();
  const { token, access } = useContext(authContext);

  const isAllowed = allowedRoles.includes(access);

  if (token && isAllowed) {
    if (currentDatabase) {
      return children;
    } else {
      return <Navigate to="/createproject" replace={true} />;
    }
  } else {
    return <Navigate to="/login" replace={true} />;
  }
};

export const ProtectedAuthRoute = ({ children, allowedRoles }) => {
  const { token, access } = useContext(authContext);

  const isAllowed = allowedRoles.includes(access);
  const accessibleRoute =
    token && isAllowed ? children : <Navigate to="/login" replace={true} />;

  return accessibleRoute;
};