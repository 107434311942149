import DocIndexKey from "./DocIndexKey"
import DocIndexValue from "./DocIndexValue"
import ValueResultKey from "./ValueResultKey"

const bodycomponents = {
    'doc-index-key': DocIndexKey,
    'doc-index-value': DocIndexValue,
    'value-from-result-key': ValueResultKey
}

export default bodycomponents