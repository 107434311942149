import React from 'react';

function IdentifierOptions() {
  return (
    <>
      <option value="" disabled>Select an identifier</option>
      <option value="Boolean">Boolean</option> 
      <option value="Date">Date</option> 
      <option value="Email">Email</option> 
      <option value="Integer">Integer</option> 
      <option value="Password">Password</option> 
      <option value="Percentage">Percentage</option> 
      <option value="PhoneNumber">Phone Number</option> 
      <option value="Text">Text</option> 
      <option value="VehicleNumber">Vehicle Number</option>
    </>
  );
}

export default IdentifierOptions;
