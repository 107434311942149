import React, { createContext, useState } from 'react';

export const CopyContext = createContext();

export const CopyEventProvider = ({ children }) => {
    const [copiedEvent, setCopiedEvent] = useState(null);

    return (
        <CopyContext.Provider value={{ copiedEvent, setCopiedEvent }}>
            {children}
        </CopyContext.Provider>
    );
};
