import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import WorkflowContainer from './WorkflowContainer';
import '../Styles/Workflow/DynamicEvent.css';
import VerticalNavbar from '../VerticalNavbar/VerticalNavbar';
import HorizontalNavbar from '../HorizontalNavbar/HorizontalNavbar';
import ApiReceiver from '../ApiReceiver/ApiReceiver';
import Plugins from '../Plugins/Plugins';

function DynamicEvent() {
    const [showWorkflowContainer, setShowWorkflowContainer] = useState(false);
    const [showApiReceiver, setShowApiReceiver] = useState(false);
    const [showPlugins, setShowApiPlugins] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');
    const location = useLocation();

    const handleWorkflowButtonClick = () => {
        setShowApiReceiver(false)
        setShowApiPlugins(false)
        setShowWorkflowContainer(!showWorkflowContainer);
    };

    const handleApiReceiverButtonClick = () => {
        setShowWorkflowContainer(false);
        setShowApiPlugins(false)
        setShowApiReceiver(!showApiReceiver)
    };

    const handlePluginButtonClick = () => {
        setShowWorkflowContainer(false);
        setShowApiReceiver(false)
        setShowApiPlugins(!showPlugins)
    };

    const navigate = useNavigate();

    const handleHomeButtonClick = () => {
        navigate('/createproject');
    }

    useEffect(() => {
        // Extract 'tab' query parameter from the URL
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab') || ''; // If 'tab' is not present, set it to an empty string
        setSelectedTab(tab);
      }, [location.search]);

    return (
        <>
        <HorizontalNavbar handleHomeButtonClick={handleHomeButtonClick} />
        <VerticalNavbar selectedTab={selectedTab} />
        <div className="container mt-5 dynamic-event-container">
            <div className="row">
                <div className="col-md-12"> 
                    {selectedTab === 'workflow' && <WorkflowContainer selectedTab={selectedTab}/>}
                    {selectedTab === 'apinode' && <ApiReceiver />}
                    {selectedTab === 'plugins' && <Plugins />}
                </div>
            </div>
        </div>
        </>
    );
}

export default DynamicEvent;
