import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable';
import square_exit_white from '../../../../../Assets/Images/square_exit_white.png';
import save from '../../../../../Assets/Images/save.png';
import '../../../../Styles/Workflow/DynamicAction/GetDataFromDB/GetDataForPopup/GetDataForPopup.css';
import bodycomponents from './PopupPaths';

function GetDataForPopup({onClose, clickedInputId, onDataForDBConnector, dataFromDBComponents, dataFromComponents}) {

    const [finalValueState, setFinalValueState] = useState({});
    const [valuesFromComponents, setValuesFromComponents] = useState({});

    const closeGetDataPopup = () => {
        onClose();
    }

    console.log("clickedInputId:", clickedInputId);
    // Get the component based on clickedInputId
    const ComponentToRender = bodycomponents[clickedInputId];

    const onValueChangeForDB = (finalValue, clickedInputId) => {
        // Update the state with the finalValue for the clickedInputId
        setFinalValueState(prevState => ({
            ...prevState,
            [clickedInputId]: finalValue
        }));
        console.log(`${finalValue} of ${clickedInputId}`);
    }

    const onDataFromComponents = (dataObject) => {
        setValuesFromComponents((prevDataObject) => {
            const mergedDataObject = { ...prevDataObject, ...dataObject };
            return mergedDataObject
        })
    }

    const handleSaveForDBConnector = () => {
        if (finalValueState){
            onDataForDBConnector(finalValueState);
            closeGetDataPopup();
        }
    }

    useEffect(() => {
        const generateDataObject = async () => {
            const dataObject ={
                [clickedInputId]:{
                    valuesFromComponents:valuesFromComponents
                }
            }
            dataFromDBComponents(dataObject)
        }

        generateDataObject()
      
    }, [
        clickedInputId,
        valuesFromComponents,
        dataFromDBComponents
    ])


    return (
        <Draggable>
            <div className="get-data-for-indexkey-popup">
                <div className="get-data-for-indexkey-popup-header">
                    <h4 className='get-data-for-indexkey-header-h4'>{clickedInputId}</h4>
                    <div className="get-data-for-indexkey-container-img" >
                        <img src={save} alt="save" className="get-data-for-indexkey-container-img-hover" onClick={handleSaveForDBConnector}/>
                    </div>
                    <div className="get-data-for-indexkey-container-img" >
                        <img src={square_exit_white} alt="square_exit_white" className="get-data-for-indexkey-container-img-hover" onClick={closeGetDataPopup}/>
                    </div>
                </div>
                <div className="get-data-for-indexkey-popup-body">
                    {ComponentToRender && 
                        <ComponentToRender 
                            clickedInputId={clickedInputId}
                            onValueChangeForDB={onValueChangeForDB}
                            onDataFromComponents={onDataFromComponents}
                            dataFromComponents={dataFromComponents}
                        />
                    }
                </div>
            </div>
        </Draggable>
    )
}

export default GetDataForPopup