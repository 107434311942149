import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/HomePage/HomePage.css';

function HomePage() {
  return (
    <div className="hp-container">
        <div className="hp-header">
            <Link to="/login" className="login-button">Login</Link>
        </div>
        <div className="hp-title">AIM</div>
    </div>  
 );
}

export default HomePage;
