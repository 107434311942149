import React, { useState, useEffect, useContext } from 'react';
import CustomEventPopup from './CustomEventPopup'
import CustomActionPopup from './CustomActionPopup'
import '../Styles/Workflow/WorkflowContainer.css';
import axios from 'axios';
import { debounce } from 'lodash';
import trash_black from '../../Assets/Images/trash_black.png';
import copy_gray from '../../Assets/Images/copy_gray.png';
import { WorkflowContextProvider, useWorkflowContext } from '../../Context/WorkflowContext';
import { CopyContext } from '../../Context/CopyPasteEvent';

const WorkflowContainer = ({ selectedTab }) => {
  
  const [showPopover, setShowPopover] = useState(false);
  const [showCustomOption, setShowCustomOption] = useState(false);
  const [customEvents, setCustomEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [customEventName, setCustomEventName] = useState('');
  const [combinedData, setCombinedData] = useState({});
  const [dynamicDbData,setDynamicDbData] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { dynamicDbDataContext } = useWorkflowContext();

  const { copiedEvent, setCopiedEvent } = useContext(CopyContext);


  const handleAddEvent = () => {
    setShowPopover(!showPopover);
    setShowPopup(false);
    setShowActionPopup(false);
  };

  const handleCustomMouseEnter = () => {
    setShowCustomOption(true);
  };

  const handleGeneralMouseEnter = () => {
    setShowCustomOption(false);
  };
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // console.log('Selected Tab in WorkflowContainer:', selectedTab);
  //     try {
  //       // Make the API request to your backend endpoint
  //       // const response = await fetch('https://aim-core.insurancepolicy4us.com/getdynamicevent');
  //       const [response] = await Promise.all([
  //         fetch('https://aim-core.insurancepolicy4us.com/getdynamicevent'),
  //         // Add other parallel requests if needed
  //       ]);

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const result = await response.json();
  //       // console.log("result.data",result.data);
  //       setDynamicDbData(result.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   // fetchData();
  //   // Fetch data only when the 'workflow' tab is selected or forceUpdate is triggered
  //   if (selectedTab === 'workflow' || forceUpdate) {
  //     fetchData();
  //     // Reset forceUpdate to false after re-render
  //     setForceUpdate(false);
  //   }
  // }, [selectedTab, forceUpdate]); 

   // Find the maximum selectedIndex from dynamicDbData
   
   useEffect(() => {
    setDynamicDbData(dynamicDbDataContext)
   }, [dynamicDbDataContext])

   
   const maxSelectedIndex = dynamicDbData.length > 0
   ? Math.max(...dynamicDbData.map(event => event.selectedIndex))
   : -1;
   // console.log("maxSelectedIndex", maxSelectedIndex);

  const handleCreateCustomEventClick = () => {
    // Create a new custom event
    const newCustomEvent = {
      id: customEvents.length + 1, // Unique identifier for each event
      text: 'Custom Event ' + (customEvents.length + 1),
    };
  
    // Calculate the index for the new custom event
    // const newIndex =customEvents.length;
    const newIndex =
    customEvents.length +
    (maxSelectedIndex !== undefined ? maxSelectedIndex + 1 : 0);
  
    // Add the new custom event to the beginning of the list
    setCustomEvents([newCustomEvent, ...customEvents]);
  
    // Call handlePopupShow with the index of the new custom event
    handlePopupShow(newIndex);
  
    // Hide the popover
    setShowPopover(false);
  }; 

  const handlePopupClose = () => {
    setShowPopup(false);
    // setSelectedEventIndex(null);
  };

  // const handlePopupShow = (index) => {
    
  //   setSelectedEventIndex(index)
  //   setShowPopup(true);
  //   setShowActionPopup(true);
  // };
  const handlePopupShow = (index) => {
    // Close the existing popup before showing a new one
    handlePopupClose();
  
    setSelectedEventIndex(index);
    setShowPopup(true);
    setShowActionPopup(true);
  };
  
  
  const handleEventNameChange = (index, eventName) => {
    setCustomEvents((prevEvents) =>
      prevEvents.map((event, i) =>
        i === index ? { ...event, text: eventName } : event
      )
    );
    // setCustomEventName(eventName); // Update the custom event name immediately
  };

  const handleFieldChange = (dataObject) => {
    // console.log("dataObject from event:", dataObject);
    setCombinedData((prevData) => ({ ...prevData, fieldData: dataObject }));
  };
  
  const handleActionChange = (newDataObject) => {
    // console.log("newDataObject from Action:", newDataObject);
    setCombinedData((prevData) => ({ ...prevData, actionData: newDataObject }));
  };
  
  // console.log("combinedData",combinedData);

  const handleSubmit = async () => {
    console.log("hiiiii");
    try {
      console.log("combinedData",combinedData);
      const response = await axios.post('https://aim-core.insurancepolicy4us.com/dynamicevent', { data: combinedData });
      console.log('MongoDB Update Response:', response.data);
      setForceUpdate((prev) => !prev);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.data) {
        // If the server responds with an error, log the error message
        console.error('Axios Error Response:', error.response.data);
      } else {  
        // Otherwise, log the general error message
        console.error('Axios Error Message:', error.message);
      }
    }    
  };
  
  // useEffect(() => {
  //   // Trigger handleSubmit when both field and action data are available
  //   if (combinedData.fieldData && combinedData.actionData) {
  //     handleSubmit();
  //   }
  // }, [combinedData]);
  
  const confirmDelete = (selectedIndex) => {
    if (window.confirm("Are you sure you want to delete?")) {
        handleDbDocDeleteClick(selectedIndex);
    }
  };


  const copyEvent = async (selectedIndex) => {
    console.log("Copy Event");
    try {
      const response = await fetch(`https://aim-core.insurancepolicy4us.com/getDynamicEvent/${selectedIndex}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const copiedEvent = await response.json();
      console.log('Copied Event:', copiedEvent);
      setCopiedEvent(copiedEvent)
    } catch (error) {
      console.error('Error copying event:', error);
    }
  };

  const handlePaste = async () => {
    console.log("Pasting button clicked...");
    if (!copiedEvent) {
      console.log('No event to paste');
      return;
    }
  
    try {
      if (copiedEvent) {
        console.log("inside paste");
        console.log("copiedEvent inside paste", copiedEvent);
    
        const response = await fetch('https://aim-core.insurancepolicy4us.com/pasteDynamicEvent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(copiedEvent),
        });
    
        if (!response.ok) {
          throw new Error('Failed to paste data');
        }
    
        const newEvent = await response.json();
        setDynamicDbData((prevData) => [...prevData, newEvent]);
        window.location.reload();
        console.log('Pasted Event:', newEvent);
      }
    } catch (error) {
      console.error('Error pasting event:', error);
    }
  };

  const handleDbDocDeleteClick = async (selectedIndex) => {
    console.log("delete_selectedIndex",selectedIndex);
    try {
      const response = await fetch(`https://aim-core.insurancepolicy4us.com/deleteDynamicEvent/${selectedIndex}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete data');
      }

      setShowPopup(false);
      setShowActionPopup(false);
      // Update the state after successful deletion
      setDynamicDbData((prevData) => prevData.filter((event) => event.selectedIndex !== selectedIndex));
     

    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };  

  const handleDeleteAction = (deletedActionStep) => {
    // Update the state to reflect the deletion
    setDynamicDbData((prevData) => {
      const newData = [...prevData];

      // Find the correct document in newData based on selectedEventIndex
      const updatedDocument = newData.find((data) => data.selectedIndex === selectedEventIndex);

      if (updatedDocument) {
        // Remove the deleted action from dynamic_actions
        updatedDocument.dynamic_actions = updatedDocument.dynamic_actions.filter(
          (action) => action.step !== deletedActionStep
        );
      }

      return newData;
    });
  };

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase()); 
  };

  // // Filter the dynamicDbData based on the search query
  // const filteredDynamicDbData = dynamicDbData.filter(event => 
  //   event.Trigger && event.Trigger.eventName && event.Trigger.eventName.toLowerCase().includes(searchQuery)
  // );
  const filteredDynamicDbData = dynamicDbData.filter(event => 
    (event.Trigger.eventName || '').toLowerCase().includes(searchQuery)
  );

  // // Filter the customEvents based on the search query
  // const filteredCustomEvents = customEvents.filter(event =>
  //   event.text.toLowerCase().includes(searchQuery)
  // );


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="workflow-container border">
            <div className="position-relative ">
            <div className='button-div-event'>
                <input
                  type="text"
                  className="custom-event-db-search-input"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)}
                />

              <div className="custom-event-db-input-container-save-img">
                
                <button
                  className="custom-event-db-input-container-save-img-hover1"
                  onClick={() => {
                    console.log("Button clicked");
                    handlePaste();
                  }}
                >
                  Paste
                </button>
                <button               
                  className="custom-event-db-input-container-save-img-hover"
                  onClick={handleSubmit}
                >
                    Save
                </button>
              </div>
            </div>
            {/* {dynamicDbData && dynamicDbData.length > 0 && dynamicDbData.map((event) => ( */}
            {filteredDynamicDbData.length > 0 && filteredDynamicDbData.map((event, index) => (
                <button
                  key={event._id}
                  className={`btn btn-light border big-button1 big-button1-${event.selectedIndex + 1}`}
                  onClick={() => {
                    if (selectedEventIndex !== null && selectedEventIndex !== event.selectedIndex) {
                      // Close the existing popup before showing a new one
                      handlePopupClose();
                    }
                    handlePopupShow(event.selectedIndex);
                  }}
                >
                  <h6>{event.Trigger.eventName}</h6>
                  <p style={{fontSize:"12px", marginTop:"-8px", marginBottom:"-10px"}}>({index+1} -- {event.selectedIndex})</p>

                  <div className="custom-event-db-input-container-img1">
                    <img 
                      src={copy_gray} 
                      alt="copy_gray" 
                      className="custom-event-db-input-container-img-hover1" 
                      onClick={() => copyEvent(event.selectedIndex)}
                    />
                  </div>
                  <div className="custom-event-db-input-container-img">
                    <img 
                      src={trash_black} 
                      alt="trash_black" 
                      className="custom-event-db-input-container-img-hover" 
                      // onClick={() => handleDbDocDeleteClick(event.selectedIndex)}
                      onClick={() => confirmDelete(event.selectedIndex)}
                    />
                  </div>
                </button>
              ))}
              {[...customEvents].reverse().map((event, index) => (
                <button
                  key={event.id}
                  className={`btn btn-light border big-button1 big-button1-${index + (maxSelectedIndex !== undefined ? maxSelectedIndex + 1 : 0)}`}
                  onClick={() => {
                    if (selectedEventIndex !== null && selectedEventIndex !== index + (maxSelectedIndex !== undefined ? maxSelectedIndex + 1 : 0)) {
                      // Close the existing popup before showing a new one
                      handlePopupClose();
                    }
                    handlePopupShow(index + (maxSelectedIndex !== undefined ? maxSelectedIndex + 1 : 0));
                  }}
                >
                  <h6>{event.text}</h6>
                  {customEventName ? (
                    <p>{customEventName}</p>
                  ) : (
                    <p>Create a custom event</p>
                  )}
                  <div className="custom-event-input-container-img">
                    <img src={trash_black} alt="trash_black" className="custom-event-input-container-img-hover" />
                  </div>
                </button>
              ))}
              <button
                className="btn btn-light border-dotted big-button"
                onClick={handleAddEvent}
              >
                Click here to add an event
              </button>
              {showPopover && (
                <div className="popover-content">
                  <div className="left-content">
                    <p
                      className="general-text"
                      onMouseEnter={handleGeneralMouseEnter}
                    >
                      General
                    </p>
                    <p
                      className="custom-text"
                      onMouseEnter={handleCustomMouseEnter}
                    >
                      Custom
                    </p>
                  </div>
                  <div className="separator"></div>
                  <div className="right-content">
                    {showCustomOption && (
                      <p onClick={handleCreateCustomEventClick}>
                        Create a custom Event
                      </p>
                    )}
                  </div>
                </div>
              )}
              {showPopup && (
                <CustomEventPopup
                  selectedEventIndex={selectedEventIndex}
                  onClose={handlePopupClose}
                  onEventNameChange={handleEventNameChange}
                  onFieldChange = {handleFieldChange}
                  dynamicDbData={dynamicDbData}
                  eventData={
                    dynamicDbData.length > 0
                      ? dynamicDbData.find((data) => data.selectedIndex === selectedEventIndex)
                      : undefined
                  }  
                />
              )}

              {showActionPopup && (
                <CustomActionPopup
                  selectedEventIndex={selectedEventIndex}
                  onClose={() => setShowActionPopup(false)}
                  onCloseEvent={() => setShowPopup(false)}
                  showWorkflowPopup={showPopup}
                  onActionObjectChange = {handleActionChange}
                  selectedIndex={
                    dynamicDbData.length > 0
                      ? dynamicDbData.find((data) => data.selectedIndex === selectedEventIndex)?.selectedIndex
                      : null
                  }
                  actionData={
                    dynamicDbData.length > 0
                      ? dynamicDbData.find((data) => data.selectedIndex === selectedEventIndex)?.dynamic_actions
                      : null
                  }   
                  onDeleteAction={handleDeleteAction}               
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowContainer;