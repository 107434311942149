import React from 'react';

function ConvertValuesOptions({ selectedConvertIdentifier }) {
  if (selectedConvertIdentifier === 'Date') {

    return (
      <>
        <option value="" disabled>Select an option</option>
        <option value="Retrieve age from DOB">Retrieve age from DOB</option> 
        <option value="Convert age to DOB">Convert age to DOB</option> 
        <option value="Extract the year from the date">Extract the year from the date</option> 
        <option value="Extract the month from the date">Extract the month from the date</option> 
        <option value="InputDate+0Days">InputDate+0Days</option> 
        <option value="InputDate-1Day">InputDate-1Day</option> 
        <option value="InputDate-365Days">InputDate-365Days</option> 
        <option value="InputDate-1095Days">InputDate-1095Days</option> 
        <option value="InputDate-1825Days">InputDate-1825Days</option> 
        <option value="InputDate+1Day">InputDate+1Day</option> 
        <option value="InputDate+365Days">InputDate+365Days</option> 
        <option value="InputDate+1095Days">InputDate+1095Days</option> 
        <option value="InputDate+1825Days">InputDate+1825Days</option> 
        <option value="CurrentDate+0Days">CurrentDate+0Days</option> 
        <option value="CurrentDate-365Days">CurrentDate-365Days</option> 
        <option value="CurrentDate+1Day">CurrentDate+1Day</option> 
        <option value="CurrentDate+365Days">CurrentDate+365Days</option> 
      </>
    );
  }
  return null;
}

export default ConvertValuesOptions;
