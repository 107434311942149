import React, { useState, useEffect } from 'react'
import '../../../../Styles/Workflow/DynamicAction/GetDataFromDB/GetDataForPopup/DocIndexKey.css'

function DocIndexKey({clickedInputId, onValueChangeForDB, onDataFromComponents, dataFromComponents}) {

    const[getDocIndexKeyFrom, setGetDocIndexKeyFrom] = useState(dataFromComponents?.["doc-index-key"]?.valuesFromComponents?.getDocIndexKeyFrom || '')
    const[manualInput, setManualInput] = useState(dataFromComponents?.["doc-index-key"]?.valuesFromComponents?.manualInput || '')
    const[dynamicInput, setDynamicInput] = useState(dataFromComponents?.["doc-index-key"]?.valuesFromComponents?.dynamicInput || '')
    const[triggerOption, setTriggerOption] = useState(dataFromComponents?.["doc-index-key"]?.valuesFromComponents?.triggerOption || '')
    const[triggerExternalInput, setTriggerExternalInput] = useState(dataFromComponents?.["doc-index-key"]?.valuesFromComponents?.triggerExternalInput || '')

    useEffect(() => {
      if(clickedInputId !== null){
        let finalValue = '';
        if(getDocIndexKeyFrom === 'Manual'){
            finalValue = `${manualInput}`
        } else if(getDocIndexKeyFrom === 'Dynamic'){
            finalValue = `${getDocIndexKeyFrom} : ${dynamicInput}`
        } else if(getDocIndexKeyFrom === 'Trigger'){
            finalValue = `${getDocIndexKeyFrom} : ${triggerOption} : ${triggerExternalInput}`
        }
        onValueChangeForDB(finalValue, clickedInputId)
      }

    }, [clickedInputId, onValueChangeForDB])

    useEffect(() => {
        const generateDataObject = async () => {
            const dataObject ={
                    getDocIndexKeyFrom: getDocIndexKeyFrom,
                    manualInput: manualInput,
                    dynamicInput:dynamicInput,
                    triggerOption: triggerOption,
                    triggerExternalInput: triggerExternalInput
            }
            onDataFromComponents(dataObject); 
        }
    
        generateDataObject(); 
    
    }, [
        getDocIndexKeyFrom, 
        manualInput, 
        dynamicInput,
        triggerOption, 
        triggerExternalInput,
        onDataFromComponents
    ]);
    
    
  return (
    <>
        <div className="row">
        <div className="col-md-12 gdikcls1">
            <label className="col-md-4 gdik-label1" htmlFor="to" >
            Doc index key:
            </label>
            <select
                className="gdik-select1"
                value={getDocIndexKeyFrom}
                onChange={(e) => setGetDocIndexKeyFrom(e.target.value)}
            >
                <option value="" disabled>Select a option</option>
                <option value="Manual">Manual</option>                
                <option value="Dynamic">Dynamic</option>                
                <option value="Trigger">Trigger</option>                
            </select>      
        </div>
        </div>
        {getDocIndexKeyFrom === 'Manual' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdikcls1">
                    <label className="col-md-4 gdik-label1">
                    </label>
                    <input
                        className="gdik-select1"
                        type='text'
                        value={manualInput}
                        onChange={(e) => setManualInput(e.target.value)}
                    />     
                </div>
            </div>
            </div>
        )}
        {getDocIndexKeyFrom === 'Dynamic' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdikcls1">
                    <label className="col-md-4 gdik-label1">
                    </label>
                    <input
                        className="gdik-select1"
                        type='text'
                        value={dynamicInput}
                        onChange={(e) => setDynamicInput(e.target.value)}
                    />     
                </div>
            </div>
            </div>
        )}
        {getDocIndexKeyFrom === 'Trigger' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdikcls1">
                    <label className="col-md-4 gdik-label1">
                    </label>
                    <select
                        className="gdik-select1"
                        value={triggerOption}
                        onChange={(e) => setTriggerOption(e.target.value)}
                    >   
                        <option value="" disabled>Select a option</option>
                        <option value="Internal">Internal</option>                
                        <option value="External">External</option>                
                    </select>
                </div>
            </div>
            {triggerOption === 'External' && (
                <div>
                <div className="row">
                    <div className="col-md-12 gdikcls1">
                        <label className="col-md-4 gdik-label1">
                        </label>
                        <input
                            className="gdik-select1"
                            type='text'
                            value={triggerExternalInput}
                            onChange={(e) => setTriggerExternalInput(e.target.value)}
                        />     
                    </div>
                </div>
                </div>
            )}
            </div>
        )}
    </>
  )

}

export default DocIndexKey