import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import '../../../Styles/Plugins/ApiConnector/AddAnotherCall/AddAnotherCall.css'
import ManuallyEnterAPIResponse from './ManuallyEnterAPIResponse'
import AddAnotherCallMethods from './AddAnotherCallMethodPaths';
import InitializeCall from './InitializeCall';
import DynamicParams from './DynamicParams/DynamicParams';
import trash_black from '../../../../Assets/Images/trash_black.png';
import AddAnotherCallAuthentication from './AddAnotherCallAuthentication';


function AddAnotherCall({
    apiIndex,
    addAnotherCallIndex,
    updateAddAnotherCallApiNames,
    index,
    isAddAnotherCallAccordionOpen,
    toggleAddAnotherCallAccordion,
    onAnotherCallObjectChange,
    addAnotherCallData,
    deleteApiCall
}) {

  // Initialize state variables using addAnotherCallData
  const [addAnotherCallApiName, setAddAnotherCallApiName] = useState(addAnotherCallData?.addAnotherCallApiName || `API Call ${apiIndex}-${addAnotherCallIndex}`);
  const [addAnotherCallResponsesArray, setAddAnotherCallResponsesArray] = useState(addAnotherCallData?.addAnotherCallResponsesArray || {});
  // const [selectedUseAs, setSelectedUseAs] = useState(addAnotherCallResponsesArray?.selectedUseAs || 'Data');
  const [selectedUseAs, setSelectedUseAs] = useState({
    selectedUseAs: addAnotherCallResponsesArray?.selectedUseAs?.selectedUseAs || 'Data',
    dynamic: addAnotherCallResponsesArray?.selectedUseAs?.dynamic || false,
  });
  // const [selectedDataType, setSelectedDataType] = useState(addAnotherCallResponsesArray?.selectedDataType || 'JSON');
  const [selectedDataType, setSelectedDataType] = useState({
    selectedDataType: addAnotherCallResponsesArray?.selectedDataType?.selectedDataType || 'JSON',
    dynamic: addAnotherCallResponsesArray?.selectedDataType?.dynamic || false,
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [showManuallyEnterAPIResponse, setShowManuallyEnterAPIResponse] = useState(false);

  // const [selectedHTTPMethod, setSelectedHTTPMethod] = useState(addAnotherCallResponsesArray?.selectedHTTPMethod || 'GET');
  const [selectedHTTPMethod, setSelectedHTTPMethod] = useState({
    selectedHTTPMethod: addAnotherCallResponsesArray?.selectedHTTPMethod?.selectedHTTPMethod || 'GET',
    dynamic: addAnotherCallResponsesArray?.selectedHTTPMethod?.dynamic || false,
  });
  // const [anotherCallURL, setAnotherCallURL] = useState(addAnotherCallResponsesArray?.anotherCallURL || '');
  const [anotherCallURL, setAnotherCallURL] = useState({
    anotherCallURL:addAnotherCallResponsesArray?.anotherCallURL?.anotherCallURL || '',
    dynamic:addAnotherCallResponsesArray?.anotherCallURL?.dynamic || false
  });
  const [errorsInResponse, setErrorsInResponse] = useState(addAnotherCallResponsesArray?.errorsInResponse || false);
  const [responseHeaders, setResponseHeaders] = useState(addAnotherCallResponsesArray?.responseHeaders || false);
  const [anotherCallMethodObject, setAnotherCallMethodObject] = useState(addAnotherCallResponsesArray?.anotherCallMethodObject || null);
  const [showInitializeCall, setShowInitializeCall] = useState(false);
  const [apiResponseInializeCall, setApiResponseInializeCall] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [anotherCallAuthetication, setAnotherCallAuthetication] = useState(addAnotherCallResponsesArray?.anotherCallAuthetication || null);

  // console.log("addAnotherCallResponsesArray", addAnotherCallResponsesArray);
  const uniqueIdentifier = `${apiIndex}-${addAnotherCallIndex}`;

  const [dynamicParams,setDynamicParams] = useState([]);

  useEffect(() => {
    setDynamicParams(parseParameters());
  }, [anotherCallURL]);


  // console.log("addAnotherCallData", addAnotherCallData);

  const updateAddAnotherCallApiName = (newValue) => {
    const updatedValue = newValue.trim() !== '' ? newValue : addAnotherCallApiName;
    setAddAnotherCallApiName(updatedValue);
  
    // Set the entered or default value in the parent state (ApiConnector)
    updateAddAnotherCallApiNames(apiIndex, addAnotherCallIndex, updatedValue);
  };

  useEffect(() => {
    // This runs when the component mounts or updates
    const updatedValue = addAnotherCallApiName; // Default value
    updateAddAnotherCallApiNames(apiIndex, addAnotherCallIndex, updatedValue);

  }, [addAnotherCallApiName, apiIndex, addAnotherCallIndex]); 

    
  const handleErrorsInResponse = () => {
    setErrorsInResponse(!errorsInResponse);
  };

  const handleResponseHeaders = () => {
    setResponseHeaders(!responseHeaders);
  };

  const initializeCall = () => {
    setButtonDisabled(true);
  };

  const openManuallyEnterAPIResponse = (index) => {
    setShowManuallyEnterAPIResponse(true);
  };

  const closeManuallyEnterAPIResponse = (index) => {
    setShowManuallyEnterAPIResponse(false);
  };

  
  // Define a mapping between HTTP methods and corresponding components
  const methodComponentMapping = {
    GET: AddAnotherCallMethods.AddAnotherCallGET,
    POST: AddAnotherCallMethods.AddAnotherCallPOST,
    PUT: AddAnotherCallMethods.AddAnotherCallPOST,
    PATCH: AddAnotherCallMethods.AddAnotherCallPOST,
    DELETE: AddAnotherCallMethods.AddAnotherCallPOST,
  };

  // Get the selected component based on the selected HTTP method
  const SelectedComponent = methodComponentMapping[selectedHTTPMethod.selectedHTTPMethod];
 

  const parseParameters = () => {
    const paramRegex = /\[(.*?)\]/g;
    const params = anotherCallURL.anotherCallURL.match(paramRegex);

    if (!params) return [];

    return params.map((param, index) => ({
      key: param.replace(/\[|\]/g, ''),
      value: '',
      private: true,
      allowblank: false,
      index,
    }));
  };



  const updateDynamicParameters = (paramIndex, key, value) => {
    const updatedParams = [...dynamicParams];
    updatedParams[paramIndex] = { ...updatedParams[paramIndex], [key]: value };
    setDynamicParams(updatedParams);
  };

  const removeDynamicParameter = (paramIndex) => {
    const updatedParams = dynamicParams.filter(param => param.index !== paramIndex);
    setDynamicParams(updatedParams);
  };

  const handleAnotherCallMethodObjectChange = (dataObject) => {
    console.log('AnotherCall Method Object:', dataObject);
    // Set the dataObject in the state
    setAnotherCallMethodObject(dataObject);
  };

  const handleAnotherCallAuthentication = (dataObject) => {
    console.log('AnotherCall Authentication:', dataObject);
    // Set the dataObject in the state
    setAnotherCallAuthetication(dataObject);
  };

  const handleCheckboxChange = () => {
    setSelectedUseAs((prevSelectedUseAs) => ({
      ...prevSelectedUseAs,
      dynamic: !prevSelectedUseAs.dynamic,
    }));
  };

  const handleDataTypeCheckboxChange = () => {
    setSelectedDataType((prevSelectedDataType) => ({
      ...prevSelectedDataType,
      dynamic: !prevSelectedDataType.dynamic,
    }));
  }; 

  const handleHTTPMethodCheckboxChange = () => {
    setSelectedHTTPMethod((prevSelectedHTTPMethod) => ({
      ...prevSelectedHTTPMethod,
      dynamic: !prevSelectedHTTPMethod.dynamic,
    }));
  };   

  const handleCallURLCheckboxChange = () => {
    setAnotherCallURL((prevAnotherCallURL) => ({
      ...prevAnotherCallURL,
      dynamic: !prevAnotherCallURL.dynamic
    }))
  }

  useEffect(() => {
    const generateDataObject = () => {
      if (isAddAnotherCallAccordionOpen) {
        const anotherCallDataObject = {
          addAnotherCallIndex: addAnotherCallIndex,
          apiIndex: apiIndex,
          addAnotherCallApiName: addAnotherCallApiName,
          // selectedUseAs,
          selectedUseAs: {
            selectedUseAs: selectedUseAs.selectedUseAs,
            dynamic: selectedUseAs.dynamic,
          },
          // selectedDataType,
          // selectedHTTPMethod,
          selectedDataType: {
            selectedDataType: selectedDataType.selectedDataType,
            dynamic: selectedDataType.dynamic,
          },
          selectedHTTPMethod: {
            selectedHTTPMethod: selectedHTTPMethod.selectedHTTPMethod,
            dynamic: selectedHTTPMethod.dynamic,
          },
          // anotherCallURL,
          anotherCallURL: {
            anotherCallURL: anotherCallURL.anotherCallURL,
            dynamic: anotherCallURL.dynamic,
          },
          errorsInResponse,
          responseHeaders,
          dynamicParams,
          anotherCallMethodObject,
          anotherCallAuthetication
        };
  
        onAnotherCallObjectChange(anotherCallDataObject);
      }
    };
  
    generateDataObject();
  
    return () => {
      // Cleanup code if needed
    };
  }, [
    addAnotherCallIndex,
    apiIndex,
    selectedUseAs,
    selectedDataType,
    selectedHTTPMethod,
    anotherCallURL,
    errorsInResponse,
    responseHeaders,
    anotherCallMethodObject,
    addAnotherCallApiName,
    dynamicParams,
    onAnotherCallObjectChange,
    isAddAnotherCallAccordionOpen,
    anotherCallAuthetication
  ]);  


  const handleInitializeCall = async () => {
    console.log("Testing........");
    try {
      // Create a copy of the original URL
      let dynamicURL = anotherCallURL.anotherCallURL;
  
      // Iterate over dynamicParams and replace placeholders with values
      dynamicParams.forEach(param => {
        console.log(`Before Replacement - Key: ${param.key}, Value: ${param.value}`);
        const placeholder = `[${param.key}]`; // Change from param.value to param.key
        dynamicURL = dynamicURL.replace(placeholder, param.value);
        console.log(`After Replacement - Key: ${param.key}, Value: ${param.value}, Updated URL: ${dynamicURL}`);
      });
  
      // Remove any remaining square brackets from the URL
      dynamicURL = dynamicURL.replace(/\[|\]/g, '');
  
      console.log("dynamicURL", dynamicURL);

      // if (selectedDataType.selectedDataType === 'JSON') {
      // // Extract values from dataObject
      //  const { selectedBodyType, addAnotherCallPOSTHeaders, addAnotherCallPOSTParameters, jsonBody} = anotherCallMethodObject;
      // } else {
      //   const { selectedBodyType, addAnotherCallPOSTHeaders, addAnotherCallPOSTParameters, xmlBody } = anotherCallMethodObject;

      // }

      const { selectedBodyType, addAnotherCallPOSTHeaders, addAnotherCallPOSTParameters, jsonBody, xmlBody} = anotherCallMethodObject;

       console.log("Type of jsonBody:", typeof jsonBody);

       let headers; // Define headers variable outside the if block

        if (addAnotherCallPOSTHeaders) {
            headers = {};
            addAnotherCallPOSTHeaders.forEach(header => {
                headers[header.key] = header.value;
            });
        } else {
            console.error("addAnotherCallPOSTHeaders does not exist");
        }

  
      // Make the API call using the updated URL and selected details
      // const response = await fetch(dynamicURL, { method: selectedHTTPMethod})
      let requestBody;
      console.log("selectedBodyType",selectedBodyType.selectedBodyType);
      console.log("jsonBody",jsonBody);
        
      // if (selectedHTTPMethod.selectedHTTPMethod === 'POST') {
      //     requestBody = selectedBodyType.selectedBodyType === 'JSON' ? JSON.stringify(jsonBody) : undefined;
      // }

      if (selectedHTTPMethod.selectedHTTPMethod === 'POST') {
        if (selectedBodyType.selectedBodyType === 'JSON') {
            requestBody = JSON.stringify(jsonBody);
        } else if (selectedBodyType.selectedBodyType === 'Raw') {
            requestBody = xmlBody;
        }
      }

      console.log("headers", headers);
      console.log("requestBody", requestBody);

      console.log("selectedHTTPMethod",selectedHTTPMethod.selectedHTTPMethod);

      // const response = await fetch(dynamicURL, {
      //     method: selectedHTTPMethod.selectedHTTPMethod,
      //     headers: headers,
      //     body: requestBody,
      // });


      
      const response = await fetch('https://aim-core.insurancepolicy4us.com/executeAPICall', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dynamicURL,
          selectedHTTPMethod: selectedHTTPMethod.selectedHTTPMethod,
          headers,
          requestBody,
        }),
      });
  
      // Handle the response from the backend
      const responseData = await response.json();
      console.log("responseData", responseData);
  
      // Update the state with the API response data
      setApiResponseInializeCall(responseData);
  
      if (responseData !== undefined && responseData !== null && Object.keys(responseData).length !== 0) {
        setShowInitializeCall(true);
      }

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (error) {
      console.log("errorr", error);
      setApiResponseInializeCall({ error });
      // setShowInitializeCall(true);
      console.error('Error during API call:', error);

      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        console.error('Network error or CORS issue. Check network connectivity and CORS settings.');
      }
    }
  };

  

  
  const handleApiCallDeleteClick = () => {
    // Call the delete function passed from the parent component
    deleteApiCall(apiIndex, addAnotherCallIndex);
  };
  
    return (
    <div>
      <Card
        key={index}
        className={`addanothercall-accordion-card ${isAddAnotherCallAccordionOpen ? 'addanothercall-accordion-open' : ''}`}
      >
        <Card.Body className="addanothercall-collapse-btn">
          <span style={{ display: isAddAnotherCallAccordionOpen ? 'block' : 'none' }} className='addanothercall-accordian-span'>
          <div
            className="addanothercall-acc-card-input1-container"
          >
            <label htmlFor={`addAnotherCallApiNames-${index}`}>Name</label>
            <input 
              id={`addAnotherCallApiNames-${index}`} 
              value={addAnotherCallApiName}
              type="text"
              placeholder='API Call'
              onChange={(e) => updateAddAnotherCallApiName(e.target.value)}
            />
            <input
              id={`addAnotherCallApiNames-${index}`}
              type="checkbox"
              className="small-checkbox"
            /> 
            <div className="addanothercall-acc-card-input-container-img" onClick={handleApiCallDeleteClick}>
              <img src={trash_black} alt="trash_black" className="addanothercall-acc-card-input-container-img-hover" />
            </div>
          </div>
          <div
            className="addanothercall-acc-card-input2-container"
          >
            <label htmlFor={''}>Use as</label>
            {/* <select className="addanothercall-select" onChange={(e) => setSelectedUseAs(e.target.value)} value={selectedUseAs}> */}
            <select 
              className="addanothercall-select" 
              onChange={(e) =>
                setSelectedUseAs({
                  selectedUseAs: e.target.value,
                  dynamic: selectedUseAs.dynamic,
                })
              }
              value={selectedUseAs.selectedUseAs}
            >
              <option value="Data">Data</option>
              <option value="Action">Action</option>
            </select>
            <input
              // id={''}
              type="checkbox"
              className="small-checkbox"
              onChange={handleCheckboxChange}
              checked={selectedUseAs.dynamic}
            /> 
          </div>
          <div
            className="addanothercall-acc-card-input3-container"
          >
            <label htmlFor={''}>Data type</label>
            <select 
              className="addanothercall-select" 
              onChange={(e) =>
                setSelectedDataType({
                  selectedDataType: e.target.value,
                  dynamic: selectedDataType.dynamic,
                })
              }
              value={selectedDataType.selectedDataType}
            >
              <option value="JSON">JSON</option>
              <option value="XML">XML</option>
              <option value="Image">Image</option>
              <option value="Number">Number</option>
              <option value="Text">Text</option>
              <option value="File">File</option>
              <option value="Empty">Empty</option>
            </select>
            <input
              id={''}
              type="checkbox"
              className="small-checkbox"
              onChange={handleDataTypeCheckboxChange}
              checked={selectedDataType.dynamic}
            /> 
          </div>
          <AddAnotherCallAuthentication               
            onAnotherCallAuthentication={handleAnotherCallAuthentication} 
            anotherCallAuthetication={anotherCallAuthetication}
          />
          <div
            className="addanothercall-acc-card-input-container1"
          >
            <label htmlFor={''}>HTTP method</label>
            <select 
              className="addanothercall-select1" 
              onChange={(e) =>
                setSelectedHTTPMethod({
                  selectedHTTPMethod: e.target.value,
                  dynamic: selectedHTTPMethod.dynamic,
                })
              }
              value={selectedHTTPMethod.selectedHTTPMethod}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </select>
            <input
              id={''}
              type="checkbox"
              className="small-checkbox"
              onChange={handleHTTPMethodCheckboxChange}
              checked={selectedHTTPMethod.dynamic}
            /> 
          </div>
          <div
            className="addanothercall-acc-card-input1-container1"
          >
            <label htmlFor={''}>URL (use [] for params)</label>
            <input 
              id={'anotherCallURL'} 
              value={anotherCallURL.anotherCallURL}
              type="text"
              onChange={(e) => setAnotherCallURL({
                anotherCallURL: e.target.value,
                dynamic: anotherCallURL.dynamic
              })}
            />
            {/* <span>(use [] for params)</span> */}
            <input
              type="checkbox"
              className="small-checkbox"
              onChange={handleCallURLCheckboxChange}
              checked={anotherCallURL.dynamic}
            /> 
          </div>
          {dynamicParams.map((param) => (
            <DynamicParams
              key={param.index}
              parameter={param}
              updateDynamicParameter={(key, value) => updateDynamicParameters(param.index, key, value)}
            />
          ))}
          <>
          {SelectedComponent && 
            <SelectedComponent 
              onAnotherCallMethodObjectChange={handleAnotherCallMethodObjectChange} 
              anotherCallMethodObject={anotherCallMethodObject}
            />
          }
          </>
          <div
            className="addanothercall-acc-card-input-container5"
          >
            <label htmlFor={''}>Include errors in response and allow workflow actions to continue</label>
            <input
                id={'errorsInResponse'}
                type="checkbox"
                checked={errorsInResponse}
                onChange={handleErrorsInResponse}
              />
          </div>
          <div
            className="addanothercall-acc-card-input-container6"
          >
            <label htmlFor={''}>Capture response headers</label>
            <input
                id={'responseHeaders'}
                type="checkbox"
                checked={responseHeaders}
                onChange={handleResponseHeaders}
              />
          </div>
          <div
            className="addanothercall-acc-card-input-container7"
          >
            <label htmlFor={''}>You need to initialize this call before it will work.</label>
          </div>
          <div
            className="addanothercall-acc-card-input-container8"
          >
            <button 
              id="initializeButton"
              className='initializecallbtn'
              onClick={handleInitializeCall}
            >
              Initialize call
            </button>
            {showInitializeCall && (
              <div className="initialize-call-popup">
                <InitializeCall 
                  apiResponse={apiResponseInializeCall} 
                  onClose={() => setShowInitializeCall(false)}
                  addAnotherCallApiName={addAnotherCallApiName}
                />
              </div>
            )}

            {((selectedDataType === 'JSON' || selectedDataType === 'XML') && (
              <a href='#' onClick={() => openManuallyEnterAPIResponse(index)}>
                Manually enter API response
              </a>
            ))}  
            {showManuallyEnterAPIResponse && (
              <div className="manually-enter-apiresponse-popup">
                <ManuallyEnterAPIResponse onClose={() => closeManuallyEnterAPIResponse(index)} />
              </div>
            )}
          </div>
          </span>
          <div
            className="addanothercall-acc-card-input-container"
            style={{ display: isAddAnotherCallAccordionOpen ? 'none' : 'flex' }}
          >
            <label htmlFor={`addAnotherCallApiNames-${index}`}>Name</label>
            <input id={`addAnotherCallApiNames-${index}`} value={addAnotherCallApiName} placeholder='API Call' type="text" readOnly/>
          </div>
          <button
            className="addanothercall-collapse-button"
            onClick={() => toggleAddAnotherCallAccordion(addAnotherCallIndex)}
          >
            {isAddAnotherCallAccordionOpen ? 'Collapse' : 'Expand'}
          </button>
        </Card.Body>
      </Card>
    </div>
  )
}


export default AddAnotherCall