import React, { useState, useEffect } from 'react';
import '../Styles/Workflow/CustomActionPopup.css';
import DynamicActionConnectors from './DynamicAction/Connectors/DynamicActionConnectorsPath'
import trash_black from '../../Assets/Images/trash_black.png';
import ConnectorComponent from './DynamicAction/ConnectorComponent';

const CustomActionPopup = ({ selectedEventIndex, onClose, onCloseEvent, showWorkflowPopup, onActionObjectChange, actionData, selectedIndex, onDeleteAction }) => {

  const [showActionPopover, setShowActionPopover] = useState(false);
  const [selectedConnectorKey, setSelectedConnectorKey] = useState(null);
  const [customActionSteps, setCustomActionSteps] = useState([]);
  const [selectedActionIndex, setSelectedActionIndex] = useState(null);
  const [dataObjectFromConnector, setDataObjectFromConnector] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);
  const [showConnectorComponent, setShowConnectorComponent] = useState(false);
  const [connectorData, setConnectorData] = useState({})

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const options = [
    // { label: 'Account', component: null },
    { label: 'Email', component: 'SendEmail' },
    { label: 'API', component: 'APIConnector' },
    // Add more options as needed
  ];

  const startingIndex = actionData
  ? Math.max(...Object.keys(actionData).map((key) => parseInt(key)), -1)
  : -1;

  const handleConnectorComponentClick = (selectedOption) => {

    const newCustomActionSteps = {
      id: startingIndex+customActionSteps.length + 1,
      text: 'Step ' + (startingIndex+customActionSteps.length + 2),
      connectorKey: selectedOption,
    };
    setCustomActionSteps([...customActionSteps, newCustomActionSteps]);
    const newIndex = customActionSteps.length + startingIndex + 1;
    setSelectedActionIndex(newIndex);

    setShowConnectorComponent(true);
    setShowActionPopover(false)
  };

  const handleConnectorComponentClose = () => {
    setShowConnectorComponent(false);
  };

  const onConnectorData = (dataObject) => {
    // console.log("onConnectorData:", dataObject);

    setConnectorData((prevDataObject) => {
      const mergedDataObject = { ...prevDataObject, ...dataObject };
      return mergedDataObject;
    });

  }

  useEffect(() => {
    const generateDataObject = async () => {
      const dataObject = {};

      for (let index = 0; index < customActionSteps.length; index++) {
        // const stepText = `Step ${index + 1}`;
        const stepText = customActionSteps[index].text;
        const connectorKey = customActionSteps[index].connectorKey;
  
        const stepsArray = [{
          action: connectorKey,
          step: stepText,
        }];
        
        if (connectorData && connectorData[index]) {
          stepsArray[0] = { ...stepsArray[0], connectorDetails: { ...connectorData[index] } };
        }
  
        const existingData = dataObject[selectedEventIndex] || [];
        dataObject[selectedEventIndex] = existingData.concat(stepsArray);
      }

      if (actionData && selectedActionIndex !== null && selectedActionIndex !== undefined) {
        const selectedEvent = customActionSteps.find((step) => step.id === selectedActionIndex);
        if (selectedEvent) {
          const stepText = selectedEvent.text;
          const connectorKey = selectedEvent.connectorKey;
  
          const stepsArray = [{
            action: connectorKey,
            step: stepText,
          }];
  
          if (connectorData && connectorData[selectedActionIndex]) {
            stepsArray[0] = { ...stepsArray[0], connectorDetails: { ...connectorData[selectedActionIndex] } };
          }
  
          // Combine the stepsArray with the existing dataObject for the selected index
          const existingData = dataObject[selectedEventIndex] || [];
          dataObject[selectedEventIndex] = existingData.concat(stepsArray);
        }
      }
      // console.log("onActionObjectChange:",dataObject);
      onActionObjectChange(dataObject);
    };
  
    generateDataObject();
  }, [customActionSteps, connectorData, onActionObjectChange, selectedEventIndex, selectedActionIndex, actionData]);
  

  useEffect(() => {
    // Reset states when showWorkflowPopup is true
    if (showWorkflowPopup) {
      setCustomActionSteps([]);
      setSelectedConnectorKey(null);
      // setDataObjectFromConnector({}); // Commented out to preserve data for each index
    }
  }, [showWorkflowPopup]);

  const handleShowActionPopup = () => {
    setShowActionPopover(!showActionPopover);
    onCloseEvent()
  };
  
  const handleButtonClick = (index) => {

    let selectedEvent;
    let connectorKey;
  
    if (actionData && index >= 0 && index < actionData.length) {
      console.log("From actionDataa...");
      selectedEvent = actionData[index];
      connectorKey = selectedEvent.action; // Use the appropriate property for connectorKey
    }else if (customActionSteps && customActionSteps.length > 0 && index >= 0 && index < customActionSteps.length) {
      selectedEvent = customActionSteps[index];
      connectorKey = selectedEvent.connectorKey;
    } else {
      const foundEvent = customActionSteps.find((step) => step.id === index);
      
      if (foundEvent) {
        selectedEvent = foundEvent;
        connectorKey = selectedEvent.connectorKey;
      } else {
        console.error("Invalid index for customActionSteps:", index);
        return;
      }
    }
    
    setSelectedOption(connectorKey)
    setShowConnectorComponent(true);
  
    if (selectedEvent) {
      setSelectedActionIndex(index);
      onCloseEvent();
    } else {
      setSelectedOption(null);
      setSelectedActionIndex(null);
    }
  };  

  const getConnectorDetailsForIndex = (index) => {
    if (actionData && selectedIndex !== null && selectedIndex !== undefined && actionData[index] && selectedIndex === selectedEventIndex) {
      // For actionData, return connectorDetails directly
      return actionData[index]?.connectorDetails;
    } 
    // If no corresponding connectorDetails found, return null or handle accordingly
    return null;
  };
  
  const connectorDetails = getConnectorDetailsForIndex(selectedActionIndex);
  // console.log("connectorDetails:", connectorDetails);

  const confirmDelete = (actionStep) => {
    if (window.confirm("Are you sure you want to delete?")) {
      handleDeleteActionClick(actionStep);
    }
  };

  const handleDeleteActionClick = (actionStep) => {

    fetch(`https://aim-core.insurancepolicy4us.com/deleteAction/${selectedIndex}/${actionStep}`, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        onDeleteAction(actionStep);
        setSelectedOption(null);
        setSelectedActionIndex(null);
      })
      .catch(error => {
        // Handle error or show a notification
        console.error('Error deleting', error);
      });
  };
  
  return (
    <div className="popover-container">
      <div className="card">
        <div className="row">
        <div className="col-md-12 actionbtn">
        <div className="col-md-11">
        {actionData && actionData.length > 0 && (
          // Render buttons based on actionData
            Object.keys(actionData).map((key) => (
            <button
              key={actionData[key]._id}
              className={`btn btn-light border action-button1 action-button1-${key}`}
              onClick={() => handleButtonClick(key)}
            >
              <h6>{actionData[key].step}</h6>
              <p>{actionData[key].action}</p>
              <div  
                className="action-popup-input-container-img" 
                // onClick={() => handleDeleteActionClick(actionData[key].step)}
                onClick={() => confirmDelete(actionData[key].step)}
              >
                <img src={trash_black} alt="trash_black" className="action-popup-input-container-img-hover" />
              </div>
            </button>
          ))
        )} 
         {/* {[...customActionSteps].reverse().map((event, index) => { */}
         {[...customActionSteps].map((event, index) => {
          return (
            <button
              key={event.id}
              className={`btn btn-light border action-button1 action-button1-${startingIndex + index}`}
              onClick={() => handleButtonClick(startingIndex + index + 1)}
            >
              <h6>{event.text}</h6>
              <p>{event.connectorKey}</p>
            </button>
          )
          })}
          
            <button 
              className="btn btn-light border-dotted action-button"
              onClick={handleShowActionPopup}
            >
                Click here to add an action
            </button>
            {showActionPopover && (
                <div className="popover-content"> 

                      <div className="left-content">
                        {options.map((option) => (
                          <p
                            key={option.label}
                            className={`${option.component ? 'custom-text' : 'general-text'}`}
                            onMouseEnter={() => handleOptionSelect(option.component)}
                          >
                            {option.label}
                          </p>
                        ))}
                      </div>
                      <div className="separator"></div>
                      {selectedOption && (
                        <div className="right-content">
                          {/* <p onClick={handleConnectorComponentClick}>{selectedOption}</p> */}
                          <p onClick={() => handleConnectorComponentClick(selectedOption)}>{selectedOption}</p>
                        </div>
                      )}
                </div>
              )}
              {showConnectorComponent && selectedOption && (
                <ConnectorComponent 
                  option={selectedOption} 
                  onClose={handleConnectorComponentClose}
                  selectedActionIndex = {selectedActionIndex}
                  selectedEventIndex = {selectedEventIndex}
                  onConnectorData = {onConnectorData}
                  connectorDetails = {connectorDetails}
                  actionStep={actionData && actionData[selectedActionIndex] && actionData[selectedActionIndex].step} 
                  customEventText={customActionSteps && customActionSteps[selectedActionIndex] && customActionSteps[selectedActionIndex].text} 
                />
              )}
        </div>
        <div className="col-md-1">
            <button className="close-btn" onClick={onClose}>
                X
            </button>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CustomActionPopup;
