import React, { createContext, useState, useEffect } from 'react';

export const ResponseToClientContext = createContext();

export const ResponseToContextProvider = ({ children }) => {
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://aim-core.insurancepolicy4us.com/send-apiresponse-to-client');
        const data = await response.json();
        console.log("send-apiresponse-to-client", data);
        setApiResponse(data);
      } catch (error) {
        console.error('Error fetching API response:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ResponseToClientContext.Provider value={{ apiResponse }}>
      {children}
    </ResponseToClientContext.Provider>
  );
};
