import React, { useState, useEffect } from 'react';
import '../../../Styles/Workflow/DynamicAction/SendEmail.css';

const SendEmail = (props) => {

  const [to, setTo] = useState(props.Input?.inputConnectorData?.to || '');
  const [isDifferentReplyTo, setIsDifferentReplyTo] = useState(props.Input?.inputConnectorData?.isDifferentReplyTo || false);
  const [replyTo, setReplyTo] = useState(props.Input?.inputConnectorData?.replyTo || '');
  const [senderName, setSenderName] = useState(props.Input?.inputConnectorData?.senderName || '');
  const [cc, setCc] = useState(props.Input?.inputConnectorData?.cc || '');
  const [bcc, setBcc] = useState(props.Input?.inputConnectorData?.bcc || '');
  const [subject, setSubject] = useState(props.Input?.inputConnectorData?.subject || '');
  const [body, setBody] = useState(props.Input?.inputConnectorData?.body || '');
  const [fileInputs, setFileInputs] = useState(props.Input?.inputConnectorData?.fileInputs || []);

  const addFileInput = () => {
    setFileInputs((prevFileInputs) => [...prevFileInputs, { file: null }]);
  };

  const removeFileInput = (index) => {
    setFileInputs((prevFileInputs) => {
      const updatedFileInputs = [...prevFileInputs];
      updatedFileInputs.splice(index, 1);
      return updatedFileInputs;
    });
  };

  const handleFileChange = (index, event) => {
    setFileInputs((prevFileInputs) => {
      const updatedFileInputs = [...prevFileInputs];
      updatedFileInputs[index].file = event.target.files[0];
      return updatedFileInputs;
    });
  };  
  
  // useEffect(() => {
  //     const generateDataObject = async () => {
  //       const dataObject = {
  //           to,
  //           isDifferentReplyTo,
  //           replyTo,
  //           senderName,
  //           cc,
  //           bcc,
  //           subject,
  //           body,
  //           fileInputs: [],
  //       };
    
  //       for (const fileInput of fileInputs) {
  //         // Ensure there is a valid File object
  //         if (fileInput.file instanceof File) {
  //           const fileBase64 = await fileToBase64(fileInput.file);
  //           dataObject.fileInputs.push({ file: fileBase64 });
  //         }
  //       }
    
  //       props.onInputConnectorData(dataObject);
  //   };
  //     generateDataObject();
  // }, [to, isDifferentReplyTo, replyTo, senderName, cc, bcc, subject, body, fileInputs, props.onInputConnectorData]);
    

  useEffect(() => {
    const generateDataObject = async () => {
        // Check if at least one of the specified state variables is a non-empty string
        const hasValidData = [to, replyTo, senderName, cc, bcc, subject, body].some(value => typeof value === 'string' && value.trim() !== '');

        if (hasValidData) {
            const dataObject = {
                to,
                isDifferentReplyTo,
                replyTo,
                senderName,
                cc,
                bcc,
                subject,
                body,
                fileInputs: [],
            };

            for (const fileInput of fileInputs) {
                // Ensure there is a valid File object
                if (fileInput.file instanceof File) {
                    const fileBase64 = await fileToBase64(fileInput.file);
                    dataObject.fileInputs.push({ file: fileBase64 });
                }
            }

            props.onInputConnectorData(dataObject);
        }
    };

    generateDataObject();
}, [to, isDifferentReplyTo, replyTo, senderName, cc, bcc, subject, body, fileInputs, props.onInputConnectorData]);

    const fileToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    };

    const handleContainerClick = (e) => {
      // Get the currently focused element
      const focusedElement = document.activeElement;
    
      // Check if the focused element is an input field and not a checkbox
      if (
        focusedElement.tagName.toLowerCase() === 'input' &&
        focusedElement.type.toLowerCase() !== 'checkbox'
      ) {
        // Get the input field value
        const inputFieldValue = focusedElement.id;
        // console.log("inputFieldValue",inputFieldValue);
    
        // Call the common function to handle input click and pass the input field value
        props.openGetDataPopup(inputFieldValue);
        props.setManualInputValue('');
      }
    };

    const inputFieldStateMap = {
      to: setTo,
      sendername: setSenderName,
      replyTo: setReplyTo,
      cc: setCc,
      bcc: setBcc,
      subject: setSubject,
      body: setBody,
    };

    let updateStateFunction
    useEffect(() => {
      // Update the value of the input field when clickedInputField or manualInputValue changes
      const inputFieldId = props.clickedInputField;
       updateStateFunction = inputFieldStateMap[inputFieldId];
  
      if (updateStateFunction) {
        updateStateFunction(props.manualInputValue);
      }
    }, [props.clickedInputField, props.manualInputValue]);
  

  return (
    
      <div onClick={handleContainerClick}>
        <div className="row">
          <div className="col-md-12 secls1">
            <label className="col-md-4" htmlFor="to">
              To:
            </label>
            <input type="text" id="to" className="form-control" value={to} onChange={(e) => setTo(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 secls2">
            <label className="col-md-11" htmlFor="differentReplyTo">
              Specify a different reply-to address:
            </label>
            <input
              type="checkbox"
              id="differentReplyTo"
              checked={isDifferentReplyTo}
              onChange={() => setIsDifferentReplyTo(!isDifferentReplyTo)}
            />
          </div>
        </div>

        {isDifferentReplyTo && (
          <div className="row">
            <div className="col-md-12 secls3">
              <label className="col-md-4" htmlFor="replyTo">
                Reply to:
              </label>
              <input type="text" id="replyTo" className="form-control" value={replyTo} onChange={(e) => setReplyTo(e.target.value)}/>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12 secls4">
            <label className="col-md-4" htmlFor="to">
              Sender name:
            </label>
            <input type="text" id="sendername" className="form-control" value={senderName} onChange={(e) => setSenderName(e.target.value)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 secls5">
            <label className="col-md-4" htmlFor="to">
              Cc:
            </label>
            <input type="text" id="cc" className="form-control" value={cc} onChange={(e) => setCc(e.target.value)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 secls6">
            <label className="col-md-4" htmlFor="to">
              Bcc:
            </label>
            <input type="text" id="bcc" className="form-control" value={bcc} onChange={(e) => setBcc(e.target.value)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 secls7">
            <label className="col-md-4" htmlFor="to">
              Subject:
            </label>
            <input type="text" id="subject" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 secls8">
            <label className="col-md-4" htmlFor="to">
              Body:
            </label>
            <input type="textarea" id="body" className="form-control" value={body} onChange={(e) => setBody(e.target.value)}/>
          </div>
        </div>
        <div className="row">
        {fileInputs.map((fileInput, index) => (
          <div className="col-md-12 secls8 file-input-container" key={index}>
            <label className="col-md-4" htmlFor={`file-${index}`}>
              File:
            </label>
            <div className="file-input-wrapper">
              <input
                type="file"
                id={`file-${index}`}
                className="form-control"
                onChange={(e) => handleFileChange(index, e)}
              />
              <span className="delete-icon" onClick={() => removeFileInput(index)}>
                X
              </span>
            </div>
          </div>
        ))}
        </div>
        <div className="secustom-btn" onClick={addFileInput}>
          <button> Attach File</button>
        </div>
      </div>
  );
};

export default SendEmail;
