// InstallPlugins.js
import React, { useState, useCallback } from 'react';
import '../Styles/Plugins/InstallPlugins.css';

// function InstallPlugins({onDone, onSelectCard}) {
  function InstallPlugins({ onDone, onSelectCard, installedPluginsData }) {
  // const [installedPlugins, setInstalledPlugins] = useState(Array(10).fill(false));
  const [installedPlugins, setInstalledPlugins] = useState(installedPluginsData || []);

  console.log("installedPluginsData:", installedPluginsData);

  const [arrowsState, setArrowsState] = useState({
    types: false,
    categories: false,
    pricing: false,
    builtby: false,
  });

  // const handleInstallToggle = (index) => {
  //   const selectedHeading = index === 0 ? 'API Connector' : index === 1 ? 'MongoDB Connector' : `Card ${index + 1}`;
  //   onSelectCard(selectedHeading);
  //   const updatedPlugins = [...installedPlugins];
  //   updatedPlugins[index] = !updatedPlugins[index];
  //   setInstalledPlugins(updatedPlugins);
  // };

  // const cards = Array.from({ length: 10 }, (_, index) => (
  //   <div key={index} className="plugin-card">
  //     <h6>{index === 0 ? 'API Connector' : index === 1 ? 'MongoDB Connector' : `Card ${index + 1}`}</h6>
  //     <p>
  //       {index === 0 ? "The API lets you define your own API calls directly in the Editor and use them in your app." :
  //       index === 1 ? "MongoDB Connector is used for connecting with MongoDB Atlas." :
  //       ''}
  //     </p>
  //     <div className="dotted-line" />
  //     <div className='plugin-card-btn'>
  //       <button className="install-button" onClick={() => handleInstallToggle(index)}>
  //         {installedPlugins[index] ? 'Uninstall' : 'Install'}
  //       </button>
  //     </div>
  //   </div>
  // ));

  const handleInstallToggle = (index) => {
    const selectedHeading = index === 0 ? 'API Connector' : index === 1 ? 'MongoDB Connector' : `Card ${index + 1}`;
    onSelectCard(selectedHeading);
    const pluginIndex = installedPlugins.indexOf(selectedHeading);
    if (pluginIndex === -1) {
      setInstalledPlugins([...installedPlugins, selectedHeading]);
    } else {
      const updatedPlugins = [...installedPlugins];
      updatedPlugins.splice(pluginIndex, 1);
      setInstalledPlugins(updatedPlugins);
    }
  };

  const cards = Array.from({ length: 10 }, (_, index) => (
    <div key={index} className="plugin-card">
      <h6>{index === 0 ? 'API Connector' : index === 1 ? 'MongoDB Connector' : `Card ${index + 1}`}</h6>
      <p>
        {index === 0
          ? "The API lets you define your own API calls directly in the Editor and use them in your app."
          : index === 1
          ? "MongoDB Connector is used for connecting with MongoDB Atlas."
          : ''}
      </p>
      <div className="dotted-line" />
      <div className="plugin-card-btn">
        <button className="install-button" onClick={() => handleInstallToggle(index)}>
          {installedPlugins.includes(index === 0 ? 'API Connector' : index === 1 ? 'MongoDB Connector' : `Card ${index + 1}`)
            ? 'Uninstall'
            : 'Install'}
        </button>
      </div>
    </div>
  ));

  const handleDoneClick = () => {
    onDone(installedPlugins);
  };


  const handleArrowClick = useCallback(
    (arrow) => {
      setArrowsState((prevState) => ({
        ...prevState,
        [arrow]: !prevState[arrow],
      }));
    },
    [setArrowsState]
  );

  return (
    <div className="container">
      <div className="row">
        <div className='col-md-12'>
          <div className="install-plugin-container border">
            <h4 className='install-plugin-header'>
              Install New Plugins
            </h4>
            <div className="inner-boxes">
              <div className="install-plugin-container1">
                <h5>Filters</h5>
                <hr/>
                <div className="install-search-container">
                  <input
                    type="text"
                    placeholder="Search for a plugin..."
                    className="install-search-input"
                  />
                </div>
                <div className="install-arrow-container" onClick={() => handleArrowClick('types')}>
                  <div className={`install-arrow ${arrowsState.types ? 'down' : ''}`}></div>
                  <span className='install-arrow-span'>Types</span>
                </div>
                <div className="install-arrow-container" onClick={() => handleArrowClick('categories')}>
                  <div className={`install-arrow ${arrowsState.categories ? 'down' : ''}`}></div>
                  <span className='install-arrow-span'>Categories</span>
                </div>
                <div className="install-arrow-container" onClick={() => handleArrowClick('pricing')}>
                  <div className={`install-arrow ${arrowsState.pricing ? 'down' : ''}`}></div>
                  <span className='install-arrow-span'>Pricing</span>
                </div>
                <div className="install-arrow-container" onClick={() => handleArrowClick('builtby')}>
                  <div className={`install-arrow ${arrowsState.builtby ? 'down' : ''}`}></div>
                  <span className='install-arrow-span'>Built by</span>
                </div>
              </div>
              <div className="install-plugin-container2">
                <div className="plugin-card-container">
                  {cards}
                </div>
              </div>
            </div>
            {/* <button className='install-plugin-btn' onClick={onDone}>Done</button> */}
            <button className='install-plugin-btn' onClick={handleDoneClick}>Done</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstallPlugins;
