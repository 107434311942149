import React, { useState } from "react";
// import "../../style/DataTypePopup.css";
import "../Styles/CreateProject/DataTypePopup.css";

const ProjectPopup = ({ onClose, onCreate }) => {
  
  const [projectName, setProjectName] = useState("");

  const handleCreate = (e) => {
    e.preventDefault();
    // Perform any additional validation if needed
    if (projectName.trim() !== "") {
      onCreate(projectName); 
      onClose();
    }
  };

  return (
    <div className="popup">
      <div className="popup-content"style={{width:"550px"}}>
        <h4 style={{marginTop:"10px"}}>Create New Project</h4>
        <form onSubmit={handleCreate}>
          <label style={{marginTop:"10px"}} htmlFor="projectName">Project Name:</label>
          <input
          style={{marginBottom:"20px"}}
            type="text"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            autoFocus
          />
          <div className="popup-buttons" style={{marginTop:"10px"}}>
            <button
              className="create-button"
              type="submit"
              disabled={!projectName.trim()}
            >
              Create
            </button>
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectPopup;
