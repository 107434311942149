import React from 'react';

const ResultOptions = ({ selectedIdentifier, selectedSubCondition, selectedNestedCondition }) => {

  // console.log("selectedIdentifier",selectedIdentifier);
  // console.log("selectedSubCondition",selectedSubCondition);
  // console.log("selectedNestedCondition",selectedNestedCondition);

  const nestedWords = selectedNestedCondition ? selectedNestedCondition.split(' ') : [];

  // Map over the words and format them
  const formattedNestedWords = nestedWords.map((word, index) => {
    if (index === 0) {
      // Capitalize the first word and convert it to camel case
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      // Convert other words to camel case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the formatted words with an empty string
  const formatedSelectedNestedCondition = formattedNestedWords.join('');

  
  const subWords = selectedSubCondition ? selectedSubCondition.split(' ') : [];

  // Map over the words and format them
  const formattedSubWords = subWords.map((word, index) => {
    if (index === 0) {
      // Capitalize the first word and convert it to camel case
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      // Convert other words to camel case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the formatted words with an empty string
  const formatedSelectedSubCondition = formattedSubWords.join('');

  const identifierWords = selectedIdentifier ? selectedIdentifier.split(' ') : [];

  // Map over the words and format them
  const formattedIdentifierWords = identifierWords.map((word, index) => {
    if (index === 0) {
      // Capitalize the first word and convert it to camel case
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      // Convert other words to camel case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the formatted words with an empty string
  const formatedSelectedIdentifier = formattedIdentifierWords.join('');

  const optionsByCondition = {

    // --------Boolean-----------------//
    Boolean:[
      "Convert to Yes or No",
      "Convert to Y or N",
      "Convert to True or False",
      "Convert to TRUE or FALSE",
      "Convert to 0 or 1",
      "Convert to on or off",
    ],

        // ------------Date-------------//

    // Date:[
    //       {
    //         Equals:[ 
    //             {
    //               DOBToAge:["Retrieve age from DOB"],
                  
    //               AgeToDob:[
    //                 "Convert age to DOB (YYYY/MM/DD)",
    //                 "Convert age to DOB (DD-MMM-YYYY)",
    //                 "Convert age to DOB (DD/MM/YYYY)",
    //                 "Convert age to DOB (DD-MM-YYYY)",
    //                 "Convert age to DOB (MM/DD/YYYY)",
    //                 "Convert age to DOB (Timestamp)",
    //               ],

    //               InputDate:[
    //                 "Format the input date as DD-MMM-YYYY",
    //                 "Format the input date as YYYY-MM-DD",
    //                 "Format the input date as DD/MM/YYYY",
    //                 "Format the input date as DD-MM-YYYY",
    //                 "Format the input date as Timestamp",
    //               ],
    //               CurrentDate:[
    //                 "Format today's date to YYYY/MM/DD",
    //                 "Format today's date to DD-MMM-YYYY",
    //                 "Format today's date to DD/MM/YYYY",
    //                 "Format today's date to DD-MM-YYYY",
    //                 "Format today's date to MM/DD/YYYY",
    //                 "Format today's date to Timestamp",
    //               ],
    //               CurrentDateInIst:[
    //                 "Format current date as YYYY/MM/DD, IST",
    //                 "Format current date as DD-MMM-YYYY, IST",
    //                 "Format current date as DD/MM/YYYY, IST",
    //                 "Format current date as DD-MM-YYYY, IST",
    //                 "Format current date as MM/DD/YYYY, IST",
    //                 "Format current date as Timestamp, IST",
    //               ]               
    //             }
    //           ],
    //           Before:[  
    //             {
    //               OneMonth:[
    //                 "Deduct one month, format as YYYY/MM/DD",
    //                 "Deduct one month, format as DD-MMM-YYYY",
    //                 "Deduct one month, format as DD/MM/YYYY",
    //                 "Deduct one month, format as DD-MM-YYYY",
    //                 "Deduct one month, format as MM/DD/YYYY",
    //                 "Deduct one month, format as Timestamp",
    //               ],
    //               OneYear:[
    //                 "Deduct one year, format as YYYY/MM/DD",
    //                 "Deduct one year, format as DD-MMM-YYYY",
    //                 "Deduct one year, format as DD/MM/YYYY",
    //                 "Deduct one year, format as DD-MM-YYYY",
    //                 "Deduct one year, format as MM/DD/YYYY",
    //                 "Deduct one year, format as Timestamp",
    //               ]
    //             }
    //           ],
    //         After:[
    //             {
    //               NextDay:[
    //                 "Calculate next day in YYYY/MM/DD format",
    //                 "Calculate next day in DD-MMM-YYYY format",
    //                 "Calculate next day in DD/MM/YYYY format",
    //                 "Calculate next day in DD-MM-YYYY format",
    //                 "Calculate next day in MM/DD/YYYY format",
    //                 "Calculate next day in Timestamp format",
    //               ],
    //               OneYear:[
    //                 "Expiry in one year, YYYY/MM/DD format",
    //                 "Expiry in one year, DD-MMM-YYYY format",
    //                 "Expiry in one year, DD/MM/YYYY format",
    //                 "Expiry in one year, DD-MM-YYYY format",
    //                 "Expiry in one year, MM/DD/YYYY format",
    //                 "Expiry in one year, Timestamp format",
    //               ],
    //               ThreeYear:[
    //                 "Expiry in three year, YYYY/MM/DD format",
    //                 "Expiry in three year, DD-MMM-YYYY format",
    //                 "Expiry in three year, DD/MM/YYYY format",
    //                 "Expiry in three year, DD-MM-YYYY format",
    //                 "Expiry in three year, MM/DD/YYYY format",
    //                 "Expiry in three year, Timestamp format",
    //               ],
    //               FiveYear:[
    //                 "Expiry in five year, YYYY/MM/DD format",
    //                 "Expiry in five year, DD-MMM-YYYY format",
    //                 "Expiry in five year, DD/MM/YYYY format",
    //                 "Expiry in five year, DD-MM-YYYY format",
    //                 "Expiry in five year, MM/DD/YYYY format",
    //                 "Expiry in five year, Timestamp format",
    //               ]
    //             }
    //           ]
    //       }
    //     ],
    Date:[
      {
        Equals:[ 
            "Retrieve age from DOB",
            "Convert age to DOB",
            "InputDate+0Days",
            "CurrentDate+0Days",
            "Extract the year from the date",
            "Extract the month from the date"            
          ],
          Before:[  
            "InputDate-1Day",
            "InputDate-365Days",
            "InputDate-1095Days",
            "InputDate-1825Days",
            "CurrentDate-365Days"
          ],
        After:[
          "InputDate+1Day",
          "InputDate+365Days",
          "InputDate+1095Days",
          "InputDate+1825Days",
          "CurrentDate+1Day",
          "CurrentDate+365Days",
        ]
      }
    ],

        // ---------------Email-----------------//

    Email:[
            "Verify email for correct format"
        ],
    
        // -----------Integer------------------//
    Integer: [
          "Convert string to integer"
      ],
    
    
        // -----------Password------------------//
    Password: [
        "Ensure strong and secure password"
      ],
      
      
        // -----------Percentage------------------//
    Percentage: [
      "Convert value to percentage"
    ],

        // ----------PhoneNumber---------------//

    PhoneNumber:[
            "Verify mobile number for correct format"
        ],

        // ----------Text---------------//

    Text: [
          // 'Verify PAN number for correct format',
          {
            Uppercase: ['Convert to Uppercase'],
            Lowercase: ['Convert to Lowercase'],
            TitleCase: ['Convert to Titlecase'],
            ExactlyMatches: [
              'Verify PAN Number',
              'Remove % from string',
              'Parse and get value by index',
              'Calculate NCB'
            ]
          }
        ],
        // ----------VehicleNumber---------//

    VehicleNumber:[
            "Format the vehicle number using hyphens",
            "Format the vehicle number using space",
            "Format the vehicle number using segments",
            "Format the vehicle number without space"
        ]
    }
  
    let options = [];
    // if (formatedSelectedNestedCondition && selectedIdentifier === 'Date') {
    //   options = optionsByCondition[selectedIdentifier]?.[0]?.[selectedSubCondition]?.[0]?.[formatedSelectedNestedCondition] || [];
    // } 
    if (formatedSelectedSubCondition && selectedIdentifier === 'Date') {
      options = optionsByCondition[selectedIdentifier]?.[0]?.[formatedSelectedSubCondition] || [];
    }
    else if(formatedSelectedSubCondition && selectedIdentifier === 'Text') {
      options = optionsByCondition[selectedIdentifier]?.[0]?.[formatedSelectedSubCondition] || [];
    } else {
      options = optionsByCondition[formatedSelectedIdentifier]
    }

  // Ensure options is an array
  const optionsArray = Array.isArray(options) ? options : Object.values(options);

  // return (
  //   <>
  //     {optionsArray.map((option, index) => (
  //       <option key={index} value={option}>
  //         {option}
  //       </option>
  //     ))}
  //   </>
  // );
  return (
    <>
      {optionsArray.map((option, index) => (
        <option key={index} value={selectedIdentifier === "Date" ? `${option} | ${selectedNestedCondition}` : option}>
          {selectedIdentifier === "Date" ? `${option} | ${selectedNestedCondition}` : option}
        </option>
      ))}
    </>
  );
  

};

export default ResultOptions;
