import React, { useState, useEffect, useContext } from 'react'
import Draggable from 'react-draggable';
import axios from 'axios';
import '../../../Styles/Workflow/DynamicAction/ResponseTab/GetFieldValuePopup.css';
import square_exit_white from '../../../../Assets/Images/square_exit_white.png';
import save from '../../../../Assets/Images/save.png';
import copy from '../../../../Assets/Images/copy.png'; 
import paste from '../../../../Assets/Images/paste.png'; 
import { CopyContext } from '../../../../Context/GetValueFromForResponseTab';

function GetFieldValuePopup({onClose, index, onSave, onGetValueFrom, getValueFromDB, fieldStatePaths, actionLabel}) {
    const [getValueFrom, setGetValueFrom] = useState('');
    const [manualEnteredValue, setManualEnteredValue] = useState('');

    const [selectedPath, setSelectedPath] = useState('');
    const [modifyTextConditions, setModifyTextConditions] = useState([]);

    const [document, setDocument] = useState(null);
    const [triggerOption, setTriggerOption] = useState('');
    const [internalStep, setInternalStep] = useState('');
    const [internalStepKey, setInternalStepKey] = useState('');
    const [internalDropdownOptions, setInternalDropdownOptions] = useState([]);

    const { copiedData, setCopiedData } = useContext(CopyContext);

    const handleSaveFieldvalue = () => {
        if(index !== null) {
            let finalValue = '';
            if (getValueFrom === 'Manual'){
                finalValue = manualEnteredValue;
            } else if (getValueFrom === 'Trigger'){
                if(triggerOption === 'Internal'){
                    finalValue = `${getValueFrom} : ${triggerOption} : ${internalStep} : ${internalStepKey}`
                }

            } else if (getValueFrom === 'RenameFieldOnCondition'){
                finalValue = `${getValueFrom} : ${selectedPath} :`
            }

            modifyTextConditions?.forEach((condition, index, array) => {
                finalValue += ` ${condition.selectedTextModifyBeforeText}=${condition.selectedTextModifyAfterText}`;
                if (index < array.length - 1) {
                  finalValue += " /";
                }
            });

        onSave(finalValue);
        onClose();
        }
    }

    const handleCopy = () => {
        const dataObject = {
            getValueFrom,
            manualEnteredValue,
            selectedPath,
            modifyTextConditions,
            triggerOption,
            internalStep,
            internalStepKey
        };
        setCopiedData(dataObject);
    };

    const handlePaste = () => {
        if (copiedData) {
            setGetValueFrom(copiedData.getValueFrom);
            setManualEnteredValue(copiedData.manualEnteredValue);
            setSelectedPath(copiedData.selectedPath);
            setModifyTextConditions(copiedData.modifyTextConditions);
            setTriggerOption(copiedData.triggerOption);
            setInternalStep(copiedData.internalStep);
            setInternalStepKey(copiedData.internalStepKey);
        }
    };


    // console.log("index:", index);
    // console.log("fieldStatePaths:", fieldStatePaths);
    const indexedElements = fieldStatePaths.filter(path => /\[\d+\]/.test(path));

    useEffect(() => {
        // Check if getValueFromDB and index are provided
        if (getValueFromDB && index !== undefined) {
            // Find the corresponding object in getValueFromDB based on the index
            const matchingObject = getValueFromDB[index];
            
            // If a matching object is found, update the state variables
            if (matchingObject) {
                setGetValueFrom(matchingObject.getValueFrom || '');
                setManualEnteredValue(matchingObject.manualEnteredValue || '');
                setSelectedPath(matchingObject.selectedPath || '');
                setModifyTextConditions(matchingObject.modifyTextConditions || []);
                setTriggerOption(matchingObject.triggerOption || '');
                setInternalStep(matchingObject.internalStep || '');
                setInternalStepKey(matchingObject.internalStepKey || '');
            }
        }
    }, [getValueFromDB, index]);

    useEffect(() => {
        const generateDataObject = async () => {
            const dataObject = {
                getValueFrom: getValueFrom,
                manualEnteredValue: manualEnteredValue,
                selectedPath: selectedPath,
                modifyTextConditions: modifyTextConditions,
                triggerOption: triggerOption,
                internalStep: internalStep,
                internalStepKey: internalStepKey
            }
            onGetValueFrom(index, dataObject)
        }
        generateDataObject()
    }, [
        getValueFrom, 
        manualEnteredValue,
        selectedPath,
        modifyTextConditions,
        triggerOption,
        internalStep,
        internalStepKey,
        index,
        onGetValueFrom
    ])

    const handleTextModifyConditionButtonClick = () => {
        const newCondition = {
            selectedTextModifyBeforeText: '',
            selectedTextModifyResult: '',
            selectedTextModifyAfterText: '',
        }
        setModifyTextConditions((prevConditions) => [...prevConditions, newCondition]);
    }

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axios.post('https://aim-core.insurancepolicy4us.com/get-document', { actionLabel });
                console.log("full Res Document from DB...", response);
                console.log("Document from DB...", response.data);
                setDocument(response.data);
  
                // Generate dropdown options
                const options = response.data.map((item) => {
                  return `${item.step}-${item.connectorDetails.LabelofAction}`;
                });
                setInternalDropdownOptions(options);
            } catch (error) {
                console.error('Error fetching the document:', error);
            }
        };
  
        fetchDocument();
      }, [actionLabel]);


  return (
    <Draggable>
    <div className="get-field-value-popup">
        <div className="get-field-value-popup-header">
            <h4 className='get-field-value-header-h4'>Get value from</h4>
            <div className="get-field-value-container-img" >
                <img src={save} alt="save" className="get-field-value-container-img-hover" onClick={handleSaveFieldvalue}/>
            </div>
            <div className="get-field-value-container-img">
                <img src={copy} alt="copy" className="get-field-value-container-img-hover" onClick={handleCopy} />
            </div>
            <div className="get-field-value-container-img">
                <img src={paste} alt="copy" className="get-field-value-container-img-hover" onClick={handlePaste} />
            </div>
            <div className="get-field-value-container-img" >
                <img src={square_exit_white} alt="square_exit_white" className="get-field-value-container-img-hover" onClick={onClose}/>
            </div>
        </div>
        <div className="get-field-value-popup-body">
        <div className="row">
            <div className="col-md-12 gvcls1">
                <label className="col-md-4 gv-label1" htmlFor="to" >
                Get value from:
                </label>
                <select
                className="gv-select1"
                value={getValueFrom}
                onChange={(e) => setGetValueFrom(e.target.value)}
                >
                    <option value="" disabled>Select a option</option>
                    <option value="Trigger">Trigger</option>                
                    <option value="Manual">Manual</option>                
                    <option value="RenameFieldOnCondition">Rename Field On Condition</option>                
                </select>      
            </div>
        </div>
        {getValueFrom === 'Trigger' &&
            <>
            <div className="row">
            <div className="col-md-12 gvcls1">
                <label className="col-md-4 gv-label1" htmlFor="to" >
                </label>
                <select
                    type='text'
                    className='gv-input1'
                    value={triggerOption}
                    onChange={(e) => setTriggerOption(e.target.value)}
                > 
                    <option value="" disabled>Select a option</option>
                    <option value="Internal">Internal</option> 
                    <option value="External">External</option> 
                </select>        
            </div>
            </div>
            {triggerOption === "Internal" && (
                <>
                    <div className="row">
                        <div className="col-md-12 gvcls1">
                            <label className="col-md-4 gv-label1" htmlFor="to" >
                            </label>
                            <select
                                className="gv-input1"
                                value={internalStep}
                                onChange={(e) => setInternalStep(e.target.value)}    
                            >
                                <option value="" disabled>Select an option</option>
                                {internalDropdownOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                                ))}
                            </select>       
                        </div>
                    </div>
                    {triggerOption === "Internal" && internalStep !== "" && (
                        <div className="row">
                            <div className="col-md-12 gvcls1">
                                <label className="col-md-4 gv-label1" htmlFor="to" >
                                </label>
                                <input
                                    type='text'
                                    className='gv-input1'
                                    placeholder='Enter Key here'
                                    value={internalStepKey}
                                    onChange={(e) => setInternalStepKey(e.target.value)}
                                />      
                            </div>
                        </div>
                    )}
                </>
            )}
            </>
        }
        {getValueFrom === 'Manual' &&
            <div className="row">
                <div className="col-md-12 gvcls1">
                    <label className="col-md-4 gv-label1" htmlFor="to" >
                    </label>
                    <input
                        type='text'
                        className='gv-input1'
                        placeholder='Enter value here'
                        value={manualEnteredValue}
                        onChange={(e) => setManualEnteredValue(e.target.value)}
                    />      
                </div>
            </div>
        }
        {getValueFrom === 'RenameFieldOnCondition' &&
            <>
                <div className="row">
                    <div className="col-md-12 gvcls1">
                        <label className="col-md-4 gv-label1" htmlFor="to" >
                            Conditional Path
                        </label>
                        <select
                            className="gv-select1"
                            value={selectedPath}
                            onChange={(e) => setSelectedPath(e.target.value)}
                        >
                            <option value="" disabled>Select a Path</option>
                            {indexedElements.map((path, index) => (
                                <option key={index} value={path}>
                                    {path}
                                </option>
                            ))}
                        </select>      
                    </div>
                </div>
                {modifyTextConditions && modifyTextConditions.map((condition, index) => (
                    <>
                        <div className="row">
                        <div className="col-md-12 gfvpcls1">
                            <label className="col-md-4 gfvp-cc-label2">
                            Text
                            </label>
                            <input
                            className="gfvp-select1"
                            type='text'
                            value={condition.selectedTextModifyBeforeText}
                            onChange={(e) => {
                                const newConditions = [...modifyTextConditions];
                                newConditions[index].selectedTextModifyBeforeText = e.target.value;
                                setModifyTextConditions(newConditions);
                            }}                          
                            />  
                        </div>
                            </div>
                            <div key={index} className='gfvp-outerline'>
                        <div className="row">
                        <div className="col-md-12 gfvpcls1">
                            <label className="col-md-4 gfvp-cc-label2">
                            Result
                            </label>
                            <select
                            className="gfvp-select1"
                            value={condition.selectedTextModifyResult}
                            onChange={(e) => {
                                const newConditions = [...modifyTextConditions];
                                newConditions[index].selectedTextModifyResult = e.target.value;
                                setModifyTextConditions(newConditions);
                            }}                         
                            >
                            <option value="" disabled>Select a option</option>
                            <option value="Text">Text</option>
                            </select>      
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12 gfvpcls1">
                            <label className="col-md-4 gfvp-cc-label2">
                            
                            </label>
                            <input
                            className="gfvp-select1"
                            type='text'
                            value={condition.selectedTextModifyAfterText}
                            onChange={(e) => {
                                const newConditions = [...modifyTextConditions];
                                newConditions[index].selectedTextModifyAfterText = e.target.value;
                                setModifyTextConditions(newConditions);
                            }}                          
                            />  
                        </div>
                        </div>
                        </div>
                    </>
                ))}
                <div className="row">
                    <div className="col-md-12 gfvp-cls1">
                        <button className='col-md-12 gfvp-format-btn' onClick={handleTextModifyConditionButtonClick}>
                            <span className="gfvp-text">Modify Condition</span>
                        </button>
                    </div>
                </div>
            </>
        }
        </div>
    </div>
    </Draggable>
  )
}

export default GetFieldValuePopup