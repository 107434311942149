import React, { useState, useEffect } from 'react';
import '../../../Styles/Plugins/ApiConnector/Authentication/PrivateKeyinHeader.css';

function PrivateKeyinHeader({onAuthObjectChange, authenticationData}) {
  const [keyNameHeader, setKeyNameHeader] = useState('Authorization');
  const [keyValueHeader, setKeyValueHeader] = useState('');
  const [developmentKeyValueHeader, setDevelopmentKeyValueHeader] = useState('');

  useEffect(() => {
    if (authenticationData) {
      setKeyNameHeader(authenticationData.keyNameHeader || 'Authorization');
      setKeyValueHeader(authenticationData.keyValueHeader || '');
      setDevelopmentKeyValueHeader(authenticationData.developmentKeyValueHeader || '');
    }
  }, [authenticationData]);

  const handleKeyNameHeader = (e) => {
    setKeyNameHeader(e.target.value);
  };

  const handleKeyValueHeader = (e) => {
    setKeyValueHeader(e.target.value);
  };

  const handleDevelopmentKeyValueHeader = (e) => {
    setDevelopmentKeyValueHeader(e.target.value);
  };

  useEffect(() => {
    const generateDataObject = () => {
      const dataObject = {
        keyNameHeader,
        keyValueHeader,
        developmentKeyValueHeader
      };
      onAuthObjectChange(dataObject);
    };
    generateDataObject();
  }, [keyNameHeader, keyValueHeader, developmentKeyValueHeader, onAuthObjectChange]);
  
  return (
    <div className="privatekeyinheader-container">
      <div className="privatekeyinheader-row">
        <label htmlFor={'keyNameHeader'}>Key Name</label>
        <input id={'keyNameHeader'} value={keyNameHeader} type="text" onChange={handleKeyNameHeader} />
      </div>
      <div className="privatekeyinheader-row">
        <label htmlFor={'keyValueHeader'}>Key Value</label>
        <input id={'keyValueHeader'} value={keyValueHeader} type="text" onChange={handleKeyValueHeader} />
      </div>

      <div className="privatekeyinheader-row1">
        <label htmlFor={'developmentKeyValueHeader'}>Development Key Value</label>
        <input
          id={'developmentKeyValueHeader'}
          value={developmentKeyValueHeader}
          type="text"
          placeholder="(Optional)"
          onChange={handleDevelopmentKeyValueHeader}
        />
      </div>
    </div>
  );
}

export default PrivateKeyinHeader;
