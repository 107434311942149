import React, { useState, useEffect } from 'react'
import '../../../../Styles/Workflow/DynamicAction/GetDataFromDB/GetDataForPopup/ValueResultKey.css'
import ConditionForValueOfResultKey from './ConditionForValueOfResultKey'

function ValueResultKey({clickedInputId, onValueChangeForDB, onDataFromComponents, dataFromComponents}) {
    const[getValueFromResultKey, setGetValueFromResultKey] = useState(dataFromComponents?.["value-from-result-key"]?.valuesFromComponents.getValueFromResultKey || '')
    const[manualInputForResult, setManualInputForResult] = useState(dataFromComponents?.["value-from-result-key"]?.valuesFromComponents.manualInputForResult || '')
    const[dynamicInputForResult, setDynamicInputForResult] = useState(dataFromComponents?.["value-from-result-key"]?.valuesFromComponents.dynamicInputForResult || '')
    const[triggerOptionForResult, setTriggerOptionForResult] = useState(dataFromComponents?.["value-from-result-key"]?.valuesFromComponents.triggerOptionForResult || '')
    const[triggerExternalInputForResult, setTriggerExternalInputForResult] = useState(dataFromComponents?.["value-from-result-key"]?.valuesFromComponents.triggerExternalInputForResult || '')

    useEffect(() => {
        if(clickedInputId !== null){
          let finalValue = '';
          if(getValueFromResultKey === 'Manual'){
              finalValue = `${manualInputForResult}`
          } else if(getValueFromResultKey === 'Dynamic'){
            finalValue = `${getValueFromResultKey} : ${dynamicInputForResult}`
          } else if(getValueFromResultKey === 'Trigger'){
              finalValue = `${getValueFromResultKey} : ${triggerOptionForResult} : ${triggerExternalInputForResult}`
          }
          onValueChangeForDB(finalValue, clickedInputId)
        }
  
      }, [clickedInputId, onValueChangeForDB])

      useEffect(() => {
        const generateDataObject = async () => {
            const dataObject ={
                    getValueFromResultKey: getValueFromResultKey,
                    manualInputForResult: manualInputForResult,
                    dynamicInputForResult:dynamicInputForResult,
                    triggerOptionForResult: triggerOptionForResult,
                    triggerExternalInputForResult: triggerExternalInputForResult
            }
            onDataFromComponents(dataObject); 
        }
    
        generateDataObject(); 
    
    }, [
        getValueFromResultKey, 
        manualInputForResult, 
        dynamicInputForResult,
        triggerOptionForResult, 
        triggerExternalInputForResult,
        onDataFromComponents
      ]);

    return (
      <>
          <div className="row">
          <div className="col-md-12 gvrkcls1">
              <label className="col-md-4 gvrk-label1" htmlFor="to" >
              Value from result key:
              </label>
              <select
                  className="gvrk-select1"
                  value={getValueFromResultKey}
                  onChange={(e) => setGetValueFromResultKey(e.target.value)}
              >
                  <option value="" disabled>Select a option</option>
                  <option value="Manual">Manual</option>                
                  <option value="Dynamic">Dynamic</option>                
                  <option value="Trigger">Trigger</option>                
              </select>      
          </div>
          </div>
          {getValueFromResultKey === 'Manual' && (
              <div>
              <div className="row">
                  <div className="col-md-12 gvrkcls1">
                      <label className="col-md-4 gvrk-label1">
                      </label>
                      <input
                          className="gvrk-select1"
                          type='text'
                          value={manualInputForResult}
                          onChange={(e) => setManualInputForResult(e.target.value)}  
                      />     
                  </div>
              </div>
              </div>
          )}
          {getValueFromResultKey === 'Dynamic' && (
              <div>
                <div className="row">
                    <div className="col-md-12 gvrkcls1">
                        <label className="col-md-4 gvrk-label1">
                        </label>
                        <input
                            className="gvrk-select1"
                            type='text'
                            value={dynamicInputForResult}
                            onChange={(e) => setDynamicInputForResult(e.target.value)}  
                        />     
                    </div>
                </div>
                {/* <ConditionForValueOfResultKey/> */}
              </div>
          )}
          {getValueFromResultKey === 'Trigger' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdikcls1">
                    <label className="col-md-4 gdik-label1">
                    </label>
                    <select
                        className="gdik-select1"
                        value={triggerOptionForResult}
                        onChange={(e) => setTriggerOptionForResult(e.target.value)}
                    >   
                        <option value="" disabled>Select a option</option>
                        <option value="Internal">Internal</option>                
                        <option value="External">External</option>                
                    </select>
                </div>
            </div>
            {triggerOptionForResult === 'External' && (
                <div>
                <div className="row">
                    <div className="col-md-12 gdikcls1">
                        <label className="col-md-4 gdik-label1">
                        </label>
                        <input
                            className="gdik-select1"
                            type='text'
                            value={triggerExternalInputForResult}
                            onChange={(e) => setTriggerExternalInputForResult(e.target.value)}
                        />     
                    </div>
                </div>
                </div>
            )}
            </div>
        )}
      </>
    )
}

export default ValueResultKey