import React, { createContext, useState, useContext, useEffect } from 'react';
import { useProjectsContext } from './ProjectsContext'; 

const ConnectorContext = createContext();

export const useConnectorContext = () => {
  return useContext(ConnectorContext);
};

export const ConnectorContextProvider = ({ children }) => {
  const [firstDocumentId, setFirstDocumentId] = useState(null);
  const [connectorName, setConnectorName] = useState(null);
  const [dataFromContext, setDataFromContext] = useState([]);
  const [connectorValues, setConnectorValues] = useState([]);

  // Retrieve currentDatabase from localStorage
  const { currentDatabase } = useProjectsContext();  
  console.log("currentDatabase1_:", currentDatabase);

  //https://aim-core.insurancepolicy4us.com/
 
  useEffect(() => {
    const fetchData = async () => {
      setFirstDocumentId(null)
      setConnectorName(null)
      setDataFromContext([])
      setConnectorValues([])
        try {
            console.log("currentDatabase:", currentDatabase);

            const response = await fetch(`https://aim-core.insurancepolicy4us.com/getplugins`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ databaseName: currentDatabase })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            console.log("get plugins:", data);
            // Find the object where Connector_Name is 'API Connector'
            const apiConnector = data.find(item => item.Connector_Name === 'API Connector');

            if (apiConnector) {
                setFirstDocumentId(apiConnector._id);
                setConnectorName(apiConnector.Connector_Name);
                setConnectorValues(apiConnector.Connector_Values);
            }

            setDataFromContext(data);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error or set an appropriate state
        }
    };

    fetchData();
}, [currentDatabase]);


  return (
    <ConnectorContext.Provider value={{ firstDocumentId, connectorName, connectorValues, dataFromContext }}>
      {children}
    </ConnectorContext.Provider>
  );
};
