
import '../Styles/LoginModule/Login.css'
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import BounceLoader from "react-spinners/BounceLoader";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { authContext } from "../../Context/AuthContext";
import { useProjectsContext } from "../../Context/ProjectsContext";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { fetchProjectwithToken } = useProjectsContext();
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const { dispatch } = useContext(authContext);

  // password visibility toggle
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (event) => {
    console.log("inside submitHandler-login");
    event.preventDefault();
    setLoading(true);

    try {
      console.log("inside try-login");

      const res = await fetch(`https://aim-core.insurancepolicy4us.com/auth/login`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await res.json();
      console.log("login result", result);

      if (!res.ok) {
        throw new Error(result.message);
      }

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          admin: result.data,
          token: result.token,
          access: result.access,
        },
      });
      console.log(result, "login data");

      // await fetchProjectwithToken(result.token);
      setLoading(false);
      console.log("result.message",result.message);
      navigate("/createproject");
    } catch (err) {
      console.error(err.message);
      setLoading(false);
    }
  };

  return (
    <section className="li-container">
      <div className="li-form-container">
        <div className="li-form-wrapper">
          <h3 className="li-form-title">Sign-in your Account</h3>
          <form className="py-4 md:py-0" onSubmit={submitHandler}>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="li-form-input"
                required
              />
            </div>
            <div className="mb-4 li-input-container">
              <input
                type={passwordType}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="li-form-input"
                required
              />

              <div className="li-password-icon" onClick={togglePassword}>
                {passwordType === "password" ? <FaRegEyeSlash /> : <FaRegEye />}
              </div>
            </div>

            <div className="li-button-container">
              <button disabled={loading && true} className="li-submit-button">
                {/* {loading ? (
                  <BounceLoader
                    className="loadingicon"
                    size={27}
                    color="#355bf3"
                  />
                ) : ( */}
                  Login
                {/* )} */}
              </button>
            </div>

            {/* <p className="li-link-text">
              Don&apos;t have an account?{" "}
              <Link to="/signup" className="li-link">
                Register
              </Link>
            </p> */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
