import React, { useState, useEffect } from 'react'
import '../../../Styles/Plugins/ApiConnector/Authentication/HTTPBasicAuth.css'

  function HTTPBasicAuth({onAuthObjectChange, authenticationData}) {

    console.log("*......authenticationData.....*:", authenticationData);
    
    const [userNameHTTPBasicAuth, setUserNameHTTPBasicAuth] = useState(authenticationData?.userNameHTTPBasicAuth || '');
    const [passwordHTTPBasicAuth, setPasswordHTTPBasicAuth] = useState(authenticationData?.passwordHTTPBasicAuth || '');

    // useEffect(() => {
    //   if (authenticationData) {
    //     setUserNameHTTPBasicAuth(authenticationData.userNameHTTPBasicAuth || '');
    //     setPasswordHTTPBasicAuth(authenticationData.passwordHTTPBasicAuth || '');
    //   }
    // }, [authenticationData]);

    const handleUserNameHTTPBasicAuth = (e) => {
        setUserNameHTTPBasicAuth(e.target.value);
      };
    
      const handlePasswordHTTPBasicAuth = (e) => {
        setPasswordHTTPBasicAuth(e.target.value);
      };

      useEffect(() => {
        const generateDataObject = () => {
          const dataObject = {
            userNameHTTPBasicAuth,
            passwordHTTPBasicAuth,
          };
          if (typeof onAuthObjectChange === 'function') {
            onAuthObjectChange(dataObject);
          } else {
              console.warn("onAuthObjectChange is not defined as a function");
          }
        };
        generateDataObject();
      }, [
        userNameHTTPBasicAuth, 
        passwordHTTPBasicAuth, 
        onAuthObjectChange
      ]);
      

      return (
        <div className="httpbasicauth-container">
          <div className="httpbasicauth-row">
            <label htmlFor={'userNameHTTPBasicAuth'}>Username</label>
            <input id={'userNameHTTPBasicAuth'} value={userNameHTTPBasicAuth} type="text" onChange={handleUserNameHTTPBasicAuth} />
          </div>
          <div className="httpbasicauth-row">
            <label htmlFor={'passwordHTTPBasicAuth'}>Password</label>
            <input id={'passwordHTTPBasicAuth'} value={passwordHTTPBasicAuth} type="text" onChange={handlePasswordHTTPBasicAuth} />
          </div>
        </div>
      );
}

export default HTTPBasicAuth