import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable';
import '../../Styles/NestedArrayObject/GetDataFrom/GetDataFromPopup.css';
import square_exit_white from '../../../Assets/Images/square_exit_white.png';
import save from '../../../Assets/Images/save.png';

function GetDataFromPopup({ onSave }) {
    const [getData, setGetData] = useState('');
    const [activeTab, setActiveTab] = useState('Input');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleSaveValue = () => {
        onSave(getData);
    }

  return (
    <Draggable>
        <div className="get-data-from-popup">
            <div className="get-data-from-popup-header">
                <h4 className='get-data-fromheader-h4'>Get data from</h4>
                <div className="get-data-from-container-img" >
                    <img src={save} alt="save" className="get-data-from-container-img-hover" onClick={handleSaveValue}/>
                </div>
                <div className="get-data-from-container-img" >
                    <img src={square_exit_white} alt="square_exit_white" className="get-data-from-container-img-hover" />
                </div>
            </div>
            <div className="get-data-from-tab-container">
              <div
                className={`get-data-from-tab ${activeTab === 'Input' ? 'get-data-from-active-tab' : ''}`}
                onClick={() => handleTabClick('Input')}
              >
                Input
              </div>
              <div
                className={`get-data-from-tab ${activeTab === 'Condition' ? 'get-data-from-active-tab' : ''}`}
                onClick={() => handleTabClick('Condition')}
              >
                Condition
              </div>
            </div>
            <div className="get-data-from-popup-body">
                {activeTab === 'Input' && (
                    <div>
                        <div className="row">
                            <div className="col-md-12 gdfcls1">
                                <label className="col-md-4 gdf-label1" htmlFor="to" >
                                Get data from:
                                </label>
                                <select
                                className="gdf-select1"
                                value={getData}
                                onChange={(e) => setGetData(e.target.value)}
                                >
                                <option value="" disabled>Select a option</option>
                                <option value="Trigger">Trigger</option> 
                                <option value="Event">Event</option> 
                                <option value="Manual">Manual</option>                
                                <option value="MongoDB">MongoDB</option>                
                                <option value="GetRandomNumber">Get Random Number</option>                
                                <option value="SingleKey">Single Key</option>                
                                <option value="MultipleKey">Multiple Key</option>                
                                </select>      
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </Draggable>
  )
}

export default GetDataFromPopup