import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable';
import square_exit_white from '../../../Assets/Images/square_exit_white.png';
import save from '../../../Assets/Images/save.png';
import edit from '../../../Assets/Images/edit.png';
import '../../Styles/NestedArrayObject/Connector/ConnectorComponent.css'
import NestedApiConnector from './NestedApiConnector';

function ConnectorComponent() {
    const [activeTab, setActiveTab] = useState('Input');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


  return (
    <Draggable>
        <div className="nested-connector-component-popup">
            <div className="nested-connector-component-popup-header">
            <div className="nested-connector-component-options">
                <h6 className='nested-connector-component-header-h4'></h6> 
                <h6 className='nested-connector-component-header-h4'></h6> 
                <h6 className='nested-connector-component-header-h4'></h6>
            </div>
            <div className="nested-connector-component-actions">
                <div className="nested-connector-component-container-img" >
                    <img src={save} alt="save" className="nested-connector-component-container-img-hover" />
                </div>
                <div className="nested-connector-component-container-img" >
                    <img src={edit} alt="edit" className="nested-connector-component-container-img-hover" />
                </div>
                <div className="nested-connector-component-container-img" >
                    <img src={square_exit_white} alt="square_exit_white" className="nested-connector-component-container-img-hover" />
                </div>
            </div>
            </div>
            <div className="nested-connector-component-tab-container">
                <div
                className={`nested-connector-component-tab ${activeTab === 'Input' ? 'nested-connector-component-active-tab' : ''}`}
                onClick={() => handleTabClick('Input')}
                >
                    Input
                </div>
                <div
                className={`nested-connector-component-tab ${activeTab === 'Condition' ? 'nested-connector-component-active-tab' : ''}`}
                onClick={() => handleTabClick('Condition')}
                >
                    Condition
                </div>
                <div
                className={`nested-connector-component-tab ${activeTab === 'Response' ? 'nested-connector-component-active-tab' : ''}`}
                onClick={() => handleTabClick('Response')}
                >
                    Response
                </div>
            </div>
            <div className="nested-connector-component-popup-body">
                <div className="row">
                    <div className="col-md-12 nested-cccls1">
                        <label className="col-md-5 nested-cc-label1" htmlFor="Label of Action" >
                            Label of Action:
                        </label>
                        <input
                            className="nested-cc-input1"
                            value={"actionLabel"}
                        />   
                    </div>
                </div>
                {activeTab === 'Input' && (
                    <NestedApiConnector/>
                )}
            </div>
        </div>
    </Draggable>
  )
}

export default ConnectorComponent