import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import workflow from '../../Assets/Images/workflow_gray.png';
import workflow_blue from '../../Assets/Images/workflow_blue.png';
import node from '../../Assets/Images/node.png';
import plugin from '../../Assets/Images/plugin_gray.png';
import plugin_blue from '../../Assets/Images/plugin_blue.png';
import settings from '../../Assets/Images/settings_gray.png';
import settings_blue from '../../Assets/Images/settings_blue.png';
import '../Styles/VerticalNavbar/VerticalNavbar.css';
import { useProjectsContext } from '../../Context/ProjectsContext'; 

const VerticalNavbar = ({ selectedTab }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  
  const { currentDatabase } = useProjectsContext();  
  console.log(".....V'currentDatabase...", currentDatabase);

  const handleButtonClick = (tab) => {
    const link = `/${currentDatabase}/dynamicevent?tab=${tab}`;
    return link;
  };

  return (
    <div className="main-tab-bar">
      <div className="ver_col">
        <Link to={handleButtonClick('workflow')} className={`ver_btn ${selectedTab === 'workflow' ? 'active' : ''}`}>
          <img src={selectedTab === 'workflow' ? workflow_blue : workflow} alt="workflow" className="ver_img" />
          <span className="ver_label">Workflow</span>
        </Link>
        <div className='h_line'></div>
        <Link to={handleButtonClick('apinode')} className={`ver_btn ${selectedTab === 'apinode' ? 'active' : ''}`}>
          <img src={selectedTab === 'apinode' ? node : node} alt="node" className="ver_img" />
          <span className="ver_label">API Nodes</span>
        </Link>
        <div className='h_line'></div>
        <Link to={handleButtonClick('plugins')} className={`ver_btn ${selectedTab === 'plugins' ? 'active' : ''}`}>
          <img src={selectedTab === 'plugins' ? plugin_blue : plugin} alt="plugin" className="ver_img" />
          <span className="ver_label">Plugins</span>
        </Link>
        <div className='h_line'></div>
        <Link to={handleButtonClick('settings')} className={`ver_btn ${selectedTab === 'settings' ? 'active' : ''}`}>
          <img src={selectedTab === 'settings' ? settings_blue : settings} alt="settings" className="ver_img" />
          <span className="ver_label">Settings</span>
        </Link>
        <div className='h_line'></div>
      </div>
    </div>
  );
};

export default VerticalNavbar;

