import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable';
import '../../Styles/Workflow/DynamicAction/ConnectorComponent.css'
import square_exit_white from '../../../Assets/Images/square_exit_white.png';
import save from '../../../Assets/Images/save.png';
import edit from '../../../Assets/Images/edit.png';
import DynamicActionConnectors from './Connectors/DynamicActionConnectorsPath'
import GetDataPopup from './GetDataPopup';
import ResponseTab from './ResponseTab/ResponseTab';

function ConnectorComponent({option, onClose, selectedActionIndex, selectedEventIndex, onConnectorData, connectorDetails, actionStep, customEventText}) {

  const [activeTab, setActiveTab] = useState('Input');
  const [showGetDataPopup, setShowGetDataPopup] = useState(false);
  const [manualInputValue, setManualInputValue] = useState('');
  const [clickedInputField, setClickedInputField] = useState(null);
  const [inputConnectorData, setInputConnectorData] = useState({});
  const [getDataFromValues, setGetDataFromValues] = useState({});
  const [apiCallName, setApiCallName] = useState('')
  const [getDataFromResponse, setGetDataFromResponse] = useState({});
  const [selectedName, setSelectedName] = useState('')
  const [actionLabel, setActionLabel] = useState('')
  const [isActionLabelFilled, setIsActionLabelFilled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedForObject, setIsCheckedForObject] = useState(false);

  // console.log("actionStep:", actionStep);
  // console.log("customEventText:", customEventText);


    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    const handleClose = () => {
      onClose();
    };


    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const openGetDataPopup = (inputFieldName) => {
      // Show GetDataPopup and pass the clicked input field information
      console.log("inputFieldName", inputFieldName)
      setShowGetDataPopup(true);
      setClickedInputField(inputFieldName); // Assuming you have a state variable to store the clicked input field information
    };    
  
    const closeGetDataPopup = () => {
      // Close GetDataPopup
      setShowGetDataPopup(false);
    };

    // Get the component dynamically based on the option
    const SelectedComponent = option ? DynamicActionConnectors[option] : null;


    // Callback function to update the manual input value
    const handleManualValueChange = (value) => {
      console.log("manualInputValue",value);
      setManualInputValue(value);
    };

    const onInputConnectorData = (dataObject) => {
      // console.log("dataObject from inputConnectot data",dataObject);
      setInputConnectorData((prevDataObject) => {
        const mergedDataObject = { ...prevDataObject, ...dataObject };
        return mergedDataObject;
      });

    }

    // const onGetDataFrom = (dataObject) => {
    //   setGetDataFromValues((prevDataObject) => {
    //     const mergedDataObject = { ...prevDataObject, ...dataObject };
    //     return mergedDataObject;
    //   });
    // }

    const onGetDataFrom = (apiCallKey, dataObject) => {
      // console.log("dataObject from get data popup", dataObject)
      setGetDataFromValues((prevDataObject) => {
        const updatedDataObject = { ...prevDataObject };
        // Overwrite any existing object with the new one
        updatedDataObject[apiCallKey] = dataObject;
        return updatedDataObject;
      });
    }; 
    
    const onGetDataFromResponse = (dataObject) => {
      // console.log("onGetDataFromResponse:",dataObject)
      setGetDataFromResponse((prevDataObject) => {
        return { ...prevDataObject, ...dataObject };
      });      
    }; 

    // useEffect(() => {
    //   const generateDataObject = async () => {
    //     const dataObject = {
      //   selectedEventIndex:selectedEventIndex,
      //   [selectedActionIndex]:{
        // LabelofAction:actionLabel,
      //     Input:{
      //       inputConnectorData,
      //       getDataFromValues
      //     },
      //     Condition:{},
      //     Response:{
      //       getDataFromResponse
      //     }
      //   }
      // };
    //     onConnectorData(dataObject);
    //   }
    //     generateDataObject();
    // }, [selectedEventIndex, selectedActionIndex, inputConnectorData, onConnectorData]);
    const handleCheckboxChangeForObject = (event) => {
      setIsCheckedForObject(event.target.checked);
    };

    const handleSaveConnectorData = async () => {
      const dataObject = {
        selectedEventIndex:selectedEventIndex,
        [selectedActionIndex]:{
          LabelofAction:actionLabel,
          isEncryptionRequired:isChecked,
          removeObjectFromArray:isCheckedForObject,
          Input:{
            inputConnectorData,
            getDataFromValues
          },
          Condition:{},
          Response:{
            getDataFromResponse
          }
        }
      };
      onConnectorData(dataObject);
      onClose();
    }

    const handleUpdateClick = async () => {
      console.log("hoooo");
      try {

        // Assume you have an API endpoint to update the connector details
        const updateEndpoint = `https://aim-core.insurancepolicy4us.com/update-connector/${selectedEventIndex}/${selectedActionIndex}`;
  
        const response = await fetch(updateEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            connectorDetails: {
              LabelofAction:actionLabel,
              isEncryptionRequired:isChecked,
              removeObjectFromArray:isCheckedForObject,
              Input:{
                inputConnectorData,
                getDataFromValues
              },
              Condition:{},
              Response:{
                getDataFromResponse
              }
            },
          }),
        });
  
        if (response.ok) {
          // Handle success
          console.log('Connector details updated successfully');
          
          // Update state variables with the new connector details
          // This will trigger a re-render and reflect the changes on the UI
          onConnectorData({
            selectedEventIndex:selectedEventIndex,
            [selectedActionIndex]:{
              LabelofAction:actionLabel,
              isEncryptionRequired:isChecked,
              removeObjectFromArray:isCheckedForObject,
              Input:{
                inputConnectorData,
                getDataFromValues
              },
              Condition:{},
              Response:{
                getDataFromResponse
              }
            }
          });
          alert('Connector details updated successfully')
  
          // You can close the modal or perform any other actions here
        } else {
          // Handle error
          console.error('Error updating connector details');
          alert('Error updating connector details')
        }
      } catch (error) {
        console.error('Error updating connector details:', error);
      }
    };

    const handleActionLabelChange = (e) => {
      setActionLabel(e.target.value);
      setIsActionLabelFilled(e.target.value !== '');
    };

    useEffect(() => {
      
      setActionLabel(connectorDetails?.LabelofAction  || '')
      setIsChecked(connectorDetails?.isEncryptionRequired || false)
      setIsCheckedForObject(connectorDetails?.removeObjectFromArray || false)
    }, [connectorDetails?.LabelofAction, connectorDetails?.isEncryptionRequired, connectorDetails?.removeObjectFromArray])
    

  return (
    <Draggable>
      <div className="connector-component-popup">
        <div className="connector-component-popup-header">
          <div className="connector-component-options">
            <h6 className='connector-component-header-h4'>{actionLabel}</h6> 
            <h6 className='connector-component-header-h4'>{selectedName}</h6> 
            <h6 className='connector-component-header-h4'>{option}</h6>
            {/* <h6 className='connector-component-header-h4'>{option} : {selectedName}</h6> */}
          </div>
          <div className="connector-component-actions">
              <div className="connector-component-container-img" >
                  <img src={save} alt="save" className="connector-component-container-img-hover" onClick={handleSaveConnectorData}/>
              </div>
              <div className="connector-component-container-img" >
                  <img src={edit} alt="edit" className="connector-component-container-img-hover" onClick={handleUpdateClick}/>
              </div>
              <div className="connector-component-container-img" >
                  <img src={square_exit_white} alt="square_exit_white" className="connector-component-container-img-hover" onClick={handleClose} />
              </div>
          </div>
        </div>
        <div className="connector-component-tab-container">
            <div
              className={`connector-component-tab ${activeTab === 'Input' ? 'connector-component-active-tab' : ''}`}
              onClick={() => handleTabClick('Input')}
            >
              Input
            </div>
            <div
              className={`connector-component-tab ${activeTab === 'Condition' ? 'connector-component-active-tab' : ''}`}
              onClick={() => handleTabClick('Condition')}
            >
              Condition
            </div>
            <div
              className={`connector-component-tab ${activeTab === 'Response' ? 'connector-component-active-tab' : ''}`}
              onClick={() => handleTabClick('Response')}
            >
              Response
            </div>
          </div>
            <div className="connector-component-popup-body">
              <div className="row">
                <div className="col-md-12 cccls1">
                  <label className="col-md-5 cc-label1" htmlFor="Label of Action" >
                    Label of Action:
                  </label>
                  <input
                    className="cc-input1"
                    value={actionLabel}
                    onChange={handleActionLabelChange}                  
                  />              
                </div>
              </div>
                <div className="row">
                  <div className="col-md-12 cccls1">
                    <label className="col-md-8 cc-label1" htmlFor="Label of Action" >
                      Is Encryption Required
                    <input
                      className="cc-check1"
                      checked={isChecked} // Update the checkbox value based on the state
                      onChange={handleCheckboxChange}   
                      type='checkbox'              
                    />   
                    </label>           
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 cccls1">
                    <label className="col-md-8 cc-label1" htmlFor="Label of Action" >
                      Remove Object from Array
                    <input
                      className="cc-check1"
                      checked={isCheckedForObject} // Update the checkbox value based on the state
                      onChange={handleCheckboxChangeForObject}   
                      type='checkbox'              
                    />   
                    </label>           
                  </div>
                </div>
                {activeTab === 'Input' && (
                  <>
                    <SelectedComponent 
                      openGetDataPopup={openGetDataPopup} 
                      manualInputValue={manualInputValue} 
                      clickedInputField={clickedInputField} 
                      setManualInputValue={setManualInputValue}
                      onInputConnectorData={onInputConnectorData}
                      Input = {connectorDetails?.Input}
                      setApiCallName={setApiCallName}
                      setSelectedName={setSelectedName}
                      actionLabel={actionLabel}
                      actionStep= {actionStep && actionStep}
                      customEventText= {customEventText && customEventText}
                    />
                  </>
                )}
                {activeTab === 'Response' && (
                  <ResponseTab 
                    apiCallName={apiCallName}
                    onGetDataFromResponse={onGetDataFromResponse}
                    Response = {connectorDetails?.Response || {}}
                    actionStep = {actionStep && actionStep}
                    customEventText = {customEventText && customEventText}
                    actionLabel={actionLabel}
                  />
                )}
                {activeTab === 'Condition' && (
                  <div></div>
                )}
            </div>
            {showGetDataPopup && (
              <GetDataPopup 
                onClose={closeGetDataPopup} 
                onManualValueChange={handleManualValueChange} 
                clickedInputField={clickedInputField}
                onGetDataFrom={onGetDataFrom}
                Input = {connectorDetails?.Input || {}}
                actionLabel={actionLabel}
              />
            )}
      </div>
    </Draggable>
  )
}

export default ConnectorComponent