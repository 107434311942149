import React, { useState, useEffect, useContext } from 'react'
import Draggable from 'react-draggable';
import '../../Styles/Workflow/DynamicAction/GetDataPopup.css'
import square_exit_white from '../../../Assets/Images/square_exit_white.png';
import save from '../../../Assets/Images/save.png';
import axios from 'axios';
import ConditionComponent from './ConditionTabGetDataFrom/ConditionComponent';
import IdentifierOptions from './DropdownOptions/IdentifierOptions';
import DaysOptions from './DropdownOptions/DaysOptions';
import FormatOptions from './DropdownOptions/FormatOptions';
import plus from '../../../Assets/Images/plus.png';
import ConvertValuesOptions from './DropdownOptions/ConvertValuesOptions';
import GetDataFromDB from './GetDataFromDB/GetDataFromDB';
import {CopyContext} from '../../../Context/CopyPasteGetDataFrom';
import copy from '../../../Assets/Images/copy.png'; 
import paste from '../../../Assets/Images/paste.png'; 
import trash_black from '../../../Assets/Images/trash_black.png';

function GetDataPopup({ onClose, onManualValueChange, clickedInputField, onGetDataFrom,Input, actionLabel }) {

    const [getData, setGetData] = useState('');
    const [activeTab, setActiveTab] = useState('Input');
    const [manualInputValue, setManualInputValue] = useState('');
    const [getTriggerData, setGetTriggerData] = useState('');
    const [selectedKey, setSelectedKey] = useState('');
    const [conditionInput, setConditionInput] = useState('');
    const [resultOutput, setResultOutput] = useState('');
    const [finalOutput, setFinalOutput] = useState([]);
    const [conditionData, setConditionData] = useState({});
    const [generatedConditions, setGeneratedConditions] = useState([]);
    const [outputData, setOutputData] = useState({});

    const [internalStep, setInternalStep] = useState('');
    const [internalStepKey, setInternalStepKey] = useState('');
    const [internalDropdownOptions, setInternalDropdownOptions] = useState([]);

    const [manualIdentifier, setManualIdentifier] = useState('');
    const [manualSubCondition, setManualSubCondition] = useState('');
    const [manualSubConditionDays, setManualSubConditionDays] = useState('');
    const [manualSubConditionFormat, setManualSubConditionFormat] = useState('');

    const [convertConditions, setConvertConditions] = useState([]);
    const [getDataFromDB, setGetDataFromDB] = useState({});

    const [document, setDocument] = useState(null);

    const { copiedData, setCopiedData } = useContext(CopyContext);


    useEffect(() => {
      if(Input.getDataFromValues) {
        setGetData(Input?.getDataFromValues?.[clickedInputField]?.Input?.getData || '')
        setGetTriggerData(Input?.getDataFromValues?.[clickedInputField]?.Input?.getTriggerData || '')
        setSelectedKey(Input?.getDataFromValues?.[clickedInputField]?.Input?.selectedKey || '')
        setInternalStep(Input?.getDataFromValues?.[clickedInputField]?.Input?.internalStep || '')
        setInternalStepKey(Input?.getDataFromValues?.[clickedInputField]?.Input?.internalStepKey || '')
        setManualInputValue(Input?.getDataFromValues?.[clickedInputField]?.Input?.manualInputValue || '')
        setManualIdentifier(Input?.getDataFromValues?.[clickedInputField]?.Input?.manualIdentifier || '')
        setManualSubCondition(Input?.getDataFromValues?.[clickedInputField]?.Input?.manualSubCondition || '')
        setManualSubConditionDays(Input?.getDataFromValues?.[clickedInputField]?.Input?.manualSubConditionDays || '')
        setManualSubConditionFormat(Input?.getDataFromValues?.[clickedInputField]?.Input?.manualSubConditionFormat || '')
        setConvertConditions(Input?.getDataFromValues?.[clickedInputField]?.Input?.convertInputs || [])
        setGetDataFromDB(Input?.getDataFromValues?.[clickedInputField]?.Input?.getDataFromDB || {})
      }
    }, [Input.getDataFromValues])

  const [internalApiResponse, setInternalApiResponse] = useState(null);

  useEffect(() => {
    // Make a GET request to the internal-api endpoint
    axios.get('https://aim-core.insurancepolicy4us.com/internal-api')
      .then(response => {
        // Handle the response data
        setInternalApiResponse(response.data.externalData);
        // console.log("response.data.externalData:", response.data.externalData);
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching internal API:', error);
      });
  }, []);

  // console.log("internalApiResponse:", internalApiResponse);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const closeGetDataPopup = () => {
      onClose();
    };

    const handleResponseStringChange = (newResponseString) => {
      setFinalOutput(newResponseString);
    };  

    const handleSaveValue = () => {
      if (clickedInputField !== null) {
        let finalValue = '';
    
        if (getData === 'Trigger') {
            if (selectedKey) {
              finalValue = `${getData} : ${getTriggerData} : ${selectedKey}`;
            } 
            else if (internalStepKey) {
              finalValue = `${getData} : ${getTriggerData} : ${internalStep} ; ${internalStepKey}`
            } 
            else {
            console.error(`Invalid selectedKey: ${selectedKey}`);
          }
        } else if(getData === 'GetRandomNumber') {

          finalValue = `${getData}`;

        }else if(getData === 'GetUniqueNumber') {
         
          finalValue = `${getData}`;

        }else if(getData === 'AlphanumericUniqueIdentifier') {
         
          finalValue = `${getData}`;

        }
else if(getData === 'Manual') {
          // finalValue = manualInputValue;
          if (manualIdentifier === 'Date'){
            finalValue = `${getData} : ${manualIdentifier} : ${manualSubCondition}${manualSubConditionDays} : ${manualSubConditionFormat}`;
          } else if (manualIdentifier === 'Text'){
            finalValue = `${getData} : ${manualIdentifier} : ${manualInputValue}`;
          }
          else {
            finalValue = manualInputValue;
          }
        } else if(getData === 'MongoDB') {
          finalValue = `${getData}`         
        }

        if (getDataFromDB?.selectedMongoDbName !== undefined && getDataFromDB?.selectedMongoDbName !== null) {
          finalValue += ` : ${getDataFromDB.selectedMongoDbName} : ${getDataFromDB.selectedDbName} : ${getDataFromDB.selectedCollectionName} ; ${getDataFromDB.selectedDocIndexKey} ; ${getDataFromDB.selectedDocIndexValue} ; ${getDataFromDB.selectedValueFromResultKey}`;
        }      

        // Append selectedResults from conditionData array
        conditionData?.conditions?.forEach(condition => {
          finalValue += ` : ${condition.selectedResult}`;
        });

        conditionData?.modifyBooleanConditions?.forEach((condition, index, array) => {
          finalValue += ` ${condition.selectedModifyOption}=${condition.selectedModifyText}`;
          if (index < array.length - 1) {
            finalValue += " /";
          }
        }); 

        conditionData?.modifyTextConditions?.forEach((condition, index, array) => {
          finalValue += ` ${condition.selectedTextModifyBeforeText}=${condition.selectedTextModifyAfterText}`;
          if (index < array.length - 1) {
            finalValue += " /";
          }
        });

        convertConditions?.forEach(condition => {
          if (condition.selectedConvertIdentifier === "Date") {
            finalValue += ` : ${condition.selectedConvertValue} | ${condition.selectedConvertFormat}`;
          } else if (condition.selectedConvertIdentifier === "VehicleNumber") {

            finalValue += `: ${condition.selectedConvertIdentifier} | ${condition.selectedConvertVehicleFormat}`;
          }else if (condition.selectedConvertIdentifier === "Currency") {

            finalValue += `: ${condition.selectedConvertIdentifier} | ${condition.selectedConvertCurrencyFormat}`;
          }
        });        

        // console.log("conditionData:", conditionData);
        // console.log("finalValue:", finalValue);
        // Call the parent's callback with the finalValue
        onManualValueChange(finalValue);
        onClose();
      }
    };

    useEffect(() => {
      let finalValue = '';
    
        if (getData === 'Trigger') {
          if (selectedKey) {
            finalValue = `${getData} : ${getTriggerData} : ${selectedKey}`;
          }
        }else if(getData === 'GetRandomNumber') {
          
          finalValue = `${getData}`;

        }else if(getData === 'GetUniqueNumber') {
         
          finalValue = `${getData}`;

        }else if(getData === 'AlphanumericUniqueIdentifier') {
         
          finalValue = `${getData}`;

        }

        else if(getData === 'Manual') {
          // finalValue = manualInputValue;
          if (manualIdentifier === 'Date'){
            finalValue = `${getData} : ${manualIdentifier} : ${manualSubCondition}${manualSubConditionDays} : ${manualSubConditionFormat}`;
          }
        } else if(getData === 'MongoDB'){
          finalValue = `${getData}`
          if (getDataFromDB && getDataFromDB.selectedMongoDbName) {
            finalValue += ` : ${getDataFromDB.selectedMongoDbName} : ${getDataFromDB.selectedDbName} : ${getDataFromDB.selectedCollectionName} ; ${getDataFromDB.selectedDocIndexKey} ; ${getDataFromDB.selectedDocIndexValue} ; ${getDataFromDB.selectedValueFromResultKey}`;
          }  
        }
        setConditionInput(finalValue)
    }, [
      getData, 
      getTriggerData, 
      selectedKey,
      manualIdentifier,
      manualSubCondition,
      manualSubConditionDays,
      manualSubConditionFormat,
      getDataFromDB
    ])



    const onConditionData = (dataObject) => {
      setConditionData((prevDataObject) => {
        const mergedDataObject = { ...prevDataObject, ...dataObject };
        return mergedDataObject;
      })
    }

    const onGetDataFromDB = (dataObject) => {
      setGetDataFromDB((prevDataObject) => {
        const mergedDataObject = { ...prevDataObject, ...dataObject };
        return mergedDataObject
      })
    }

    // console.log("conditionData:", conditionData);
    useEffect(() => {
      if (Object.keys(conditionData).length !== 0 && conditionData.conditions && conditionData.conditions.length > 0) {
        const selectedResults = conditionData.conditions.map(condition => condition.selectedResult);
        setFinalOutput(selectedResults); // Set final output to the array of selectedResults
      }
    }, [conditionData]);

        
    useEffect(() => {
      let finalValue = '';
    
        if (getData === 'Trigger') {
          if (selectedKey) {
            finalValue = `${getData} : ${getTriggerData} : ${selectedKey}`;
          } 
          // else if (selectedKey && finalOutput) {
          //   finalValue = `${getData} : ${getTriggerData} : ${selectedKey} ; ${finalOutput}`
          // }
        } else if(getData === 'Manual') {
          // finalValue = manualInputValue;
          if (manualIdentifier === 'Date'){
            finalValue = `${getData} : ${manualIdentifier} : ${manualSubCondition}${manualSubConditionDays} : ${manualSubConditionFormat}`;
          } else {
            finalValue = manualInputValue;
          }
        }else if(getData === 'MongoDB') {
          // finalValue = `${getData}`
          finalValue = `${getData} : ${getDataFromDB.selectedMongoDbName}`;
        }

        // Append selectedResults from conditionData array
        conditionData?.conditions?.forEach(condition => {
          finalValue += ` : ${condition.selectedResult}`;
        });

        conditionData?.modifyConditions?.forEach(condition => {
          finalValue += ` : ${condition.selectedModifyOption}=${condition.selectedModifyText}`;
        });

        conditionData?.modifyTextConditions?.forEach(condition => {
          finalValue += ` : ${condition.selectedTextModifyBeforeText}=${condition.selectedTextModifyAfterText}`;
        });

        setResultOutput(finalValue)
    }, [
      getData, 
      getTriggerData, 
      selectedKey, 
      manualInputValue, 
      manualIdentifier,
      manualSubCondition,
      manualSubConditionDays,
      manualSubConditionFormat,
      getDataFromDB,
      conditionData.conditions,
      conditionData.modifyConditions,
      conditionData.modifyTextConditions
    ])
    

    useEffect(() => {
      const generateDataObject = async () => {
        const dataObject = {
          // [clickedInputField]:{
            Input: (() => {
              if (getData === 'Trigger') {
                return {
                  getData: getData,
                  getTriggerData: getTriggerData,
                  selectedKey: selectedKey,
                  internalStep: internalStep,
                  internalStepKey: internalStepKey,
                  convertInputs:convertConditions
                };
              } else if (getData === 'Manual' && manualIdentifier !== 'Date') {
                return {
                  getData: getData,
                  manualIdentifier: manualIdentifier,
                  manualInputValue: manualInputValue
                };
              } else if (getData === 'Manual' && manualIdentifier === 'Date') {
                return {
                  getData: getData,
                  manualIdentifier: manualIdentifier,
                  manualSubCondition: manualSubCondition,
                  manualSubConditionDays: manualSubConditionDays,
                  manualSubConditionFormat: manualSubConditionFormat
                };
              } else if (getData === 'MongoDB'){
                return{
                  getData:getData,
                  getDataFromDB:getDataFromDB
                }
              }else {
                // Handle other cases if needed
                return {};
              }
            })(),
            Condition: conditionData,
          // }
        }
        // console.log("dataObjectFromGetData:", dataObject);
        // onGetDataFrom(dataObject)
        onGetDataFrom(clickedInputField,dataObject)
      }
      generateDataObject()
    },[
      getData,
      getTriggerData,
      selectedKey,
      internalStep,
      internalStepKey,
      manualInputValue,
      manualIdentifier,
      manualSubCondition,
      manualSubConditionDays,
      manualSubConditionFormat,
      conditionData,
      convertConditions,
      getDataFromDB,
      clickedInputField,
      onGetDataFrom
    ])

    const handleCopy = () => {
      const dataObject = {
        getData: getData,
        getTriggerData: getTriggerData,
        selectedKey: selectedKey,
        internalStep: internalStep,
        internalStepKey: internalStepKey,
        convertInputs:convertConditions,
        manualIdentifier: manualIdentifier,
        manualInputValue: manualInputValue,
        manualSubCondition: manualSubCondition,
        manualSubConditionDays: manualSubConditionDays,
        manualSubConditionFormat: manualSubConditionFormat,
        getDataFromDB:getDataFromDB,
        conditionData: conditionData
      };
      setCopiedData(dataObject);
    };

    const handlePaste = () => {
      if (copiedData) {
        setGetData(copiedData.getData);
        setGetTriggerData(copiedData.getTriggerData);
        setSelectedKey(copiedData.selectedKey);
        setInternalStep(copiedData.internalStep);
        setInternalStepKey(copiedData.internalStepKey);
        setConvertConditions(copiedData.convertInputs);
        setManualIdentifier(copiedData.manualIdentifier);
        setManualInputValue(copiedData.manualInputValue);
        setManualSubCondition(copiedData.manualSubCondition);
        setManualSubConditionDays(copiedData.manualSubConditionDays);
        setManualSubConditionFormat(copiedData.manualSubConditionFormat);
        setGetDataFromDB(copiedData.getDataFromDB);
        setConditionData(copiedData.conditionData);
      }
    };

    let conditionDBResponse
    if (Input?.getDataFromValues) {
      conditionDBResponse = Input?.getDataFromValues?.[clickedInputField]?.Condition
    } else {
      conditionDBResponse = {}
    }

    const handleGeneratedConditionsChange = (conditions) => {
      setGeneratedConditions(conditions);
    };


    const handleConvertConditionButtonClick = () => {
      // Check if convertConditions array is empty
      if (convertConditions.length === 0) {
        console.log("hiii.......");
        const newCondition = {
          selectedConvertIdentifier: '',
          selectedConvertValue: '',
          selectedConvertFormat: '',
          selectedConvertVehicleFormat: '',
          selectedConvertCurrencyFormat: ''
        };
    
        setConvertConditions([newCondition]); // Set the state with a new condition
      }
    };

    useEffect(() => {
      const fetchDocument = async () => {
          try {
              const response = await axios.post('https://aim-core.insurancepolicy4us.com/get-document', { actionLabel });
              // console.log("full Res Document from DB...", response);
              // console.log("Document from DB...", response.data);
              setDocument(response.data);

              // Generate dropdown options
              const options = response.data.map((item) => {
                return `${item.step}-${item.connectorDetails.LabelofAction}`;
              });
              setInternalDropdownOptions(options);
          } catch (error) {
              console.error('Error fetching the document:', error);
          }
      };

      fetchDocument();
    }, [actionLabel]);

    
  return (
    <Draggable>
        <div className="get-data-popup">
            <div className="get-data-popup-header">
                <h4 className='get-data-header-h4'>Get data from</h4>
                <div className="get-data-container-img" >
                    <img src={save} alt="save" className="get-data-container-img-hover" onClick={handleSaveValue}/>
                </div>
                <div className="get-data-container-img">
                    <img src={copy} alt="copy" className="get-data-container-img-hover" onClick={handleCopy} />
                </div>
                <div className="get-data-container-img">
                    <img src={paste} alt="copy" className="get-data-container-img-hover" onClick={handlePaste} />
                </div>
                <div className="get-data-container-img" >
                    <img src={square_exit_white} alt="square_exit_white" className="get-data-container-img-hover" onClick={closeGetDataPopup}/>
                </div>
            </div>
            <div className="get-data-tab-container">
              <div
                className={`get-data-tab ${activeTab === 'Input' ? 'get-data-active-tab' : ''}`}
                onClick={() => handleTabClick('Input')}
              >
                Input
              </div>
              <div
                className={`get-data-tab ${activeTab === 'Condition' ? 'get-data-active-tab' : ''}`}
                onClick={() => handleTabClick('Condition')}
              >
                Condition
              </div>
            </div>
            <div className="get-data-popup-body">
            {activeTab === 'Input' && (
                <div>
                    <div className="row">
                        <div className="col-md-12 gdcls1">
                            <label className="col-md-4 gd-label1" htmlFor="to" >
                            Get data from:
                            </label>
                            <select
                            className="gd-select1"
                            value={getData}
                            onChange={(e) => setGetData(e.target.value)}
                            >
                            <option value="" disabled>Select a option</option>
                            <option value="Trigger">Trigger</option> 
                            <option value="Event">Event</option> 
                            <option value="Manual">Manual</option>                
                            <option value="MongoDB">MongoDB</option>                
                            <option value="GetRandomNumber">Get Random Number</option>   
                            
                            <option value="GetUniqueNumber">Get Unique Number</option>                
                            <option value="AlphanumericUniqueIdentifier">Alphanumeric Unique Identifier</option>                
                            </select>      
                        </div>
                    </div>
                    {getData === 'Trigger' && (
                        <div>
                          <div className="row">
                              <div className="col-md-12 gdcls1">
                                <label className="col-md-4 gd-label2">
                                </label>
                                <select
                                  className="gd-select1"
                                  value={getTriggerData}
                                  onChange={(e) => setGetTriggerData(e.target.value)}
                                >
                                  <option value="" disabled>Select a option</option>
                                  <option value="Internal">Internal</option> 
                                  <option value="External">External</option> 
                                </select>      
                              </div>
                          </div>
                        </div>
                    )}
                    {getData === 'Trigger' && getTriggerData === "Internal" && (
                      <div>
                        <div className="row">
                            <div className="col-md-12 gdcls1">
                                <label className="col-md-4 gd-label1" htmlFor="to" >
                                
                                </label>
                                <select
                                  className="gd-select1"
                                  value={internalStep}
                                  onChange={(e) => setInternalStep(e.target.value)}    
                                >
                                {/* <option value="Step 1-RS motor(car) proposal step 1">Step 1 - RS motor(car) proposal step 1</option>                  */}
                                  <option value="" disabled>Select an option</option>
                                  {internalDropdownOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>      
                            </div>
                        </div>
                        {getData === 'Trigger' && getTriggerData === "Internal" && internalStep !== "" && (
                          <div className="row">
                          <div className="col-md-12 gdcls2">
                              <label className="col-md-4 gd-label2">
                              </label>
                              <input
                                type="text"
                                id="filter"
                                className="gd-input1"
                                placeholder='Enter key name here...'
                                value={internalStepKey}
                                onChange={(e) => setInternalStepKey(e.target.value)}    
                              />  
                          </div>
                          </div>
                        )}
                      </div>
                    )}
                    {getData === 'Trigger' && getTriggerData === "External" && (
                      <div>
                        <div className="row">
                          <div className="col-md-12 gdcls1">
                            <label className="col-md-4 gd-label4">
                            </label>
                            <input
                              className="gd-select1"
                              value={selectedKey}
                              onChange={(e) => setSelectedKey(e.target.value)}
                              placeholder='Enter key here...'
                            />
                            
                          </div>
                        </div>
                        {convertConditions.map((condition, index) => (
                          <React.Fragment key={index}>
                          <div key={index} className="row"> 
                            <div className="col-md-12 gdcls1">
                              <label className="col-md-4 gd-cc-label2">
                                Identifier
                              </label>
                              <select
                                value={condition.selectedConvertIdentifier}
                                onChange={(e) => {
                                  const newConditions = [...convertConditions];
                                  newConditions[index].selectedConvertIdentifier = e.target.value;
                                  setConvertConditions(newConditions);
                                }} 
                                className="gd-select1"
 
                              >
                                <option value="" disabled>Select an identifier</option>
                                <option value="Date">Date</option>
                                <option value="VehicleNumber">Vehicle Number</option>
                                <option value="Currency">Currency</option>
                              </select>
                            </div>
                          </div>
                          {condition.selectedConvertIdentifier === 'Date' && (
                            <>
                              <div key={`date-value-${index}`} className="row"> 
                                <div className="col-md-12 gdcls1">
                                  <label className="col-md-4 gd-cc-label2">
                                    Value
                                  </label>
                                  <select
                                    value={condition.selectedConvertValue}
                                    onChange={(e) => {
                                      const newConditions = [...convertConditions];
                                      newConditions[index].selectedConvertValue = e.target.value;
                                      setConvertConditions(newConditions);
                                    }}
                                    className="gd-select1"
                                  >
                                    <ConvertValuesOptions
                                      selectedConvertIdentifier = {condition.selectedConvertIdentifier}
                                    />
                                  </select>
                                </div>
                              </div>
                              <div key={`date-format-${index}`} className="row"> 
                              <div className="col-md-12 gdcls1">
                                <label className="col-md-4 gd-cc-label2">
                                  Format
                                </label>
                                <select
                                  value={condition.selectedConvertFormat}
                                  onChange={(e) => {
                                    const newConditions = [...convertConditions];
                                    newConditions[index].selectedConvertFormat = e.target.value;
                                    setConvertConditions(newConditions);
                                  }}  
                                  className="gd-select1"

                                >
                                  <FormatOptions
                                    selectedConvertIdentifier = {condition.selectedConvertIdentifier}
                                  />
                                </select>
                              </div>
                              </div>
                            </>
                          )}
                          {condition.selectedConvertIdentifier === 'VehicleNumber' && (
                            <>
                              <div key={`vehicle-format-${index}`} className="row"> 
                              <div className="col-md-12 gdcls1">
                                <label className="col-md-4 gd-cc-label2">
                                  Format
                                </label>
                                <select
                                  value={condition.selectedConvertVehicleFormat}
                                  onChange={(e) => {
                                    const newConditions = [...convertConditions];
                                    newConditions[index].selectedConvertVehicleFormat = e.target.value;
                                    setConvertConditions(newConditions);
                                  }}  
                                  className="gd-select1"

                                >
                                  <FormatOptions
                                    selectedConvertIdentifier = {condition.selectedConvertIdentifier}
                                  />
                                </select>
                              </div>
                              </div>
                            </>
                          )}
                          {condition.selectedConvertIdentifier === 'Currency' && (
                            <>
                              <div key={`currency-format-${index}`} className="row"> 
                              <div className="col-md-12 gdcls1">
                                <label className="col-md-4 gd-cc-label2">
                                  Format
                                </label>
                                <select
                                  value={condition.selectedConvertCurrencyFormat}
                                  onChange={(e) => {
                                    const newConditions = [...convertConditions];
                                    newConditions[index].selectedConvertCurrencyFormat = e.target.value;
                                    setConvertConditions(newConditions);
                                  }}  
                                  className="gd-select1"

                                >
                                  <FormatOptions
                                    selectedConvertIdentifier = {condition.selectedConvertIdentifier}
                                  />
                                </select>
                              </div>
                              </div>
                            </>
                          )}
                          </React.Fragment>
                        ))}
                        <div className="row">
                          <div className="col-md-12 gdcls4">
                            <button className='col-md-12 gd-format-btn' onClick={handleConvertConditionButtonClick}>
                              {/* <img src='plus' alt='plus'/> */}
                              <span className="gd-text">Convert Input</span>
                            </button>
                          </div>
                        </div>
                    </div>
                    )}
                    {getData === 'Event' && (
                        <div></div>
                    )}
                    {getData === 'Manual' && (
                      <>
                        <div>
                        <div className="row">
                          <div className="col-md-12 gdcls2">
                              <label className="col-md-4 gd-label2">
                              </label>
                              <select
                                className="gd-select1"
                                value={manualIdentifier}
                                onChange={(e)=> setManualIdentifier(e.target.value)}
                              >
                                <IdentifierOptions/>
                              </select> 
                          </div>
                        </div>
                        </div>
                        {manualIdentifier === 'Date' && (
                          <div>
                          <div className="row">
                            <div className="col-md-12 gdcls2">
                                <label className="col-md-4 gd-label2">
                                </label>
                                <select
                                  className="gd-select1"
                                  value={manualSubCondition}
                                  onChange={(e)=> setManualSubCondition(e.target.value)}
                                >
                                  <option value="" disabled>Select a sub condition</option>
                                  <option value="CurrentDate">Current Date</option> 
                                </select> 
                            </div>
                          </div>
                          </div>
                        )}
                        {manualSubCondition === 'CurrentDate' && manualIdentifier === "Date" &&(
                          <div>
                          <div className="row">
                            <div className="col-md-12 gdcls2">
                                <label className="col-md-4 gd-label2">
                                </label>
                                <select
                                  className="gd-select1"
                                  value={manualSubConditionDays}
                                  onChange={(e)=> setManualSubConditionDays(e.target.value)}
                                >
                                  <DaysOptions/>
                                </select> 
                            </div>
                          </div>
                          </div>
                        )}
                        { manualSubConditionDays && manualIdentifier === "Date" &&(
                          <div>
                          <div className="row">
                            <div className="col-md-12 gdcls2">
                                <label className="col-md-4 gd-label2">
                                </label>
                                <select
                                  className="gd-select1"
                                  value={manualSubConditionFormat}
                                  onChange={(e)=> setManualSubConditionFormat(e.target.value)}
                                >
                                  <FormatOptions                                     
                                    selectedConvertIdentifier = {manualIdentifier}
                                  />
                                </select> 
                            </div>
                          </div>
                          </div>
                        )}
                        { manualIdentifier !== "Date" && manualIdentifier === "Text" &&(
                          <div>
                            <div className="row">
                              <div className="col-md-12 gdcls2">
                                  <label className="col-md-4 gd-label2">
                                  </label>
                                  <input
                                    type="text"
                                    id="filter"
                                    className="gd-input1"
                                    placeholder='Enter value here...'
                                    value={manualInputValue}
                                    onChange={(e) => setManualInputValue(e.target.value)}
                                  />  
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {getData === 'MongoDB' && (
                      <GetDataFromDB 
                        onGetDataFromDB={onGetDataFromDB}
                        getDataFromMongoDB={getDataFromDB}
                      />
                    )}
                    
                </div>
            )}
            {activeTab === 'Condition' && (
                <ConditionComponent 
                  conditionInput={conditionInput} 
                  setConditionInput={setConditionInput} 
                  onConditionData={onConditionData}
                  onGeneratedConditionsChange={handleGeneratedConditionsChange}
                  conditionDBResponse = {conditionDBResponse || {} }
                  conditionData={conditionData} 
                />
            )}

            </div>
        </div>
    </Draggable>
  )
}

export default GetDataPopup