import PrivateKeyinURL from './PrivateKeyinURL';
import PrivateKeyinHeader from './PrivateKeyinHeader';
import HTTPBasicAuth from './HTTPBasicAuth';
import OAuth2PasswordFlow from './OAuth2PasswordFlow';
import OAuth2UserAgentFlow from './OAuth2UserAgentFlow';
import OAuth2CustomToken from './OAuth2CustomToken';
import JSONWebToken from './JSONWebToken';
import ClientSideSSLCertificate from './ClientSideSSLCertificate';

const AuthComponents = {
    PrivateKeyinURL,
    PrivateKeyinHeader,
    HTTPBasicAuth,
    OAuth2PasswordFlow,
    OAuth2UserAgentFlow,
    OAuth2CustomToken,
    JSONWebToken,
    ClientSideSSLCertificate
};

export default AuthComponents;
