import React, { useState, useEffect } from 'react';
import '../../Styles/Workflow/CustomEventResponse/CustomEventResponse.css';

function CustomEventResponse({selectedEventIndex, dynamicDbData, onDataFromCustomEventResponse}) {
    
    const [responseCheckBox, setResponseCheckBox] = useState(false)

    // Filter the object from dynamicDbData where selectedIndex matches selectedEventIndex
    const selectedObject = dynamicDbData.find(data => data.selectedIndex === selectedEventIndex);
    // console.log("selectedObject:", selectedObject);

    useEffect(() => {
        if (dynamicDbData) {
            const selectedObject = dynamicDbData.find(data => data.selectedIndex === selectedEventIndex);
            // console.log("selectedObject", selectedObject);
            if (selectedObject) {
                const response = selectedObject.Response.getDataFromCustomEventResponse;
                // Check if response is an object
                if (typeof response === 'object' && response !== null) {
                    const checkBoxes = Object.values(response).map(data => data.checkBox);
                    setResponseCheckBox(checkBoxes);
                } else {
                    console.error("getDataFromCustomEventResponse is not returning an object");
                }
            }
        }
    }, [dynamicDbData]);
      

    useEffect(() => {
        if (!selectedObject) {
            console.log('No matching object found for selectedEventIndex:', selectedEventIndex);
            return; // Exit early if no selectedObject
        }

        const { dynamic_actions } = selectedObject;

        const generateDataObject = async () => {
            const dataObject = {};
            dynamic_actions.forEach((action, index) => {
                dataObject[index] = {
                    step: action?.step,
                    action: action?.action,
                    labelOfAction: action?.connectorDetails?.LabelofAction,
                    checkBox: responseCheckBox
                };
            });
            // console.log("Data Object:", dataObject);
            onDataFromCustomEventResponse(dataObject)
        }
        generateDataObject();
    }, [
        selectedObject, 
        responseCheckBox,
        onDataFromCustomEventResponse
    ]); 


return (
    <div>
       {selectedObject && selectedObject.dynamic_actions.map((action, index) => (
            <div className="row" key={index}>
                <div className="col-md-12 cercls1">
                    <label className="col-md-6 cer-label" >
                        <span>{action?.step}</span>
                        <span>{action?.action}</span>
                    </label>
                    <input
                        className="cer-input"
                        type='text'
                        placeholder='StepLabel'
                        value={action?.connectorDetails?.LabelofAction}
                        disabled={responseCheckBox}
                    />
                    <input
                        className="cer-checkbox"
                        type='checkbox'
                        checked={responseCheckBox}
                        onChange={(e) => setResponseCheckBox(e.target.checked)}
                    />
                </div>
            </div>
        ))}
    </div>
  )
}

export default CustomEventResponse