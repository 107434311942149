import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import '../../../Styles/Workflow/DynamicAction/GetDataFromDB/GetDataFromDB.css';
import GetDataForPopup from './GetDataForPopup/GetDataForPopup';
import { useConnectorContext } from '../../../../Context/ConnectorContext';

function GetDataFromDB({onGetDataFromDB, getDataFromMongoDB}) {

    console.log("getDataFromMongoDB", getDataFromMongoDB);
    
    const [ selectedMongoDbName, setSelectedMongoDbName] = useState(getDataFromMongoDB?.selectedMongoDbName || "")
    const [ selectedDbName, setSelectedDbName] = useState(getDataFromMongoDB?.selectedDbName || "")
    const [ selectedCollectionName, setSelectedCollectionName] = useState(getDataFromMongoDB?.selectedCollectionName || "")
    const [ selectedDocIndexKey, setSelectedDocIndexKey] = useState(getDataFromMongoDB?.selectedDocIndexKey || "")
    const [ selectedDocIndexValue, setSelectedDocIndexValue] = useState(getDataFromMongoDB?.selectedDocIndexValue || "")
    const [ selectedValueFromResultKey, setValueFromResultKey] = useState(getDataFromMongoDB?.selectedValueFromResultKey || "")
    const [ showGetDataForPopup, setShowGetDataForPopup] = useState(false);
    const [ databaseNames, setDatabaseNames] = useState([]);
    const [ mongoUri, setMongoUri] = useState('')
    const [ collectionNames,setCollectionNames] = useState([]);
    const [ clickedInputId, setClickedInputId] = useState('')
    const [ dataFromComponents, setDataFromComponents] = useState(getDataFromMongoDB?.dataFromComponents || {});


    
    const popupRef = useRef(null);

      const openGetDataForPopup = (inputFieldId) => {
        setShowGetDataForPopup(true);
        setClickedInputId(inputFieldId)
      };    
    
      const closeGetDataForPopup = () => {
        setShowGetDataForPopup(false);
      };

      const { dataFromContext } = useConnectorContext();

      let installedMongoDBConnectorData;
      const mongoDBConnectorObject = dataFromContext && dataFromContext.find(item => item.Connector_Name === 'MongoDB Connector');
      if (mongoDBConnectorObject) {
          installedMongoDBConnectorData = mongoDBConnectorObject.Connector_Values;
        //   console.log("installedMongoDBConnectorData:", installedMongoDBConnectorData);
      }

      const dynamicDbNames = installedMongoDBConnectorData && installedMongoDBConnectorData.filter(obj => obj.dbName.dynamic === true)
                                                      .map(obj => obj.dbName.dbName);

    const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedMongoDbName(selectedValue);

    // Find the selected MongoDB object
    const selectedMongoDBObject = installedMongoDBConnectorData.find(obj => obj.dbName.dbName === selectedValue);

        // Check if the selected MongoDB object exists and if dynamic properties are true
        if (selectedMongoDBObject && selectedMongoDBObject.mongoUri.dynamic && selectedMongoDBObject.passWord.dynamic) {
            // Extract mongoUri and passWord
            const mongoUri = selectedMongoDBObject.mongoUri.mongoUri;
            const passWord = selectedMongoDBObject.passWord.passWord;

            // Make a POST request to the backend
            axios.post('https://aim-core.insurancepolicy4us.com/get-mongodb', { mongoUri, passWord })
            .then(response => {
            // Extract database names from the response and store them in state
            setDatabaseNames(response.data.databases);
            setMongoUri(response.data.mongoUri)
            })
            .catch(error => {
                // Handle errors if any
                console.error('Error sending data to backend:', error);
            });
        }
    };

    const handleSelectDbChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedDbName(selectedValue)

        if(mongoUri && selectedValue){
            axios.post('https://aim-core.insurancepolicy4us.com/get-collections', { mongoUri, selectedValue })
            .then(response => {
                // Extract collection names from the response and store them in state
                setCollectionNames(response.data.collections);
            })
            .catch(error => {
                // Handle errors if any
                console.error('Error fetching collection names:', error);
            });
        }
    }

    const handleInputClick = (e) => {
        if (popupRef.current && popupRef.current.contains(e.target)) {
            // Clicked inside the popup, do nothing
            return;
        }
        const focusedElement = document.activeElement;
        if (focusedElement.tagName.toLowerCase() === 'input') {
            const inputFieldId = focusedElement.id;
            openGetDataForPopup(inputFieldId);
        }
    }

    const stateSetterMap = {
        'doc-index-key': setSelectedDocIndexKey, 
        'doc-index-value': setSelectedDocIndexValue,
        'value-from-result-key': setValueFromResultKey

    };
    
    const onDataForDBConnector = (finalValueState) => {
        for (const key in finalValueState) {
            const inputField = document.getElementById(key);
            if (inputField && stateSetterMap[key]) { // Check if there's a corresponding state setter function
                stateSetterMap[key](finalValueState[key]); // Call the state setter function with the corresponding value
            }
        }
    }


    const dataFromDBComponents = (dataObject) => {
        setDataFromComponents((prevDataObject) => {
          const mergedDataObject = { ...prevDataObject, ...dataObject };
          return mergedDataObject
        })
      }

    useEffect(() => {
        const generateDataObject = async () => {
            const dataObject ={
                dataFromComponents:dataFromComponents,
                selectedMongoDbName:selectedMongoDbName,
                selectedDbName:selectedDbName,
                selectedCollectionName:selectedCollectionName,
                selectedDocIndexKey:selectedDocIndexKey,
                selectedDocIndexValue:selectedDocIndexValue,
                selectedValueFromResultKey:selectedValueFromResultKey,
            }
            onGetDataFromDB(dataObject)
        }

        generateDataObject()
      
    }, [
        dataFromComponents,
        selectedMongoDbName,
        selectedDbName,
        selectedCollectionName,
        selectedDocIndexKey,
        selectedDocIndexValue,
        selectedValueFromResultKey,
        onGetDataFromDB
    ])

  return (
    <div onClick={handleInputClick}>
        <div className="row">
            <div className="col-md-12 gddbcls1">
                <label className="col-md-4 gddb-label1" htmlFor="to" >
                    MongoDB Connector 
                </label>
                <select
                    className="gddb-select1"
                    value={selectedMongoDbName}
                    onChange={handleSelectChange}
                >
                    <option value="" disabled>Select a option</option>             
                    {dynamicDbNames && dynamicDbNames.map((dbName, index) => (
                    <option key={index} value={dbName}>{dbName}</option>
                    ))}                                
                </select>      
            </div>
        </div>
        {databaseNames.length > 0 && (
            <div className="row">
                <div className="col-md-12 gddbcls1">
                    <label className="col-md-4 gddb-label1" htmlFor="to" >
                        Database Name
                    </label>
                    <select
                    className="gddb-select1"
                    value={selectedDbName}
                    onChange={handleSelectDbChange}
                    >
                    <option value="" disabled>Select a option</option>             
                    {databaseNames && databaseNames.map((dbName, index) => (
                        <option key={index} value={dbName}>{dbName}</option>
                    ))}          
                    </select>      
                </div>
            </div>
        )}
        {selectedDbName && collectionNames.length > 0 && (
            <div className="row">
                <div className="col-md-12 gddbcls1">
                    <label className="col-md-4 gddb-label1" htmlFor="to">
                        Collection Name
                    </label>
                    <select
                        className="gddb-select1"
                        value={selectedCollectionName}
                        onChange={(e) => setSelectedCollectionName(e.target.value)}
                    >
                        <option value="" disabled>Select a option</option>
                        {collectionNames.map((collectionName, index) => (
                            <option key={index} value={collectionName}>{collectionName}</option>
                        ))}
                    </select>
                </div>
            </div>
        )}
        { selectedCollectionName && (
            <div className="row">
                <div className="col-md-12 gddbcls1">
                    <label className="col-md-4 gddb-label1" htmlFor="to" >
                        Document index key
                    </label>
                    <input
                        className="gddb-select1"
                        value={selectedDocIndexKey}
                        onChange={(e) => setSelectedDocIndexKey(e.target.value)}
                        type='text'
                        id='doc-index-key'
                    />    
                </div>
            </div>
        )}
        { selectedDocIndexKey && (
            <div className="row">
                <div className="col-md-12 gddbcls1">
                    <label className="col-md-4 gddb-label1" htmlFor="to" >
                        Document index value
                    </label>    
                    <input
                        className="gddb-select1"
                        value={selectedDocIndexValue}
                        onChange={(e) => setSelectedDocIndexValue(e.target.value)}
                        type='text'
                        id='doc-index-value'
                    /> 
                </div>
            </div>
        )}
        { selectedDocIndexValue && (
            <div className="row">
                <div className="col-md-12 gddbcls1">
                    <label className="col-md-4 gddb-label1" htmlFor="to" >
                        Value from result key
                    </label>  
                    <input
                        className="gddb-select1"
                        value={selectedValueFromResultKey}
                        onChange={(e) => setValueFromResultKey(e.target.value)}
                        type='text'
                        id='value-from-result-key'
                    />   
                </div>
            </div>
        )}
        {showGetDataForPopup && (
            <div ref={popupRef}>
            <GetDataForPopup
                onClose={closeGetDataForPopup}
                clickedInputId={clickedInputId}
                onDataForDBConnector={onDataForDBConnector}
                dataFromDBComponents={dataFromDBComponents}
                dataFromComponents={dataFromComponents}
            />
            </div>
        )}
    </div>
  )
}

export default GetDataFromDB