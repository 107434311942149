import React, { useContext, useEffect, useState } from "react";
// import "../../style/CreateProjectPage.css";
import "../Styles/CreateProject/CreateProjectPage.css"
// import { useDataTypeAndAppDataContext } from "../../context/DataTypeAndAppDataContext";
// import { useOptionSetsContext } from "../../context/OptionSetsContext";
import { useProjectsContext } from '../../Context/ProjectsContext';
import { AiTwotoneDelete, AiOutlineEdit } from "react-icons/ai";
import ProjectPopup from "./ProjectPopup";
// import { BASE_URL } from "../../../config";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../Context/AuthContext";



const CreateProjectPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { updateCollections, selectedType, fetchFields, fetchValues } =
   '';
  const { fetchAttributes, fetchOptions, updateOptionSets, selectedSet } =
    '';
  const { projects, setDatabase, updateProject } = useProjectsContext();
  const navigate = useNavigate();
  const { dispatch, token} =useContext(authContext)

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleCreateProject = async (projectName) => {
    try {
      setIsLoading(true);

      // Simulate a delay for the loader (e.g., 2 seconds)
      setTimeout(async () => {
        const response = await fetch(
          'https://aim-core.insurancepolicy4us.com/create-project',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ projectName }),
          }
        );

        if (response.ok) {
          updateProject();
          setDatabase(projectName);
          navigate(`/${projectName}/dynamicevent`);
          // updateCollections();
          // fetchFields(selectedType);
          // fetchValues(selectedType);
          // updateOptionSets();
          // if (selectedSet !== "") {
          //   fetchAttributes(selectedSet);
          //   fetchOptions(selectedSet);
          // }
          console.log(`Project: ${projectName} created successfully`);
        } else {
          console.error("Error creating project:", response.statusText);
        }

        setIsLoading(false);
      }, 2000); // Set the time you want the loader to be displayed
    } catch (error) {
      console.error("Error creating project:", error);
      setIsLoading(false);
    }
  };

  const handleClick = async (project) => {
    try {
      setIsLoading(true);

      // Simulate a delay for the loader (e.g., 2 seconds)
      setTimeout(async () => {
        const response = await fetch(
          'https://aim-core.insurancepolicy4us.com/connect-db',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authorization:`Bearer ${token}`
            },
            body: JSON.stringify({ projectName: project }),
          }
        );

        if (response.ok) {
          setDatabase(project);
          navigate(`/${project}/dynamicevent`);
          // updateCollections();
          // fetchFields(selectedType);
          // fetchValues(selectedType);
          // updateOptionSets();
          // if (selectedSet !== "") {
          //   fetchAttributes(selectedSet);
          //   fetchOptions(selectedSet);
          // }
          console.log(` ${project} connected successfully`);
        } else {
          console.error("Error connecting project:", response.statusText);
        }

        setIsLoading(false);
      }, 2000); // Set the time you want the loader to be displayed
    } catch (error) {
      console.error("Error connecting project:", error);
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    dispatch({type: "LOGOUT" })
    // navigate("/login");
    console.log("logout successfully");
  }

  const handleDeleteProject = async (projectName) => {
    try {
      const shouldDelete = window.confirm(
        `Are you sure you want to delete the project: ${projectName}?`
      );

      if (!shouldDelete) {
        return;
      }

      setDeleteLoading(true);

      const response = await fetch(
        'https://aim-core.insurancepolicy4us.com/delete-project',
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:`Bearer ${token}`
          },
          body: JSON.stringify({ projectName }),
        }
      );

      if (response.ok) {
        updateProject();
        console.log(`Project: ${projectName} deleted successfully`);
      } else {
        // Handle error
        console.error("Error deleting project:", response.statusText);
      }

      setDeleteLoading(false);
    } catch (error) {
      console.error("Error deleting project:", error);
      setDeleteLoading(false);
    }
  };

  const filteredProjects = projects.filter((project) =>
    project.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const displayProjects = searchTerm ? filteredProjects : projects;

  return (
    <div className="create-prjt-container">
      <div className="divForButton">
        <button className="create-project-btn" onClick={openPopup}>
          Create Project
        </button>
        <button className="logout-project-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="search-container">
        <label htmlFor="searchProject" className="search-text">
          Search
        </label>
        <input
          id="searchProject"
          type="text"
          className="search-input"
          placeholder=" Project Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="TextPDiv">
        <p className="cc">Created Projects</p>
      </div>
      <div className="box-container">
        {displayProjects.map((project, index) => (
          <div key={index} className="box" onClick={() => handleClick(project)}>
            <div>
              <div className="boxText">{project}</div>
            </div>
            <div className="BoxIcon">
              <AiTwotoneDelete
                size={22}
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation
                  handleDeleteProject(project);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="fullScreen">
          <span className="loader"></span>
        </div>
      )}
      {deleteLoading && (
        <div className="fullScreen">
          <span className="deleteloader"></span>
        </div>
      )}
      {isPopupOpen && (
        <ProjectPopup onClose={closePopup} onCreate={handleCreateProject} />
      )}
    </div>
  );
};

export default CreateProjectPage;
