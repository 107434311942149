import React from 'react';

function DaysOptions() {
  return (
    <>
      <option value="" disabled>Select days</option>
      <option value="+0Days">+0 days</option> 
      <option value="+1Day">+1 day</option> 
      <option value="+365Days">+365 days</option> 
      <option value="-365Days">-365 days</option> 
      <option value="+1095Days">+1095 days(3 years)</option> 
      <option value="+1825Days">+1825 days(5 years)</option> 
    </>
  );
}

export default DaysOptions;
