import React, { useState, useEffect } from 'react';
import '../../../Styles/Plugins/ApiConnector/AddAnotherCall/InitializeCall.css';

function InitializeCall({ apiResponse, onClose, addAnotherCallApiName }) {
  const [showRawData, setShowRawData] = useState(false);
  const [keysArray, setKeysArray] = useState([]);
  const [error, setError] = useState(null);

  const toggleRawData = () => {
    setShowRawData(!showRawData);
  };

  const getDataType = (value) => {
    const type = typeof value;
    if (type === 'string') {
      // Check if it's a date
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(value)) {
        return 'date';
      }
      return 'text';
    } else if (type === 'number') {
      return 'number';
    } else if (type === 'boolean') {
      return 'yes / no';
    } else {
      return 'Ignore Field';
    }
  };

  useEffect(() => {
    // Update keysArray when apiResponse changes
    if (apiResponse && apiResponse.error) {
      setError(apiResponse.error); // Set the error state
    } else if (Array.isArray(apiResponse) && apiResponse.length > 0) {
      const firstObject = apiResponse[0];
      setKeysArray(Object.keys(firstObject));
    } else if (typeof apiResponse === 'object' && apiResponse !== null) {
      setKeysArray(Object.keys(apiResponse));
    }
  }, [apiResponse]);

  const prettifyJSON = (jsonString) => JSON.stringify(JSON.parse(jsonString), null, 2);

  return (
    <div className="initializecall-modal">
      <div className="initializecall-modal-header">Returned values - {addAnotherCallApiName}</div>
      <div className="initializecall-modal-body">
        {error ? (
          <div className="error-message">{error.message}</div>
        ) : (
          <>
            <div className="initializecall-modal-sub-sec1">
              {keysArray.map((key, index) => (
                <div key={index}>
                  <label>{key}</label>
                  <select className="initializecall-select">
                    <option value={getDataType(apiResponse[key])}>{getDataType(apiResponse[key])}</option>
                    <option value="text">text</option>
                    <option value="number">number</option>
                    <option value="date">date</option>
                    <option value="date(UNIX)">date(UNIX)</option>
                    <option value="date interval">date interval</option>
                    <option value="yes / no">yes / no</option>
                    <option value="file">file</option>
                    <option value="image">image</option>
                    <option value="geographic address">geographic address</option>
                  </select>
                </div>
              ))}
            </div>
            <div className="initializecall-modal-sub-sec2">
              <a href="#" onClick={toggleRawData}>
                {showRawData ? 'Hide raw data' : 'Show raw data'}
              </a>
              {showRawData && (
                <div className="raw-data-box">
                  {/* Display entire API response */}
                  <pre className='pre'>{prettifyJSON(JSON.stringify(apiResponse, null, 2))}</pre>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="initializecall-modal-footer">
        <button className="initializecall-save-button" onClick={() => console.log('Save')}>
          Save
        </button>
        <button className="initializecall-cancel-button" onClick={() => onClose()}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default InitializeCall;
