import React from 'react';
import '../../../Styles/Plugins/ApiConnector/AddAnotherCall/ManuallyEnterAPIResponse.css';

function ManuallyEnterAPIResponse({onClose}) {
    return (
        <div className="manuallyenterapires-modal">
          <div className="manuallyenterapires-modal-header">Manually enter API response</div>
          <div className="manuallyenterapires-modal-body">
            
          </div>
          <div className="manuallyenterapires-modal-footer">
            <button className="manuallyenterapires-save-button">Save</button>
            <button className="manuallyenterapires-cancel-button" onClick={onClose}>Cancel</button>
          </div>
        </div>
    );
}

export default ManuallyEnterAPIResponse