// HorizontalNavbar.jsx
import React from 'react';
import home from '../../Assets/Images/home_gray.png';
import home_blue from '../../Assets/Images/home_blue.png';
import '../Styles/HorizontalNavbar/HorizontalNavbar.css';
import { useProjectsContext } from '../../Context/ProjectsContext'; 

const HorizontalNavbar = ({handleHomeButtonClick}) => {
  const { currentDatabase } = useProjectsContext();  
  console.log(".....H'currentDatabase...", currentDatabase);
  
  return (
    <div className="main-hor-nav-bar">
      <div className="hor_row">
        <button className="hor_btn" onClick={handleHomeButtonClick}>
          <img src={home} alt="home" className="hor_img" />
          <img src={home_blue} alt="home_blue" className="hor_img_hover" />
          <span className="hor_label">Home</span>
        </button>
      </div>
    </div>
  );
};

export default HorizontalNavbar;
