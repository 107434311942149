import React, { useState, useEffect } from 'react';
import '../Styles/Plugins/Plugins.css';
import InstallPlugins from './InstallPlugins';
import ConnectorComponents from './ConnectorsPath';
import { ConnectorContextProvider, useConnectorContext } from '../../Context/ConnectorContext';

function Plugins() {

  const [showInstallPopup, setShowInstallPopup] = useState(false);
  const [selectedCardHeading, setSelectedCardHeading] = useState('');
  const [installedConnectorData, setInstalledConnectorData] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [installedConnectorDataDB, setInstalledConnectorDataDB] = useState([]);
  const [documentId, setDocumentId] = useState(null);

  const { firstDocumentId, connectorName, connectorValues, dataFromContext } = useConnectorContext();

  const [installedPlugins, setInstalledPlugins] = useState([]);

  console.log("dataFromContext:", dataFromContext);

  useEffect(() => {
    // Check if the page has already been refreshed to avoid infinite reload
    if (!sessionStorage.getItem('reloaded')) {
      sessionStorage.setItem('reloaded', 'true'); // Set the flag
      window.location.reload(); // Refresh the page
    }
  }, []);

  const handleAddPluginsClick = () => {
    setShowInstallPopup(true);
  };

  // const handleDoneClick = () => {
  //   setShowInstallPopup(false);
  // };
  const handleDoneClick = (installedPlugins) => {
    setShowInstallPopup(false);
    setInstalledPlugins(installedPlugins);
  };

  const handleSelectCard = (heading) => {
    setSelectedCardHeading(heading);
  };

  // Define mapHeadingToComponent directly in the Plugins.jsx file
  const mapHeadingToComponent = (heading) => {
    const componentMappings = {
      'API Connector': 'ApiConnector',
      'MongoDB Connector': 'MongoDBConnector',
      // Add other mappings as needed
    };
    return componentMappings[heading] || heading;
  };

  const MappedComponent = selectedCardHeading && ConnectorComponents[mapHeadingToComponent(selectedCardHeading)];

  const onDataReadyFromConnector = (data) => {
    // console.log("InstalledConnectorData",data);
    setInstalledConnectorData(data);
  };
  // console.log("InstalledConnectorData",installedConnectorData);

  const handleInstallPlugins = async () => {
    try {
      // console.log("hiiii");
      // console.log("selectedCardHeading",selectedCardHeading)
      // Perform the POST request to the /addplugins endpoint
      const response = await fetch('https://aim-core.insurancepolicy4us.com/addplugins', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selectedCardHeading,
          installedConnectorData,
        }),
      });

      if (response.ok) {
        // Handle success (if needed)
        console.log('Plugins installed successfully!');
        alert('Plugins installed successfully!');
        window.location.reload();
      } else {
        // Handle error (if needed)
        console.error('Error installing plugins');
        alert('Failed to install plugins.');
      }
    } catch (error) {
      // Handle network error or other issues
      console.error('Network error:', error);
    }
  };

  // useEffect(() => {

  //   if (selectedCardHeading && installedConnectorData && installedConnectorData.length > 0) {
  //     handleInstallPlugins();
  //   }
  // }, [selectedCardHeading, installedConnectorData]);


  useEffect(() => {
    setPlugins(dataFromContext);
    setSelectedCardHeading(connectorName);
    setInstalledConnectorDataDB(connectorValues); 
    setDocumentId(firstDocumentId);
    // setInstalledPlugins([connectorName])

    console.log("InstalledConnectorDataDB:", dataFromContext.map(item => item.connectorValues));

    // Extract Connector_Name from each object and push into setInstalledPlugins array
    const installedPlugins = dataFromContext.map(item => item.Connector_Name);
    setInstalledPlugins(installedPlugins);

  }, [firstDocumentId, connectorName, connectorValues, dataFromContext]);


  return (
    <div className="container">
      <div className="row ">
        <div className="col-md-12">
          <div className="plugin-container">
            <div className="plugin-position">
              <div className="plugin-header">
                <h4 className='plugin-h4'>Installed Plugins</h4>
                <button className="plugin-btn" onClick={handleAddPluginsClick}>Add Plugins</button>
              </div>
              <div><button className="plugin-btn" onClick={handleInstallPlugins}>Save</button></div>
            </div>
            <div className='plugin-body-row'>
                <div className='plugin-body-col-md-3'>
                  {/* {selectedCardHeading && (
                    <>
                    <button
                      className="custom-card-button"
                    >
                      {selectedCardHeading}
                    </button>
                    </>
                  )} */}
                  {installedPlugins.map((plugin, index) => (
                  <button 
                    key={index} 
                    className={`custom-card-button ${selectedCardHeading === plugin ? 'active' : ''}`} 
                    onClick={() => handleSelectCard(plugin)}
                  >
                    {plugin}
                  </button>
                  ))}
                </div>
                <div className='plugin-body-col-md-9'>
                {/* {selectedCardHeading &&  */}
                  {MappedComponent && 
                    <MappedComponent 
                      onDataReady={onDataReadyFromConnector} 
                      dataFromContext={dataFromContext}
                      documentId={documentId}
                    />
                  }
                </div>
            </div>
          </div>
        </div>
      </div>
      {showInstallPopup && (
        <div className="plugin-popup-overlay">
          <InstallPlugins 
            onDone={handleDoneClick} 
            onSelectCard={handleSelectCard} 
            installedPluginsData={installedPlugins}
          />
        </div>
      )}
    </div>
  );
}

export default Plugins;
