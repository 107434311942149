import '../Styles/LoginModule/Signup.css'
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import BounceLoader from "react-spinners/BounceLoader";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const navigate = useNavigate();

  // password visibility toggle
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      console.error("Passwords do not match!");
      return;
    }

    setLoading(true);

      try {
        const res = await fetch(`https://aim-core.insurancepolicy4us.com/auth/register`, {
          method: "post",
          headers: {
            "content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        const { message } = await res.json();
  
        if (!res.ok) {
          throw new Error(message);
        }
  
        setLoading(false);
        console.log(message);
        navigate("/login");
      } catch (err) {
        console.error(err.message);
        setLoading(false);
      }
  };

  return (
    <section className="su-container">
      <div className="su-form-container">
        <div className="su-form-wrapper">
          <h3 className="su-form-title">Create an Account</h3>
          <form onSubmit={submitHandler}>
            <div className="mb-4 su-input-container">
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="su-form-input"
                required
              />
            </div>
            <div className="mb-4 su-input-container">
              <input
                type={passwordType}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="su-form-input"
                required
              />
              <div className="su-password-icon" onClick={togglePassword}>
                {passwordType === "password" ? <FaRegEyeSlash /> : <FaRegEye />}
              </div>
            </div>
            <div className="mb-4 su-input-container">
              <input
                type={confirmPasswordType}
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="su-form-input"
                required
              />
              <div className="su-password-icon" onClick={toggleConfirmPassword}>
                {confirmPasswordType === "password" ? (
                  <FaRegEyeSlash />
                ) : (
                  <FaRegEye />
                )}
              </div>
            </div>
            <div className="su-button-container">
              <button disabled={loading && true} className="su-submit-button">
                {/* {loading ? (
                  <BounceLoader className="loadingicon" size={27} color="#355bf3" />
                ) : ( */}
                  Signup
                {/* )} */}
              </button>
            </div>
            <p className="su-link-text">
              Already have an account?{" "}
              <Link to="/login" className="su-link">
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Signup;
