import React, { useState, useEffect } from 'react'
import '../../../Styles/Plugins/ApiConnector/Authentication/OAuth2UserAgentFlow.css'

function OAuth2UserAgentFlow({onAuthObjectChange, authenticationData}) {
  const [appIdUserAgent, setAppIdUserAgent] = useState('');
  const [appSecretUserAgent, setAppSecretUserAgent] = useState('');
  const [devAppIdUserAgent, setDevAppIdUserAgent] = useState('');
  const [devSecretUserAgent, setDevSecretUserAgent] = useState('');
  const [scopeUserAgent, setScopeUserAgent] = useState('');
  const [tokenNameUserAgent, setTokenNameUserAgent] = useState('');
  const [authUserAgent, setAuthUserAgent] = useState(false); 
  const [tokenQueryUserAgent, setTokenQueryUserAgent] = useState(false); 
  const [accessTokenUserAgent, setAccessTokenUserAgent] = useState(false); 
  const [accessTypeUserAgent, setAccessTypeUserAgent] = useState(false); 
  const [genericRedirectURLUserAgent, setGenericRedirectURLUserAgent] = useState(false); 
  const [loginDialogUserAgent, setLoginDialogUserAgent] = useState('');
  const [accessTokenEndpointUserAgent, setAccessTokenEndpointUserAgent] = useState('');
  const [userProfileEndpointUserAgent, setUserProfileEndpointUserAgent] = useState('');
  const [userIdKeyPathUseragent, setUserIdKeyPathUseragent] = useState('id');
  const [userEmailKeyPathUseragent, setUserEmailKeyPathUseragent] = useState('email');

  useEffect(() => {
    if (authenticationData) {
      setAppIdUserAgent(authenticationData.appIdUserAgent || '');
      setAppSecretUserAgent(authenticationData.appSecretUserAgent || '');
      setDevAppIdUserAgent(authenticationData.devAppIdUserAgent || '');
      setDevSecretUserAgent(authenticationData.devSecretUserAgent || '');
      setScopeUserAgent(authenticationData.scopeUserAgent || '');
      setTokenNameUserAgent(authenticationData.tokenNameUserAgent || '');
      setAuthUserAgent(authenticationData.authUserAgent || false);
      setTokenQueryUserAgent(authenticationData.tokenQueryUserAgent || false);
      setAccessTokenUserAgent(authenticationData.accessTokenUserAgent || false);
      setAccessTypeUserAgent(authenticationData.accessTypeUserAgent || false);
      setGenericRedirectURLUserAgent(authenticationData.genericRedirectURLUserAgent || false);
      setLoginDialogUserAgent(authenticationData.loginDialogUserAgent || '');
      setAccessTokenEndpointUserAgent(authenticationData.accessTokenEndpointUserAgent || '');
      setUserProfileEndpointUserAgent(authenticationData.userProfileEndpointUserAgent || '');
      setUserIdKeyPathUseragent(authenticationData.userIdKeyPathUseragent || 'id');
      setUserEmailKeyPathUseragent(authenticationData.userEmailKeyPathUseragent || 'email');
    }
  }, [authenticationData]);

    const handleAppIdUserAgent = (e) => {
      setAppIdUserAgent(e.target.value);
      };
  
    const handleAppSecretUserAgent = (e) => {
      setAppSecretUserAgent(e.target.value);
    };

    const handleDevAppIdUserAgent = (e) => {
      setDevAppIdUserAgent(e.target.value);
    };
    
    const handleDevSecretUserAgent = (e) => {
      setDevSecretUserAgent(e.target.value);
    };

    const handleScopeUserAgent = (e) => {
      setScopeUserAgent(e.target.value);
    };
    
    const handleTokenNameUserAgent = (e) => {
      setTokenNameUserAgent(e.target.value);
    };

    const handleAuthUserAgent = () => {
      setAuthUserAgent(!authUserAgent);
    };
  
    const handleTokenQueryUserAgent = () => {
      setTokenQueryUserAgent(!tokenQueryUserAgent);
    };

    const handleAccessTokenUserAgent = () => {
      setAccessTokenUserAgent(!accessTokenUserAgent)
    }

    const handleAccessTypeUserAgent = () => {
      setAccessTypeUserAgent(!accessTypeUserAgent)
    }

    const handleGenericRedirectURLUserAgent = () => {
      setGenericRedirectURLUserAgent(!genericRedirectURLUserAgent)
    }

    const handleLoginDialogUserAgent = (e) => {
      setLoginDialogUserAgent(e.target.value)
    }

    const handleAccessTokenEndpointUserAgent = (e) => {
      setAccessTokenEndpointUserAgent(e.target.value)
    }

    const handleUserProfileEndpointUserAgent = (e) => {
      setUserProfileEndpointUserAgent(e.target.value)
    }

    const handleUserIdKeyPathUseragent = (e) => {
      setUserIdKeyPathUseragent(e.target.value)
    }

    const handleUserEmailKeyPathUseragent = (e) => {
      setUserEmailKeyPathUseragent(e.target.value)
    }

    useEffect(() => {
      const generateDataObject = () => {
        const dataObject = {
          appIdUserAgent,
          appSecretUserAgent,
          devAppIdUserAgent,
          devSecretUserAgent,
          scopeUserAgent,
          tokenNameUserAgent,
          authUserAgent,
          tokenQueryUserAgent,
          accessTokenUserAgent,
          accessTypeUserAgent,
          genericRedirectURLUserAgent,
          loginDialogUserAgent,
          accessTokenEndpointUserAgent,
          userProfileEndpointUserAgent,
          userIdKeyPathUseragent,
          userEmailKeyPathUseragent,
        };
        onAuthObjectChange(dataObject);
      };
  
      generateDataObject();
    }, [
      appIdUserAgent,
      appSecretUserAgent,
      devAppIdUserAgent,
      devSecretUserAgent,
      scopeUserAgent,
      tokenNameUserAgent,
      authUserAgent,
      tokenQueryUserAgent,
      accessTokenUserAgent,
      accessTypeUserAgent,
      genericRedirectURLUserAgent,
      loginDialogUserAgent,
      accessTokenEndpointUserAgent,
      userProfileEndpointUserAgent,
      userIdKeyPathUseragent,
      userEmailKeyPathUseragent,
      onAuthObjectChange,
    ]);

    return (
      <div className="authuseragentflow-container">
        <div className="authuseragentflow-row">
          <label htmlFor={'appIdUseragent'}>App ID</label>
          <input id={'appIdUseragent'} value={appIdUserAgent} type="text" onChange={handleAppIdUserAgent} />
        </div>
        <div className="authuseragentflow-row">
          <label htmlFor={'appSecretUserAgent'}>App Secret</label>
          <input id={'appSecretUserAgent'} value={appSecretUserAgent} type="text" onChange={handleAppSecretUserAgent} />
        </div>
        <div className="authuseragentflow-row">
          <label htmlFor={'devAppIdUserAgent'}>Dev.App ID</label>
          <input id={'devAppIdUserAgent'} value={devAppIdUserAgent} type="text" placeholder='(Optional)' onChange={handleDevAppIdUserAgent} />
        </div>
        <div className="authuseragentflow-row">
          <label htmlFor={'devSecretUserAgent'}>Dev.Secret</label>
          <input id={'devSecretUserAgent'} value={devSecretUserAgent} type="text" placeholder='(Optional)' onChange={handleDevSecretUserAgent} />
        </div>
        <div className="authuseragentflow-row1">
          <label htmlFor={'scopeUserAgent'} className='UserAgent-label_1'>Scope</label>
          <input id={'scopeUserAgent'} className='UserAgent-input_1' value={scopeUserAgent} type="text" onChange={handleScopeUserAgent} />
        </div>
        <div className="authuseragentflow-row-1">
          <label htmlFor={'authUserAgent'} className='UserAgent-label_2'>Authentication goes in the header</label>
          <input
            type="checkbox"
            id={'authUserAgent'}
            className='UserAgent-input_2'
            checked={authUserAgent}
            onChange={handleAuthUserAgent}
          />
        </div>
        <div className="authuseragentflow-row2">
          <label htmlFor={'tokenNameUserAgent'}  className='UserAgent-label'>Token Name</label>
          <input id={'tokenNameUserAgent'} value={tokenNameUserAgent} type="text" placeholder='access_token' onChange={handleTokenNameUserAgent} />
        </div>
        <div className="authuseragentflow-row-2">
          <label htmlFor={'tokenQueryUserAgent'} className='UserAgent-label'>Token is returned as querystring</label>
          <input
            type="checkbox"
            id={'tokenQueryUserAgent'}
            checked={tokenQueryUserAgent}
            onChange={handleTokenQueryUserAgent}
          />
        </div>
        <div className="authuseragentflow-row3">
          <label htmlFor={'accessTokenUserAgent'} className='UserAgent-label'>Requesting an access token uses Basic Auth</label>
          <input
            type="checkbox"
            id={'accessTokenUserAgent'}
            checked={accessTokenUserAgent}
            onChange={handleAccessTokenUserAgent}
          />
        </div>
        <div className="authuseragentflow-row3">
          <label htmlFor={'accessTypeUserAgent'} className='UserAgent-label'>Add access_type=offline (Google APIs)</label>
          <input
            type="checkbox"
            id={'accessTypeUserAgent'}
            checked={accessTypeUserAgent}
            onChange={handleAccessTypeUserAgent}
          />
        </div>
        <div className="authuseragentflow-row4">
          <label htmlFor={'genericRedirectURLUserAgent'} className='UserAgent-label'>Use a generic redirect URL (https://example.com/api/1.1/oauth_redirect)</label>
          <input
            type="checkbox"
            id={'genericRedirectURLUserAgent'}
            checked={genericRedirectURLUserAgent}
            onChange={handleGenericRedirectURLUserAgent}
          />
        </div>
        <div className="authuseragentflow-row5">
          <label htmlFor={'loginDialogUserAgent'}  className='UserAgent-label'>Login dialog redirect</label>
          <input id={'loginDialogUserAgent'} value={loginDialogUserAgent} type="text" placeholder='https://accounts.spotify.com/authorize' onChange={handleLoginDialogUserAgent} />
          <span>(use [] for params)</span>
        </div>
        <div className="authuseragentflow-row6">
          <label htmlFor={'accessTokenEndpointUserAgent'}  className='UserAgent-label'>Access token endpoint</label>
          <input id={'accessTokenEndpointUserAgent'} value={accessTokenEndpointUserAgent} type="text" placeholder='https://accounts.spotify.com/api/token' onChange={handleAccessTokenEndpointUserAgent} />
        </div>
        <div className="authuseragentflow-row7">
          <label htmlFor={'userProfileEndpointUserAgent'}  className='UserAgent-label'>User profile endpoint</label>
          <input id={'userProfileEndpointUserAgent'} value={userProfileEndpointUserAgent} type="text" placeholder='https://accounts.spotify.com/v1/me' onChange={handleUserProfileEndpointUserAgent} />
        </div>
        <div className="authuseragentflow-row">
          <label htmlFor={'userIdKeyPathUseragent'}>User ID key path</label>
          <input id={'userIdKeyPathUseragent'} value={userIdKeyPathUseragent} type="text" onChange={handleUserIdKeyPathUseragent} />
  
          <label htmlFor={'userEmailKeyPathUseragent'}>User email key path</label>
          <input id={'userEmailKeyPathUseragent'} value={userEmailKeyPathUseragent} type="text" onChange={handleUserEmailKeyPathUseragent} />
        </div>
      </div>
    );
}

export default OAuth2UserAgentFlow