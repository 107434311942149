import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../Styles/Plugins/MongoDBConnector/MongoDBConnector.css';
import { Card } from 'react-bootstrap';
import trash_black from '../../../Assets/Images/trash_black.png';

function MongoDBConnector({onDataReady, dataFromContext, documentId}) {
    const [dbAccordionStates, setDbAccordionStates] = useState([]);
    const [dbData, setDbData] = useState([]);
    const [dbNames, setDbNames] = useState(Array.from({ length: dbAccordionStates.length }, () => ({ dbName: '', dynamic: false })));
    const [mongoUris, setMongoUris] = useState(Array.from({ length: dbAccordionStates.length }, () => ({ mongoUri: '', dynamic: false })));
    const [passWords, setPassWords] = useState(Array.from({ length: dbAccordionStates.length }, () => ({ passWord: '', dynamic: false })));

    useEffect(() => {
        const mongoDBConnectorObject = dataFromContext.find(item => item.Connector_Name === 'MongoDB Connector');
        if (mongoDBConnectorObject) {
            const installedMongoDBConnectorData = mongoDBConnectorObject.Connector_Values;
            setDbData(installedMongoDBConnectorData);
            setDbNames(installedMongoDBConnectorData.map(db => ({ dbName: db.dbName.dbName, dynamic: db.dbName.dynamic })));
            setMongoUris(installedMongoDBConnectorData.map(db => ({ mongoUri: db.mongoUri.mongoUri, dynamic: db.mongoUri.dynamic })));
            setPassWords(installedMongoDBConnectorData.map(db => ({ passWord: db.passWord.passWord, dynamic: db.passWord.dynamic })));
            setDbAccordionStates(Array.from({ length: installedMongoDBConnectorData.length }, () => false));
        }
    }, [dataFromContext]);
    
    const addDb = () => {
        setDbAccordionStates([...dbAccordionStates, false]);      
        // Create a new DB entry with initial data
        const newDbEntry = {
          dbIndex: dbData.length, // Assign a unique DB index
          dbName: '',
          mongoUri: '',
          passWord:''
        };

        setDbData([...dbData, newDbEntry]);
    }

    const toggleAccordion = (index) => {
        const updatedDbAccordionStates = [...dbAccordionStates];
        updatedDbAccordionStates[index] = !updatedDbAccordionStates[index];
        setDbAccordionStates(updatedDbAccordionStates);
    };

    const updateDbName = (index, newValue, isDynamic) => {
        const updatedDbData = [...dbData];
        updatedDbData[index].dbName = { dbName: newValue, dynamic: isDynamic };
      
        setDbData(updatedDbData);
      
        const updatedDbNames = [...dbNames];
        updatedDbNames[index] = { dbName: newValue, dynamic: isDynamic };
        setDbNames(updatedDbNames);
    };

    const handleDbDeleteClick = (dbIndex) => {
        setDbData((prevDbData) => {
          const updatedDbData = [...prevDbData];
          updatedDbData.splice(dbIndex, 1);
          return updatedDbData;
        });
      
    };

    
  const updateMongoUri = (index, newValue, isDynamic) => {
    const updatedDbData = [...dbData];
    updatedDbData[index].mongoUri = { mongoUri: newValue, dynamic: isDynamic };
  
    setDbData(updatedDbData);
    // Set the entered value in mongoUris state
    const updatedMongoUris = [...mongoUris];
    updatedMongoUris[index] = { mongoUri: newValue, dynamic: isDynamic };
    setMongoUris(updatedMongoUris);
  };


  const updatePassWord = (index, newValue, isDynamic) => {
    const updatedDbData = [...dbData];
    updatedDbData[index].passWord = { passWord: newValue, dynamic: isDynamic };
  
    setDbData(updatedDbData);
    const updatedPassWords = [...passWords];
    updatedPassWords[index] = { passWord: newValue, dynamic: isDynamic };
    setPassWords(updatedPassWords);
  };

  useEffect(() => {
    const nonEmptyDBData = dbData.filter(db => db.dbName && db.dbName.dbName && typeof db.dbName.dbName.trim === 'function' && db.dbName.dbName.trim() !== '');
  
    onDataReady(nonEmptyDBData);
  }, [dbData, onDataReady]);

  return (
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="dbconnector-container border">
                    <h6>MongoDB Connector</h6>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu odio nec turpis
                        hendrerit ultrices vel id quam.
                    </p>
                    <div className="dbbutton-container">
                        {dbAccordionStates.map((isAccordionOpen, index) => (
                            <Card
                                key={index}
                                className={`dbaccordion-card ${isAccordionOpen ? 'dbaccordion-open' : ''}`}
                            >
                                <Card.Body className="dbcollapse-btn">
                                <span style={{ display: isAccordionOpen ? 'block' : 'none' }} className='dbaccordian-span'>
                                    <div
                                        className="dbacc-card-input-container"
                                    >
                                        <label className="col-md-3" htmlFor={`dbName-${index}`}>MongoDB Connector Name</label>
                                        <input
                                            id={`dbName-${index}`}
                                            value={dbNames[index]?.dbName}
                                            type="text"
                                            onChange={(e) => updateDbName(index, e.target.value, dbNames[index]?.dynamic)}
                                        />
                                        <input
                                            id={`dbName-${index}`}
                                            type="checkbox"
                                            className="dbsmall-checkbox"
                                            checked={dbNames[index]?.dynamic}
                                            onChange={(e) => updateDbName(index, dbNames[index]?.dbName, e.target.checked)}
                                        />
                                        <div className="dbacc-card-input-container-del-img" onClick={() => handleDbDeleteClick(index)}>
                                            <img src={trash_black} alt="trash_black" className="dbacc-card-input-container-del-img-hover" />
                                        </div>
                                    </div>
                                    <div
                                        className="dbacc-card-input-container-1"
                                        >       
                                        <label htmlFor={`mongoUri-${index}`}>Mongo Uri</label>
                                        <input 
                                            id={`mongoUri-${index}`}
                                            type="text"
                                            value={mongoUris[index]?.mongoUri}
                                            onChange={(e) => updateMongoUri(index, e.target.value, mongoUris[index]?.dynamic)}
                                        />
                                        <div className="dbacc-card-input-container-1_1" >
                                            <input
                                                id={`mongoUris-${index}`}
                                                type="checkbox"
                                                className='dbsmall-checkbox1'
                                                checked={mongoUris[index]?.dynamic}
                                                onChange={(e) => updateMongoUri(index, mongoUris[index]?.mongoUri, e.target.checked)}
                                            /> 
                                        </div>
                                    </div>  
                                    <div
                                        className="dbacc-card-input-container-1"
                                        >       
                                        <label htmlFor={`passWord-${index}`}>Password</label>
                                        <input 
                                            id={`passWord-${index}`}
                                            type="text"
                                            value={passWords[index]?.passWord}
                                            onChange={(e) => updatePassWord(index, e.target.value, passWords[index]?.dynamic)}
                                        />
                                        <div className="dbacc-card-input-container-1_1" >
                                            <input
                                                id={`passWords-${index}`}
                                                type="checkbox"
                                                className='dbsmall-checkbox1'
                                                checked={passWords[index]?.dynamic}
                                                onChange={(e) => updatePassWord(index, passWords[index]?.passWord, e.target.checked)}
                                            /> 
                                        </div>
                                    </div>
                                </span>
                                <div
                                    className="dbacc-card-input-container_"
                                    style={{ display: isAccordionOpen ? 'none' : 'flex' }}
                                >
                                <label htmlFor={`dbName-${index}`}>MongoDB Connector Name</label>
                                <input
                                    id={`dbName-${index}`}
                                    value={dbNames[index]?.dbName}
                                    type="text"
                                    readOnly
                                />
                                </div>
                                <button
                                    className="dbcollapse-button"
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {isAccordionOpen ? 'Collapse' : 'Expand'}
                                </button>
                                </Card.Body>
                            </Card>
                        ))}
                        <button className="db-button" onClick={addDb}>
                            Add Another MongoDB
                        </button>
                        <button className="dbuninstall-button">Uninstall this Plugin</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MongoDBConnector