import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ApiReceiver from "./Components/ApiReceiver/ApiReceiver";
import DynamicEvent from "./Components/Workflow/DynamicEvent";
import VerticalNavbar from "./Components/VerticalNavbar/VerticalNavbar";
import HorizontalNavbar from "./Components/HorizontalNavbar/HorizontalNavbar";
import InstallPlugins from "./Components/Plugins/InstallPlugins";
import ApiConnector from "./Components/Plugins/ApiConnector/ApiConnector";
import InitializeCall from "./Components/Plugins/ApiConnector/AddAnotherCall/InitializeCall";
import CreateProjectPage from "./Components/CreateProject/CreateProjectPage";
// import ConnectorComponent from "./Components/Workflow/DynamicAction/ConnectorComponent";
import MongoDBConnector from "./Components/Plugins/MongoDBConnector/MongoDBConnector";
import ConnectorComponent from "./Components/NestedArrayObject/Connector/ConnectorComponent";
import Signup from "./Components/LoginModule/SignUp";
import Login from "./Components/LoginModule/Login";
import { ProtectedProjectRoute, ProtectedAuthRoute} from './Router/ProtectedAuthRoute';
import HomePage from "./Components/HomePage/HomePage";

function App(err) {
  return (
    <Router>
      <Routes>
      <Route exact path='/' element={<HomePage/>} />     
      <Route exact path='/apireceiver' element={<ApiReceiver/>} />     
      <Route exact path='/:projectName/dynamicevent' element={
          <ProtectedProjectRoute allowedRoles={["Sagma", "user"]}>
            <DynamicEvent/>
          </ProtectedProjectRoute>
        }
      />
      <Route exact path='/vertical' element={<VerticalNavbar/>} />
      <Route exact path='/horizontal' element={<HorizontalNavbar/>} />
      <Route exact path='/install' element={<InstallPlugins />} />
      <Route exact path='/apiconnector' element={<ApiConnector />} />
      <Route exact path='/initialize' element={<InitializeCall />} />
      <Route exact path='/createproject' element={
        <ProtectedAuthRoute allowedRoles={["Sagma", "user"]}>
            <CreateProjectPage />
        </ProtectedAuthRoute>
        } 
        />
      {/* <Route exact path='/connectors' element={<ConnectorComponent />} /> */}
      <Route exact path='/dbconnector' element={<MongoDBConnector />} />
      <Route exact path='/nestedconnector' element={<ConnectorComponent />} />
      <Route exact path='/signup' element={<Signup />} />
      <Route exact path='/login' element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
