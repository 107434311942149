import React, { useState, useEffect } from 'react';
import '../../../Styles/Plugins/ApiConnector/Authentication/OAuth2CustomToken.css';
import trash_gray from '../../../../Assets/Images/trash_gray.png';
import trash_black from '../../../../Assets/Images/trash_black.png';
// import { Controlled as CodeMirror } from 'react-codemirror2';
// import 'codemirror/lib/codemirror.css';
// import 'codemirror/mode/javascript/javascript';


function OAuth2CustomToken({onAuthObjectChange, authenticationData}) {
  const [tokenEndpointCustomToken, setTokenEndpointCustomToken] = useState('');
  const [tokenCallHeaders, setTokenCallHeaders] = useState([]);
  const [authCustomTokenBody, setAuthCustomTokenBody] = useState('');

  useEffect(() => {
    if (authenticationData) {
      setTokenEndpointCustomToken(authenticationData.tokenEndpointCustomToken || '');
      setTokenCallHeaders(authenticationData.tokenCallHeaders || []);
      setAuthCustomTokenBody(authenticationData.authCustomTokenBody || '');
    }
  }, [authenticationData]);

  const handleTokenEndpointCustomToken = (e) => {
    setTokenEndpointCustomToken(e.target.value);
  };

  const addTokenCallHeader = () => {
    setTokenCallHeaders((prevHeaders) => [
      ...prevHeaders,
      { key: '', value: '', optional: false },
    ]);
  };

  const updateTokenCallHeader = (headerIndex, field, newValue) => {
    setTokenCallHeaders((prevHeaders) => {
      const updatedHeaders = [...prevHeaders];
      updatedHeaders[headerIndex][field] = newValue;
      return updatedHeaders;
    });
  };

  const removeTokenCallHeader = (headerIndex) => {
    setTokenCallHeaders((prevHeaders) => {
      const updatedHeaders = [...prevHeaders];
      updatedHeaders.splice(headerIndex, 1);
      return updatedHeaders;
    });
  };

  const handleAuthCustomTokenBody = (editor, data, value) => {
    setAuthCustomTokenBody(value);
  };

  useEffect(() => {
    const generateDataObject = () => {
      const dataObject = {
        tokenEndpointCustomToken,
        tokenCallHeaders,
        authCustomTokenBody,
      };
      onAuthObjectChange(dataObject);
    };
    generateDataObject();
  }, [tokenEndpointCustomToken, tokenCallHeaders, authCustomTokenBody, onAuthObjectChange]);


  return (
    <div className="authcustomtoken-container">
      <div className="authcustomtoken-row">
        <label htmlFor={'tokenEndpointCustomToken'}>Token endpoint (POST)</label>
        <input
          id={'tokenEndpointCustomToken'}
          value={tokenEndpointCustomToken}
          type="text"
          onChange={handleTokenEndpointCustomToken}
        />
      </div>
      <div className="authcustomtoken-row1">
        <label htmlFor={''}>Token call headers</label>
        {tokenCallHeaders.map((header, headerIndex) => (
          <div key={headerIndex} className="token-header-row">
            <div className="token-header-field">
              <label htmlFor={`key-${headerIndex}`}>Key</label>
              <input
                id={`key-${headerIndex}`}
                value={header.key}
                type="text"
                onChange={(e) => updateTokenCallHeader(headerIndex, 'key', e.target.value)}
              />
            </div>
            <div className="token-header-field">
              <label htmlFor={`value-${headerIndex}`}>Value</label>
              <input
                id={`value-${headerIndex}`}
                value={header.value}
                type="text"
                onChange={(e) => updateTokenCallHeader(headerIndex, 'value', e.target.value)}
              />
            </div>
            <div className="token-header-field">
              <label htmlFor={`optional-${headerIndex}`}>Optional</label>
              <input
                id={`optional-${headerIndex}`}
                checked={header.optional}
                type="checkbox"
                onChange={(e) => updateTokenCallHeader(headerIndex, 'optional', e.target.checked)}
              />
            </div>
            <div className="token-header-field" onClick={() => removeTokenCallHeader(headerIndex)}>
              <img src={trash_black} alt="trash_black" className="token_img_hover" />
            </div>
          </div>
        ))}
        <button id={`addHeader-${tokenCallHeaders.length}`} onClick={addTokenCallHeader}>
          Add header
        </button>
      </div>
      <div className="authcustomtoken-row2">
      <label htmlFor={'authCustomTokenBody'}>
        Body (JSON object, use {'<>'} for dynamic values)
      </label>
      {/* <CodeMirror
        value={authCustomTokenBody}
        options={{
          mode: 'javascript',
          lineNumbers: true,
          lineWrapping: true,
        }}
        onBeforeChange={(editor, data, value) => handleAuthCustomTokenBody(editor, data, value)}
      /> */}
      </div>
    </div>
  );
}

export default OAuth2CustomToken;
