import React from 'react';

const SubConditionOptions = ({ selectedIdentifier }) => {
  const optionsByIdentifier = {
    Date: [ 
            'After', 
            'Before',
            'Equals'
          ],
    Integer: [
                'Greater than',
                'Less than'
            ],
    Text: [
            'Contains', 
            'Does not contain',
            'Exactly Matches',
            'Does not exactly match',
            'Is in',
            'Is not in',
            'Starts with',
            'Does not start with',
            'Ends with',
            'Does not end with',
            'Lowercase',
            'Uppercase'
        ],
    // Add more options for other identifiers as needed
  };

  const options = optionsByIdentifier[selectedIdentifier] || [];

  return (
    <>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </>
  );
};

export default SubConditionOptions;
