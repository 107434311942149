import React, { useState, useEffect } from 'react';
import '../Styles/Workflow/CustomEventPopup.css';
import Draggable from 'react-draggable';
import trash_black from '../../Assets/Images/trash_black.png';
import square_exit_white from '../../Assets/Images/square_exit_white.png';
import save from '../../Assets/Images/save.png';
import edit from '../../Assets/Images/edit.png';
import CustomEventResponse from './CustomEventResponse/CustomEventResponse';

const CustomEventPopup = ({ selectedEventIndex, onClose, onEventNameChange, onFieldChange, dynamicDbData, eventData }) => {

  console.log("eventData",eventData);
  const [fieldData, setFieldData] = useState({});
  const [eventName, setEventName] = useState('');
  const [parameters, setParameters] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);

  // State for fields inside Trigger tab
  const [triggerCondition1, setTriggerCondition1] = useState('');
  const [triggerEventName, setTriggerEventName] = useState('');
  const [triggerEventNames, setTriggerEventNames] = useState([]);
  const [triggerStep, setTriggerStep] = useState('');
  const [triggerStepsForSelectedEvent, setTriggerStepsForSelectedEvent] = useState([]);

  // State for fields inside Condition tab
  const [conditionFilter, setConditionFilter] = useState('');
  const [getDataFromCustomEventResponse, setGetDataFromCustomEventResponse] = useState({});

  const [activeTab, setActiveTab] = useState('Trigger');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // console.log("dynamicDbData",dynamicDbData);

  const onDataFromCustomEventResponse = (dataObject) => {
    console.log("onDataFromCustomEventResponse:",dataObject)
    setGetDataFromCustomEventResponse((prevDataObject)=>{
      return { ...prevDataObject, ...dataObject };
    })
  }

  useEffect(() => {
      if (eventData && !buttonClicked) {
        const dataObject = {
          [selectedEventIndex]: {
            Trigger: {},
            Condition: {},
            Response: {
              getDataFromCustomEventResponse
            }
          },
      };

      if (triggerCondition1 === 'External') {
        dataObject[selectedEventIndex].Trigger = {
          trigger: triggerCondition1,
          eventName: eventName,
          parameters: parameters,
        };
      } else if (triggerCondition1 === 'Internal') {
        dataObject[selectedEventIndex].Trigger = {
          trigger: triggerCondition1,
          triggerEventName: triggerEventName,
          triggerStep: triggerStep,
        };
      }
      onFieldChange(dataObject);
    }
  }, [
    eventData, 
    triggerCondition1,
    eventName, 
    parameters, 
    triggerEventName, 
    triggerStep, 
    selectedEventIndex, 
    buttonClicked,
    onFieldChange,
    getDataFromCustomEventResponse
  ]);
  
  const handleEventGenerateDataObject = async () => {
    try {
      const dataObject = {
        [selectedEventIndex]: {
          Trigger: {},
          Condition: {},
          Response: {
            getDataFromCustomEventResponse
          }
        },
      };
  
      if (triggerCondition1 === 'External') {
        dataObject[selectedEventIndex].Trigger = {
          trigger: triggerCondition1,
          eventName: eventName,
          parameters: parameters,
        };
      } else if (triggerCondition1 === 'Internal') {
        dataObject[selectedEventIndex].Trigger = {
          trigger: triggerCondition1,
          triggerEventName: triggerEventName,
          triggerStep: triggerStep,
        };
      }
  
      onFieldChange(dataObject);
      setButtonClicked(true);
  
      // Make a POST request to submit the fieldData to the backend
      const response = await fetch('https://aim-core.insurancepolicy4us.com/submit-field-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fieldData: dataObject }),
      });
  
      const responseData = await response.json();
  
      if (responseData.success) {
        console.log('Field data submitted successfully');
        setFieldData({}); // Reset fieldData after successful submission
        window.location.reload();
      } else {
        console.error('Error submitting field data:', responseData.message);
        // Handle error, display error message or perform other actions
      }
    } catch (error) {
      console.error('Error submitting field data:', error);
      // Handle unexpected errors
    } finally {
      onClose();
    }
  };

  const handleUpdateEventClick = () => {

    console.log("eventData._id", eventData._id);
    if (!eventData || !eventData._id) {
      console.error("Cannot update event without valid eventData or _id");
      return;
    }

    const updateEndpoint = `https://aim-core.insurancepolicy4us.com/update-event/${eventData._id}`;

    const updateData = {
      Trigger: {},
      Condition: {},
      Response: {
        getDataFromCustomEventResponse
      }
    };

    if (triggerCondition1 === 'External') {
      updateData.Trigger = {
        trigger: triggerCondition1,
        eventName: eventName,
        parameters: parameters,
      };
    } else if (triggerCondition1 === 'Internal') {
      updateData.Trigger = {
        trigger: triggerCondition1,
        triggerEventName: triggerEventName,
        triggerStep: triggerStep,
      };
    }

    // Make a PUT request to the server to update the event
    fetch(updateEndpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => response.json())
      .then((updatedEventData) => {
        console.log('Update successful', updatedEventData);

        // Update the local state with the updated eventData
        setEventName(updatedEventData.Trigger.eventName);
        setParameters(updatedEventData.Trigger.parameters);

        // Notify the parent component about the update
        onFieldChange(updatedEventData);
        window.location.reload();
      })
      .catch((error) => console.error('Error updating event:', error));
  };

  useEffect(() => {
    if (eventData && eventData.selectedIndex === selectedEventIndex) {
      setTriggerCondition1(eventData.Trigger.trigger || '')
      setEventName(eventData.Trigger.eventName || '');
      setParameters(eventData.Trigger.parameters || []);
      setTriggerEventName(eventData.Trigger.triggerEventName  || '')
      setTriggerStep(eventData.Trigger.triggerStep  || '')

    } else {
      setTriggerCondition1('')
      setEventName('');
      setParameters([]);
      setTriggerEventName('');
      setTriggerStep('');
    }
  }, [selectedEventIndex, eventData]);


  const handleAddParameter = () => {
    const newParameter = {
      id: parameters.length + 1,
      name: "", // Correct usage of backticks
    };
  
    setParameters([...parameters, newParameter]);
  };

  const handleDeleteParameterClick = (id) => {

    console.log("parameterId", id);
    console.log("DocObjectId", eventData._id);
    // Check if parameters are loaded from eventData
    if (eventData && eventData?.Trigger?.parameters) {
      console.log("based on eventData");
      const deleteEndpoint = `https://aim-core.insurancepolicy4us.com/delete-parameter/${eventData._id}/${id}`;

      // Make a DELETE request to the server to delete the parameter
      fetch(deleteEndpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("data",data);
          setParameters(data.Trigger.parameters || []);
          // Update the local state or take appropriate actions
          onFieldChange(data);
        })
        .catch((error) => console.error('Error deleting parameter:', error));
    } 
    // else {
    //   console.log("based not on eventData");
    //   // Parameters are not loaded from eventData, filter from the local state
    //   const updatedParameters = parameters.filter((parameter) => parameter.id !== id);
    //   setParameters(updatedParameters);
    // }
  };

  useEffect(() => {
    // Check if dynamicDbData exists and is an array
    if (dynamicDbData && Array.isArray(dynamicDbData) && dynamicDbData.length > 0) {
      // Filter out the event_name values based on the condition
      const filteredEventNames = dynamicDbData
        .filter((data) => data.selectedIndex !== selectedEventIndex)
        .map((data) => data.Trigger.eventName);

      // Set the filtered event_name values to the state
      setTriggerEventNames(filteredEventNames);
    }
  }, [dynamicDbData, selectedEventIndex]);

  useEffect(() => {
    // Find the selected event data based on triggerEventName
    const selectedEventData = dynamicDbData.find((data) => data.Trigger.eventName === triggerEventName);

    // Update trigger steps based on selected event data
    if (selectedEventData) {
      const steps = selectedEventData.dynamic_actions.map((action) => action.step);
      setTriggerStepsForSelectedEvent(steps);
    } else {
      setTriggerStepsForSelectedEvent([]);
    }
  }, [triggerEventName, dynamicDbData]);

  return (
    <Draggable>
      <div className="custom-event-popup">
        <div className="popup-event-header">
        <h4 className='event-header-h4'>{eventName ? eventName : 'Custom Event'}</h4>
          <div className="event-header-container-img" onClick={handleEventGenerateDataObject}>
            <img src={save} alt="save" className="event-header-container-img-hover" />
          </div>
          <div className="event-header-container-img" onClick={handleUpdateEventClick}>
            <img src={edit} alt="edit" className="event-header-container-img-hover" />
          </div>
          <div className="event-header-container-img" onClick={onClose}>
            <img src={square_exit_white} alt="square_exit_white" className="event-header-container-img-hover" />
          </div>
        </div>
        <div className="tab-container">
            <div
              className={`tab ${activeTab === 'Trigger' ? 'active-tab' : ''}`}
              onClick={() => handleTabClick('Trigger')}
            >
              Trigger
            </div>
            <div
              className={`tab ${activeTab === 'Condition' ? 'active-tab' : ''}`}
              onClick={() => handleTabClick('Condition')}
            >
              Condition
            </div>
            <div
              className={`tab ${activeTab === 'Response' ? 'active-tab' : ''}`}
              onClick={() => handleTabClick('Response')}
            >
              Response
            </div>
            
          </div>
        <div className="popup-event-body">
        {activeTab === 'Trigger' && (
            <div>
              <div className='row'>
              <div className="col-md-12 trigger-condition">
                  <label className="col-md-4 peblabel" htmlFor="condition1">
                    Trigger:
                  </label>
                  <select
                    className="trigger-select-condition"
                    onChange={(e) => setTriggerCondition1(e.target.value)}
                    value={triggerCondition1}
                  >
                    <option value="" disabled>Select a condition</option>
                    <option value="Internal">Internal</option>
                    <option value="External">External</option>                    
                  </select>
                </div>
              </div>
              { triggerCondition1 === "Internal" &&
                <div>
                  <div className='row'>
                  <div className="col-md-12 trigger-eventName">
                      <label className="col-md-4 peblabel" htmlFor="eventName">
                        Event:
                      </label>
                      <select
                        className="trigger-select-eventName"
                        onChange={(e) => setTriggerEventName(e.target.value)}
                        value={triggerEventName}
                      >
                        <option value="" disabled>Select an event</option>
                        {triggerEventNames.map((eventName) => (
                          <option key={eventName} value={eventName}>
                            {eventName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                  <div className="col-md-12 trigger-step">
                      <label className="col-md-4 peblabel" htmlFor="step">
                        Step:
                      </label>
                      <select
                        className="trigger-select-step"
                        onChange={(e) => setTriggerStep(e.target.value)}
                        value={triggerStep}
                      >
                        <option value="" disabled>Select a step</option>
                        {triggerStepsForSelectedEvent.map((step) => (
                          <option key={step} value={step}>
                            {step}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              }
              { triggerCondition1 === "External" &&
                <div>
                  <div className="row">
                    <div className="col-md-12 eventName">
                      <label className="col-md-4 peblabel" htmlFor="eventName">
                        Event Name:
                      </label>
                      <input
                        type="text"
                        id="eventName"
                        className="pebinput"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </div>
                  </div>
                  {parameters.map((parameter) => (
                    <div className="row" key={parameter.id}>
                      <div className="col-md-12 parameterName">
                        <label className="col-md-4 peblabel" htmlFor={`parameter-${parameter.id}`}>
                          Name
                        </label>
                        <input
                          type="text"
                          id={`parameter-${parameter.id}`}
                          className="pebinput"
                          value={parameter.name}
                          // Add onChange handler to update parameter name
                          onChange={(e) => {
                            const updatedParameters = parameters.map((param) =>
                              param.id === parameter.id
                                ? { ...param, name: e.target.value }
                                : param
                            );
                            setParameters(updatedParameters);
                          }}
                        />
                        {/* <div className="param-input-container-img" onClick={() => handleDeleteParameterClick(parameter._id, parameter.id)}> */}
                        <div className="param-input-container-img" onClick={() => handleDeleteParameterClick(parameter.id)}>
                          <img src={trash_black} alt="trash_black" className="param-input-container-img-hover" />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="custom-evnt-btn">
                    <button onClick={handleAddParameter}>Add a new parameter</button>
                  </div>
                </div>
              }
            </div>
          )}

          {activeTab === 'Condition' && (
            <div>
              <div className='row'>
                <div className="col-md-12 trigger-filter">
                  <label className="col-md-4 peblabel" htmlFor="eventName">
                    Filter:
                  </label>
                  <input
                    type="text"
                    id="filter"
                    className="pebinput"
                    value={conditionFilter}
                    onChange={(e) => setConditionFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === 'Response' && (
            <CustomEventResponse 
              selectedEventIndex={selectedEventIndex}
              dynamicDbData={dynamicDbData}
              onDataFromCustomEventResponse={onDataFromCustomEventResponse}
            />
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default CustomEventPopup;
