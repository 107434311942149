import React, { useState, useEffect } from 'react'
import '../../Styles/NestedArrayObject/Connector/NestedApiConnector.css';
import GetDataFromPopup from '../GetDataFrom/GetDataFromPopup';


    const jsonBody = {
        "authenticationDetails": {
          "agentId": "RSAI",
          "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084="
        },
        "existingTPPolicyDetails": {
          "tpAddress1": "RGfbfg street",
          "tpAddress2": "RTG street",
          "tpCity": "CHENNAI",
          "tpExpiryDate": "12/07/2020",
          "tpInceptionDate": "13/07/2018",
          "tpInsurer": "National Insurance Co. Ltd.",
          "tpPincode": "600096",
          "tpPolicyNumber": "RTGRT4523fTRF",
          "tpPolicyTerm": 1
        },
        "isFinalCalculateCall": "No",
        "isNewUser": "No",
        "isPosOpted": "false",
        "isproductcheck": "No",
        "istranscheck": "No",
        "policyED": "27/05/2025",
        "policySD": "28/05/2024",
        "posCode": "MP000016",
        "posDetails": {
          "aadhaar": "213412910874",
          "licenceExpiryDate": "04/02/2025",
          "mobile": "9047876767",
          "name": "posName",
          "pan": "EHXPK1164B"
        },
        "proposerDetails": {
          "GSTIN": "",
          "aadharNumber": "987456321012",
          "addressFour": "twlmbkd",
          "addressOne": "twlmbkd",
          "addressThree": "twlmbkd",
          "addressTwo": "twlmbkd",
          "clientCode": "",
          "contactAddress1": "twlmbkd",
          "contactAddress2": "twlmbkd",
          "contactAddress3": "twlmbkd",
          "contactAddress4": "twlmbkd",
          "contactCity": "CHENNAI",
          "contactPincode": "600032",
          "dateOfBirth": "12/08/1990",
          "eiaNumber": "3AABCI8695P1ZS",
          "guardianAge": "54",
          "guardianName": "ghijkl",
          "irName": "NSDL Database Management Limited",
          "isLoginCheck": "No",
          "isNewUser": "No",
          "nomineeAge": "14",
          "nomineeName": "abcdef",
          "occupation": "IT Profession",
          "panNumber": "EAVPS1254J",
          "regCity": "CHENNAPanAaadharLater",
          "userName": "ghijkl"
        },
        "quoteId": "",
        "reqType": "JSON",
        "respType": "JSON",
        "source": "Microsite",
        "vehicleDetails": {
          "ProductName": "Rollovercar",
          "VIRNumber": "",
          "accidentcoverforpaiddriver": "50000",
          "addOnsOptedInPreviousPolicy": "DepreciationWaiver, WindShieldGlass",
          "addonValue": "",
          "automobileAssociationMembership": "No",
          "averageMonthlyMileageRun": 0,
          "campaignDiscount": 0.5,
          "carRegisteredCity": "CHENNAI",
          "chassisNumber": "31823114312",
          "claimAmountReceived": "0",
          "claimsMadeInPreviousPolicy": "Yes",
          "claimsReported": "0",
          "companyNameForCar": "xyz",
          "cover_dri_othr_car_ass": "Yes",
          "cover_elec_acc": "Yes",
          "cover_non_elec_acc": "Yes",
          "cpaCoverDetails": {
            "companyName": "Royal Sundaram General Insurance Pvt Ltd.",
            "cpaCoverWithInternalAgent": false,
            "expiryDate": "30/12/2018",
            "noEffectiveDrivingLicense": false,
            "policyNumber": "345tf435rf4gsd",
            "standalonePAPolicy": false
          },
          "cpaCoverisRequired": "YES",
          "cpaPolicyTerm": 1,
          "cpaSumInsured": 1500000,
          "depreciationWaiver": "on",
          "drivingExperience": 1,
          "electricalAccessories": {
            "electronicAccessoriesDetails": [
              {
                "makeModel": "jPwYFkokuD",
                "nameOfElectronicAccessories": "MRkyDzbAVd",
                "value": ""
              }
            ]
          },
          "engineCapacityAmount": "796 CC",
          "engineNumber": "3312312312",
          "engineprotector": "off",
          "enhancedPACoverForCpa": "",
          "enhancedPACoverForPaidDriver": "",
          "enhancedPACoverForUnnamedPassengers": "",
          "fibreglass": "Yes",
          "financierName": "Reliance",
          "fuelType": "Petrol",
          "hdnDepreciation": true,
          "hdnInvoicePrice": false,
          "hdnKeyReplacement": true,
          "hdnLossOfBaggage": false,
          "hdnNCBProtector": false,
          "hdnProtector": false,
          "hdnRoadTax": false,
          "hdnSpareCar": false,
          "hdnTyreCover": true,
          "hdnWindShield": false,
          "hdtDiscount": 10,
          "invoicePrice": "off",
          "isBiFuelKit": "Yes",
          "isBiFuelKitYes": "InBuilt",
          "isBreakinInsurance": "No",
          "isBundleDiscountApply": "Yes",
          "isCarFinanced": "Yes",
          "isCarFinancedValue": "Hypothecation",
          "isCarOwnershipChanged": "Yes",
          "isPreviousPolicyHolder": "false",
          "isUsedCar": "No",
          "isVehicleInspected": "No",
          "keyreplacement": "on",
          "leadType": "string",
          "legalliabilitytoemployees": "Yes",
          "legalliabilitytopaiddriver": "Yes",
          "lossOfBaggage": "off",
          "modelName": "Alto 800 LXI Anniversary Edition - 5 Seater",
          "modified_idv_2year_value": "500000",
          "modified_idv_3year_value": "500000",
          "modified_idv_value": "500000",
          "modify_your_idv": "500000",
          "ncbcurrent": "20",
          "ncbprevious": "0",
          "ncbprotector": "off",
          "noClaimBonusPercent": "1",
          "nonElectricalAccesories": {
            "nonelectronicAccessoriesDetails": [
              {
                "makeModel": "jPwYFkokuD",
                "nameOfElectronicAccessories": "MRkyDzbAVd",
                "value": ""
              }
            ]
          },
          "original_idv": "500000",
          "original_idv_2year": "500000",
          "original_idv_3year": "500000",
          "personalaccidentcoverforunnamedpassengers": "50000",
          "planOpted": "Flexi Plan",
          "policyED": "27/05/2025",
          "policySD": "28/05/2024",
          "policyTerm": "1",
          "previousInsurerName": "BAJAJ ALLIANZ GENERAL INSURANCE COMPANY LTD",
          "previousPolicyExpiryDate": "27/05/2024",
          "previousPolicyType": "Comprehensive",
          "previousinsurersCorrectAddress": "BAJAJ ALLIANZ GENERAL INSURANCE COMPANY LTD, n10 xyz street, xycity, zstate",
          "previuosPolicyNumber": "4456456456",
          "pucnumber": "",
          "pucvalidUpto": "",
          "region": "South Region",
          "registrationNumber": "TN 04 AB 1534",
          "registrationchargesRoadtax": "off",
          "rtoName": "TN04-CHENNAI EAST",
          "spareCar": "off",
          "spareCarLimit": "",
          "technicalDiscount": 10,
          "towingChargesCover": "Yes",
          "towingChargesCover_SI": "1500",
          "tppdLimit": 6000,
          "typeOfCover": "Comprehensive",
          "tyreCover": "on",
          "validPUCAvailable": "",
          "valueOfLossOfBaggage": "50000",
          "valueofelectricalaccessories": "1000",
          "valueofnonelectricalaccessories": "1000",
          "vehicleManufacturerName": "SKODA",
          "vehicleModelCode": "CMH218",
          "vehicleMostlyDrivenOn": "City roads",
          "vehicleRegisteredInTheNameOf": "Company",
          "vehicleSubLine": "privatePassengerCar",
          "vehicleregDate": "19/03/2023",
          "voluntarydeductible": "0",
          "windShieldGlass": "off",
          "yearOfManufacture": "2023"
        }
    }
    


  function renderInputFields(data, handleInputClick, parentKey = '') {
      return Object.keys(data).map((key) => {
          const value = data[key];
          const isObject = value && typeof value === 'object' && !Array.isArray(value);
          const isArray = Array.isArray(value);
  
          return (
              <div key={key}>
                  <label className="col-md-12 nested-label1">
                      <span className='nested-span'>{key} {isObject ? '{ }' : isArray ? '[ ]' : ':'}</span>
                      <input 
                          type="text" 
                          id={`${parentKey}.${key}`} 
                          className="nested-input1" 
                          value={value} 
                          readOnly
                          onClick={() => handleInputClick(`${parentKey}.${key}`)}
                      />
                  </label>
                  {isObject && renderInputFields(value, handleInputClick, key)}
                  {isArray && value.map((item, index) => (
                      <div key={index}>
                          <label className="col-md-12 nested-label1">
                              <span className='nested-span'>{index} {'{ }'}</span>
                              <input 
                                  type="text" 
                                  id={`${parentKey}.${key}[${index}]`} 
                                  className="nested-input1" 
                                  value={item} 
                                  readOnly
                                  onClick={() => handleInputClick(`${parentKey}.${key}[${index}]`)}
                              />
                          </label>
                          {renderInputFields(item, handleInputClick, `${key}[${index}]`)}
                      </div>
                  ))}
              </div>
          );
      });
  }
  
  function NestedApiConnector() {
      const [activeInput, setActiveInput] = useState(null);
      const [jsonData, setJsonData] = useState(jsonBody);
      const [showPopup, setShowPopup] = useState(false);
  
      const handleInputClick = (inputId) => {
          setActiveInput(inputId);
          setShowPopup(true);
      };
  
      const handleSaveValue = (selectedValue) => {
          if (activeInput) {
              const keys = activeInput.split('.').slice(1);
              const updatedData = { ...jsonData };
  
              let currentLevel = updatedData;
              keys.forEach((key, index) => {
                  if (key.includes('[')) {
                      const [arrKey, arrIndex] = key.split(/[\[\]]/).filter(Boolean);
                      if (index === keys.length - 1) {
                          currentLevel[arrKey][arrIndex] = selectedValue;
                      } else {
                          currentLevel = currentLevel[arrKey][arrIndex];
                      }
                  } else {
                      if (index === keys.length - 1) {
                          currentLevel[key] = selectedValue;
                      } else {
                          currentLevel = currentLevel[key];
                      }
                  }
              });
  
              setJsonData(updatedData);
          }
          setShowPopup(false);
      };
  
      return (
          <div>
              {renderInputFields(jsonData, handleInputClick)}
              {showPopup && <GetDataFromPopup onSave={handleSaveValue} />}
          </div>
      );
  }
  
  export default NestedApiConnector;


