import AddAnotherCallGET from './AddAnotherCallGET';
import AddAnotherCallPOST from './AddAnotherCallPOST';


const AddAnotherCallMethods = {
    AddAnotherCallGET,
    AddAnotherCallPOST,

}

export default AddAnotherCallMethods;