import React, { useState, useEffect } from 'react'
import '../../../../Styles/Workflow/DynamicAction/GetDataFromDB/GetDataForPopup/DocIndexValue.css'

function DocIndexValue({clickedInputId, onValueChangeForDB, onDataFromComponents, dataFromComponents}) {

    const[getDocIndexValueFrom, setGetDocIndexValueFrom] = useState(dataFromComponents?.["doc-index-value"]?.valuesFromComponents.getDocIndexValueFrom || '')
    const[manualInputForValue, setManualInputForValue] = useState(dataFromComponents?.["doc-index-value"]?.valuesFromComponents.manualInputForValue || '')
    const[dynamicInputForValue, setDynamicInputForValue] = useState(dataFromComponents?.["doc-index-value"]?.valuesFromComponents.dynamicInputForValue || '')
    const[triggerOptionForValue, setTriggerOptionForValue] = useState(dataFromComponents?.["doc-index-value"]?.valuesFromComponents.triggerOptionForValue || '')
    const[triggerExternalInputForValue, setTriggerExternalInputForValue] = useState(dataFromComponents?.["doc-index-value"]?.valuesFromComponents.triggerExternalInputForValue || '')

    useEffect(() => {
        if(clickedInputId !== null){
          let finalValue = '';
          if(getDocIndexValueFrom === 'Manual'){
              finalValue = `${manualInputForValue}`
          } else if(getDocIndexValueFrom === 'Dynamic'){
            finalValue = `${getDocIndexValueFrom} : ${dynamicInputForValue}`
          } else if(getDocIndexValueFrom === 'Trigger'){
              finalValue = `${getDocIndexValueFrom} : ${triggerOptionForValue} : ${triggerExternalInputForValue}`
          }
          onValueChangeForDB(finalValue, clickedInputId)
        }
  
      }, [clickedInputId, onValueChangeForDB])

      useEffect(() => {
        const generateDataObject = async () => {
            const dataObject ={
                    getDocIndexValueFrom: getDocIndexValueFrom,
                    manualInputForValue: manualInputForValue,
                    dynamicInputForValue: dynamicInputForValue,
                    triggerOptionForValue: triggerOptionForValue,
                    triggerExternalInputForValue: triggerExternalInputForValue
            }
            onDataFromComponents(dataObject); 
        }
    
        generateDataObject(); 
    
    }, [
        getDocIndexValueFrom, 
        manualInputForValue, 
        dynamicInputForValue,
        triggerOptionForValue, 
        triggerExternalInputForValue,
        onDataFromComponents
      ]);

  return (
    <>
        <div className="row">
        <div className="col-md-12 gdivcls1">
            <label className="col-md-4 gdiv-label1" htmlFor="to" >
            Doc index value:
            </label>
            <select
                className="gdiv-select1"
                value={getDocIndexValueFrom}
                onChange={(e) => setGetDocIndexValueFrom(e.target.value)}
            >
                <option value="" disabled>Select a option</option>
                <option value="Manual">Manual</option>                
                <option value="Dynamic">Dynamic</option>                
                <option value="Trigger">Trigger</option>                
            </select>      
        </div>
        </div>
        {getDocIndexValueFrom === 'Manual' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdivcls1">
                    <label className="col-md-4 gdiv-label1">
                    </label>
                    <input
                        className="gdiv-select1"
                        type='text'
                        value={manualInputForValue}
                        onChange={(e) => setManualInputForValue(e.target.value)}
                    />     
                </div>
            </div>
            </div>
        )}
        {getDocIndexValueFrom === 'Dynamic' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdivcls1">
                    <label className="col-md-4 gdiv-label1">
                    </label>
                    <input
                        className="gdiv-select1"
                        type='text'
                        value={dynamicInputForValue}
                        onChange={(e) => setDynamicInputForValue(e.target.value)}
                    />     
                </div>
            </div>
            </div>
        )}
        {getDocIndexValueFrom === 'Trigger' && (
            <div>
            <div className="row">
                <div className="col-md-12 gdikcls1">
                    <label className="col-md-4 gdik-label1">
                    </label>
                    <select
                        className="gdik-select1"
                        value={triggerOptionForValue}
                        onChange={(e) => setTriggerOptionForValue(e.target.value)}
                    >   
                        <option value="" disabled>Select a option</option>
                        <option value="Internal">Internal</option>                
                        <option value="External">External</option>                
                    </select>
                </div>
            </div>
            {triggerOptionForValue === 'External' && (
                <div>
                <div className="row">
                    <div className="col-md-12 gdikcls1">
                        <label className="col-md-4 gdik-label1">
                        </label>
                        <input
                            className="gdik-select1"
                            type='text'
                            value={triggerExternalInputForValue}
                            onChange={(e) => setTriggerExternalInputForValue(e.target.value)}
                        />     
                    </div>
                </div>
                </div>
            )}
            </div>
        )}
    </>
  )
}

export default DocIndexValue