import React, { useState, useEffect, useRef } from 'react';
import TokenGenerationForm from './TokenGenerationForm';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../Styles/ApiReceiver/TokenGenerationForm.css';
import { useProjectsContext } from '../../Context/ProjectsContext'; 

function ApiReceiver() {
  const [name, setName] = useState('');
  const [route, setRoute] = useState('');
  const [showTokenForm, setShowTokenForm] = useState(false);
  const [apiTokenLabel, setApiTokenLabel] = useState(''); // Add this line
  const [jwtToken, setJwtToken] = useState(''); // Add this line
  const [apiReceivers, setApiReceivers] = useState([]);
  const inputRef = useRef(null);

  const { currentDatabase } = useProjectsContext();  
  console.log(".....API Receiver currentDatabase...", currentDatabase);

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleRouteChange(e) {
    setRoute(e.target.value);
  }

  const handleGenerateTokenClick = () => {
    setShowTokenForm(true);
  };

  const handleCloseTokenForm = () => {
    setShowTokenForm(false);
  };

  const handleTokenChange = (apiTokenLabel, jwtToken) => {
    // Update the state in ApiReceiver
    setApiTokenLabel(apiTokenLabel);
    setJwtToken(jwtToken);
  };

  const handleSaveClick = async () => {
    try {
      // Send a POST request to save data to the backend
        const response = await fetch('https://aim-core.insurancepolicy4us.com/createapi', {
          method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          route,
          apiTokenLabel,
          jwtToken,
        }),
      });

      if (response.ok) {
        console.log('Data saved successfully');

        // Reset form fields and close TokenGenerationForm
        setName('');
        setRoute('');
        setApiTokenLabel('');
        setJwtToken('');
        setShowTokenForm(false);
        
      } else {
        console.error('Failed to save data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    console.log("hiiiiooo");
    const fetchData = async () => {
      try {
        console.log("H!!!!!!!");
        const response = await fetch('https://aim-core.insurancepolicy4us.com/getapi'); // Replace with your actual API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log("response", response);
        const data = await response.json();
        console.log('Fetched data:', data);
        setApiReceivers(data);

        // Assuming the first item in the array contains the data you want to use
        if (data.length > 0) {
          const firstApiReceiver = data[0];
          console.log('First API Receiver:', firstApiReceiver); 
          setName(firstApiReceiver.Api_Name);
          setRoute(firstApiReceiver.Api_Route_URL);
          setShowTokenForm(true)
          setApiTokenLabel(firstApiReceiver.Api_Token_Label);
          setJwtToken(firstApiReceiver.Api_Private_Key);
        }else if (currentDatabase) {
          setRoute(`https://aim-core.insurancepolicy4us.com/${currentDatabase}`);
        } 

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const copyURLToClipboard = (e) => {
    e.preventDefault(); // Prevent the default behavior (form submission, link navigation, etc.)
  
    if (inputRef.current) {
        inputRef.current.select();

        try {
            navigator.clipboard.writeText(inputRef.current.value);
            alert('URL copied to clipboard!');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    }
  };

  return (
    <div className="container">
      <div className="card p-4 mt-5">
      <h4>Public API Nodes</h4>
        <div className="row mb-3">
          <div className="col-md-2 mt-3">
            <label htmlFor="apiName" className="form-label">
              Name of the API
            </label>
          </div>
          <div className="col-md-10 mt-3">
            <input
              type="text"
              className="form-control"
              id="apiName"
              value={name}
              onChange={handleNameChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="apiRoute" className="form-label">
              Route
            </label>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              id="apiRoute"
              value={route}
              onChange={handleRouteChange}
              ref={inputRef}
            />
          </div>
          <div className="col-md-1">
            <button className='copy-btn' onClick={copyURLToClipboard}>Copy</button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-outline-primary"
              onClick={handleGenerateTokenClick}
            >
              Generate a new API Token
            </button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            {showTokenForm && 
              <TokenGenerationForm 
                onClose={handleCloseTokenForm} 
                onTokenChange={handleTokenChange} 
                initialApiTokenLabel={apiTokenLabel} 
                initialJwtToken={jwtToken}
              />
            }
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <button className="api-save-btn" onClick={handleSaveClick}>
              Save
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiReceiver;
