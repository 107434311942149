import React, { useState, useEffect } from 'react';
import plus from '../../../../Assets/Images/plus.png';
import SubConditionOptions from './SubConditionOptions/SubConditionOptions';
import '../../../Styles/Workflow/DynamicAction/ConditionTabGetDataFrom/ConditionComponent.css';
import NestedConditionOptions from './NestedConditionOptions/NestedConditionOptions';
import ResultOptions from '../FormatComponents/FormatArray';

function ConditionComponent({ conditionInput, setConditionInput, onConditionData, onGeneratedConditionsChange, conditionDBResponse, conditionData  }) {

    const [conditions, setConditions] = useState(conditionData.conditions || []);
    const [subConditionIndex, setSubConditionIndex] = useState(1);
    const [modifyConditions, setModifyConditions] = useState(conditionData.modifyBooleanConditions || []);
    const [modifyTextConditions, setModifyTextConditions] = useState(conditionData.modifyTextConditions || []);

    useEffect(() => {
      if (conditionDBResponse && conditionDBResponse.conditions) {
        setConditions(conditionDBResponse.conditions);
        if (conditionDBResponse.modifyBooleanConditions) {
          setModifyConditions(conditionDBResponse.modifyBooleanConditions);
        }
        if (conditionDBResponse.modifyTextConditions) {
          setModifyTextConditions(conditionDBResponse.modifyTextConditions);
        }
      }
    }, [conditionDBResponse]);    
    

    const handleConditionButtonClick = () => {
        const newCondition = {
          selectedIdentifier: '',
          selectedSubCondition: '',
          selectedNestedCondition: '',
          selectedResult:'',
        };
    
        setConditions((prevConditions) => [...prevConditions, newCondition]);
    };


    useEffect(() => {
      const generatedConditions = [];
  
      conditions.forEach((condition, index) => {
          let generateCondition;
  
          // First Case: Independent conditions without logical operations
          if (condition.selectedOperation === '') {
              generateCondition = {
                  [`Condition ${index + 1}`]: {
                      selectedIdentifier: condition.selectedIdentifier,
                      ...(['Date', 'Integer', 'Text'].includes(condition.selectedIdentifier) && {
                        selectedSubCondition: condition.selectedSubCondition,
                      }),
                      ...(['Date'].includes(condition.selectedIdentifier) && {
                        selectedNestedCondition: condition.selectedNestedCondition,
                      }),
                  },
              };
              // Handle independent conditions logic here (you can use or return independentCondition as needed)
          }
  
          generatedConditions.push(generateCondition);
      });
  
      // console.log('Conditions:', generatedConditions);
      onGeneratedConditionsChange(generatedConditions)

  }, [conditions]);
  

    useEffect(() => {
      const generateDataObject = async () => {
        const dataObject = {
          conditions:conditions,
          modifyBooleanConditions:modifyConditions,
          modifyTextConditions:modifyTextConditions
        }
        onConditionData(dataObject)
      }
      generateDataObject()
    }, [conditions, onConditionData])

    const handleBooleanModifyConditionButtonClick = () => {
      const newCondition = {
          selectedModifyOption: '',
          selectedModifyResult: '',
          selectedModifyText: '',
      }
      setModifyConditions((prevConditions) => [...prevConditions, newCondition]);
    }

    const handleTextModifyConditionButtonClick = () => {
      const newCondition = {
          selectedTextModifyBeforeText: '',
          selectedTextModifyResult: '',
          selectedTextModifyAfterText: '',
      }
      setModifyTextConditions((prevConditions) => [...prevConditions, newCondition]);
    }
    

return (
    <div>
    <div className="row">
        <div className="col-md-12 gdcls2">
            <label className="col-md-4 gd-label2" htmlFor="to" >
            Input:
            </label>
            <input
              type="text"
              id="filter"
              className="gd-input1"
              value={conditionInput}
              onChange={(e) => setConditionInput(e.target.value)}
            />  
        </div>
    </div>
    {conditions && conditions.map((condition, index) => (
      <>
      <div key={index} className='gd-outerline'>
        <div className="row">
          <div className="col-md-12 gdhcls1">
            <h6 className='gdh6'>Condition {index + 1}</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 gdcls1">
            <label className="col-md-4 gd-cc-label2">
              Identifier
            </label>
            <select
              className="gd-select1"
              value={condition.selectedIdentifier}
              onChange={(e) => {
                const newConditions = [...conditions];
                newConditions[index].selectedIdentifier = e.target.value;
                setConditions(newConditions);
              }}                          
            >
              <option value="" disabled>Select an identifier</option>
              <option value="Boolean">Boolean</option> 
              <option value="Date">Date</option> 
              <option value="Email">Email</option> 
              <option value="Integer">Integer</option> 
              <option value="Password">Password</option> 
              <option value="Percentage">Percentage</option> 
              <option value="PhoneNumber">Phone Number</option> 
              <option value="Text">Text</option> 
              <option value="VehicleNumber">Vehicle Number</option> 
            </select>      
          </div>
        </div>
        {['Boolean'].includes(condition.selectedIdentifier) && (
            <>
            {modifyConditions && modifyConditions.map((condition, index) => (
              <>
                <div className="row">
                  <div className="col-md-12 gdcls1">
                    <label className="col-md-4 gd-cc-label2">
                      Boolean
                    </label>
                    <select
                      className="gd-select1"
                      value={condition.selectedModifyOption}
                      onChange={(e) => {
                        const newConditions = [...modifyConditions];
                        newConditions[index].selectedModifyOption = e.target.value;
                        setModifyConditions(newConditions);
                      }}                         
                    >
                      <option value="" disabled>Select an option</option>
                      <option value="true" >true</option>
                      <option value="false" >false</option>
                    </select>      
                  </div>
                </div>
                <div key={index} className='gd-outerline'>

                <div className="row">
                <div className="col-md-12 gdcls1">
                  <label className="col-md-4 gd-cc-label2">
                    Result
                  </label>
                  <select
                    className="gd-select1" 
                    value={condition.selectedModifyResult}
                    onChange={(e) => {
                      const newConditions = [...modifyConditions];
                      newConditions[index].selectedModifyResult = e.target.value;
                      setModifyConditions(newConditions);
                    }}                        
                  >
                    <option value="" disabled>Select an option</option>
                    <option value="Text" >Text</option>
                  </select>      
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 gdcls1">
                  <label className="col-md-4 gd-cc-label2">
                    
                  </label>
                  <input
                    className="gd-select1"
                    type='text'
                    value={condition.selectedModifyText}
                    onChange={(e) => {
                      const newConditions = [...modifyConditions];
                      newConditions[index].selectedModifyText = e.target.value;
                      setModifyConditions(newConditions);
                    }}
                  />
                        
                </div>
              </div>
                </div>
              </>
            ))}
              <div className="row">
                <div className="col-md-12 gdcls4">
                  <button className='col-md-12 gd-format-btn' onClick={handleBooleanModifyConditionButtonClick}>
                    <span className="gd-text">Modify Condition</span>
                  </button>
                </div>
              </div>
            </>
          )}
          
        {['Date', 'Integer', 'Text'].includes(condition.selectedIdentifier) && (
          <>
          <div className="row">
            <div className="col-md-12 gdcls1">
              <label className="col-md-4 gd-cc-label2">
                Sub-Conditions
              </label>
              <select
                className="gd-select1"
                value={condition.selectedSubCondition}
                onChange={(e) => {
                  const newConditions = [...conditions];
                  newConditions[index].selectedSubCondition = e.target.value;
                  setConditions(newConditions);
                }}                         
              >
                <option value="" disabled>Select a option</option>
                <SubConditionOptions selectedIdentifier={condition.selectedIdentifier} />
              </select>      
            </div>
          </div>
          {['Date'].includes(condition.selectedIdentifier) && (
            <div className="row">
              <div className="col-md-12 gdcls1">
                <label className="col-md-4 gd-cc-label2">
                  Date Format
                </label>
                <select
                  className="gd-select1"
                  value={condition.selectedNestedCondition}
                  onChange={(e) => {
                    const newConditions = [...conditions];
                    newConditions[index].selectedNestedCondition = e.target.value;
                    setConditions(newConditions);
                  }}                         
                >
                  <option value="" disabled>Select a date format</option>
                  <option value="YYYY/MM/DD" >YYYY/MM/DD</option>
                  <option value="DD-MMM-YYYY" >DD-MMM-YYYY</option>
                  <option value="DD/MM/YYYY" >DD/MM/YYYY</option>
                  <option value="DD-MM-YYYY" >DD-MM-YYYY</option>
                  <option value="MM/DD/YYYY" >MM/DD/YYYY</option>
                  <option value="Timestamp" >Timestamp</option>
                  {/* <NestedConditionOptions selectedIdentifier={condition.selectedIdentifier} selectedSubCondition={condition.selectedSubCondition}/> */}
                </select>      
              </div>
            </div>
          )}
          
          </>
        )}
        {['Text'].includes(condition.selectedIdentifier) && ['Exactly Matches'].includes(condition.selectedSubCondition) &&(
          <>
            {modifyTextConditions && modifyTextConditions.map((condition, index) => (
              <>
              <div className="row">
                  <div className="col-md-12 gdcls1">
                    <label className="col-md-4 gd-cc-label2">
                      Text
                    </label>
                    <input
                      className="gd-select1"
                      type='text'
                      value={condition.selectedTextModifyBeforeText}
                      onChange={(e) => {
                        const newConditions = [...modifyTextConditions];
                        newConditions[index].selectedTextModifyBeforeText = e.target.value;
                        setModifyTextConditions(newConditions);
                      }}                          
                    />  
                  </div>
                </div>
                <div key={index} className='gd-outerline'>
                <div className="row">
                  <div className="col-md-12 gdcls1">
                    <label className="col-md-4 gd-cc-label2">
                      Result
                    </label>
                    <select
                      className="gd-select1"
                      value={condition.selectedTextModifyResult}
                      onChange={(e) => {
                        const newConditions = [...modifyTextConditions];
                        newConditions[index].selectedTextModifyResult = e.target.value;
                        setModifyTextConditions(newConditions);
                      }}                         
                    >
                      <option value="" disabled>Select a option</option>
                      <option value="Text">Text</option>
                    </select>      
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 gdcls1">
                    <label className="col-md-4 gd-cc-label2">
                      
                    </label>
                    <input
                      className="gd-select1"
                      type='text'
                      value={condition.selectedTextModifyAfterText}
                      onChange={(e) => {
                        const newConditions = [...modifyTextConditions];
                        newConditions[index].selectedTextModifyAfterText = e.target.value;
                        setModifyTextConditions(newConditions);
                      }}                          
                    />  
                  </div>
                </div>
                </div>
              </>
            ))}
            <div className="row">
              <div className="col-md-12 gdcls4">
                <button className='col-md-12 gd-format-btn' onClick={handleTextModifyConditionButtonClick}>
                  <span className="gd-text">Modify Condition</span>
                </button>
              </div>
            </div>
          </>
          )}
      </div>
      <div className="row">
      <div className="col-md-12 gdcls1">
        <label className="col-md-4 gd-cc-label2">
          Result {index + 1}
        </label>
        
        <select
          className="gd-select1"
          value={condition.selectedResult || (conditionDBResponse && conditionDBResponse.conditions && conditionDBResponse.conditions[index] && conditionDBResponse.conditions[index].selectedResult) || ""}  
          onChange={(e) => {
            const newConditions = [...conditions];
            newConditions[index].selectedResult = e.target.value;  
            setConditions(newConditions);
          }}                          
        >

          <option value="" >Select an option </option>
          {condition.selectedIdentifier !== 'Date' && condition.selectedIdentifier !== 'Text' && condition.selectedIdentifier && (
            <ResultOptions
              selectedIdentifier={condition.selectedIdentifier}
              selectedSubCondition={condition.selectedSubCondition}
              selectedNestedCondition={condition.selectedNestedCondition}
            />
          )}
          {condition.selectedIdentifier === 'Text' && condition.selectedIdentifier && condition.selectedSubCondition && (
            <ResultOptions
              selectedIdentifier={condition.selectedIdentifier}
              selectedSubCondition={condition.selectedSubCondition}
              selectedNestedCondition={condition.selectedNestedCondition}
            />
          )}
          {condition.selectedIdentifier === 'Date' && condition.selectedIdentifier && condition.selectedSubCondition && condition.selectedNestedCondition && (
            <ResultOptions
              selectedIdentifier={condition.selectedIdentifier}
              selectedSubCondition={condition.selectedSubCondition}
              selectedNestedCondition={condition.selectedNestedCondition}
            />
          )}
        </select>
      </div>
      </div>
      </>
    ))}
    <div className="row">
      <div className="col-md-12 gdcls4">
        <button className='col-md-12 gd-format-btn' onClick={handleConditionButtonClick}>
          <img src={plus} alt="plus" className="gd-img-hover" />
          <span className="gd-text">Condition</span>
        </button>
      </div>
    </div>
  </div>
 )
}

export default ConditionComponent;
